// Адаптивная версия
// -----------------------------
// @media screen and (min-width: 1441px){

// }
@media screen and (max-width: 1366px){
  section#pageThanks {
    padding-top: 25vh;
  }
  section#page404{
    padding-top: 155px;
    .error_page{
      h1{
        br{
          display: none;
        }
      }
      p{
        br{
          display: none;
        }
      }
    }
  }
  .fullpage {
    background-position-x: -100px;
    background-position-x: 68%;
  }
  header {
    .navbar {
      padding: 35px 5px;
      padding: 5px 10px;
    }
    .header_phoneblock {
      padding: 8px 0 0 50px;
      background-position: 27px 14px;
    }
    .navbar-nav .nav-link {
      padding-right: 1.0rem!important;
      padding-left: 1.0rem!important;
    }
    .menu {
      width: auto;
      padding-right: 180px;
      .menu_close {
        margin-bottom: 40px;
      }
      p {
        line-height: 1.5;
      }
    }
    .navbar-brand {
      margin-right: 0;
    }
  }
  section#main {
    margin-top: -121px;
    padding: 130px 0 0;
    padding-top: 22vh;
    h1 {
      font-size: 2.25rem;
    }
    .test-drive_div {
      margin-bottom: 15px;
      p {
        font-size: 1.0rem;
        font-size: 0.95rem;
        line-height: 1.125;
        margin-bottom: .75rem;
        font-family: "Uni Neue Bold";
        &+p{
          font-size: .85rem;
          font-family: "Uni Neue Regular";
        }
      }
      span {
        font-size: 100px;
        line-height: 0.8;
      }
    }
    .slogan {
      line-height: 1.1;
      font-size: 1.75rem;
    }
    .advantage_cycles {
      font-size: 2.25rem;
      line-height: 1.3;
      margin: 2.5rem 8px 3rem;
    }
    .test-drive_form {
      padding: 25px 32px 24px;
      h4 {
        font-size: 1.0rem;
        font-size: 1.2rem;
        font-family: "Uni Neue Regular";
        font-family: "Uni Neue Bold";
      }
      button[type=submit]{
        font-size: .85rem;
      }
    }
  }
  section#sec2 {
    .advantage_right {
      //background-position: 215px 220px;
      background-image: url(../img/adv_button2.png);
      background-repeat: no-repeat;
      background-position: 265px 305px;
      background-size: 315px;
      h3 {
        font-size: 1.75rem;
        line-height: 1.3;
      }
    }
  }
  section#sec3 {
    background-position-x: 62%;
    h2 {
      font-size: 2.25rem;
      line-height: 1.1;
      &+p {
        font-size: 1.75rem;
        line-height: 1.1;
      }
    }
    .description {
      margin-top: 35px;
    }
    .send-catalog_form form {
      margin: 45px 0 60px;
    }
  }
  section#sec4 {
    margin-top: -45px;
    h2 {
      font-size: 1.75rem;
      line-height: 1.25;
    }
    .quiz_block {
      margin-top: 55px;
      margin-bottom: 25px;
    }
    .quiz_block .question__title {
      font-size: 1.75rem;
    }
  }
  section#sec5 {
    padding: 75px 0 35px;
    h2 {
      text-align: center;
      line-height: 1.2;
      &+p {
        margin: 35px 0 50px;
      }
    }
  }
  section#sec6 {
    background-position-x: 50%;
  }
  section#sec7 {
    h2 {
      font-size: 2.25rem;
      line-height: 1.2;
    }
    .customers_count span {
      font-size: 4.25rem;
    }
    .comments_logos {
      margin: 90px auto 60px;
      margin: 75px auto 40px;
    }
    .slick-dots {
      bottom: -90px;
    }
  }
  section#sec7 .slick-dots {
    bottom: -75px;
  }
  section#sec8 {
    background-position-x: 65%;
    h2 {
      line-height: 1.2;
      margin-bottom: 10px;
    }
    .quiz_questions {
      margin: 35px 0;
      width: 435px;
    }
    .delivery_advantages {
      margin: 25px 0 20px;
      .delivery_advantages-item {
        width: 50%;
        height: 85px;
        background-position: 40px 15px;
        padding-left: 110px;
        background-position: 10px 15px;
        padding-left: 80px;
        background-repeat: no-repeat;
        &:before {
          left: 12%;
        }
      }
    }
  }
  section#sec9 {
    .address_block h2 {
      line-height: 1.1;
    }
  }
  footer .footer_menu {
    padding: 60px 0;
  }
}
@media screen and (orientation : landscape) and (max-width: 1366px){
  section#page404 {
    padding-top: 200px;
  }
}
@media screen and (max-width: 1280px) {
  section#page404 {
    padding-top: 255px;
    padding-top: 225px;
  }
  .fullpage {
    background-position-x: 48%;
  }
  header {
    .navbar {
      padding: 35px 25px;
    }
  }
  section#main {
    padding-top: 24vh;
    h1 {
      font-size: 3rem;
      //text-align: center;
      br{
        display: none;
      }
    }
    .slogan{
      //text-align: center;
      br{
        display: none;
      }
    }
    .advantage_cycles {
      margin: 3rem 8px 3rem;
      font-size: 2.875rem;
    }
    .test-drive_div {
      margin-bottom: 40px;
      p {
        font-size: 1.125rem;
        &+p {
          font-size: .875rem;
        }
      }
      span {
        font-size: 145px;
        line-height: 115px;
      }
    }
  }
}
@media screen and (max-width: 1280px) and (max-device-height: 800px){
  .fullpage {
    background-position-x: 50%;
  }
  section#main {
    padding-top: 23vh;
    padding-top: 21vh;
    .test-drive_div {
      margin-bottom: 15px;
    }
  }
  section#sec7 {
    .slick-dots {
      bottom: -70px;
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .header_mailblock{
    display:none;
  }
  section#sec2 {
    .advantage_right {
      background-size: 247px;
    }
  }
}
@media screen and (max-width: 1024px){
  section#pageThanks {
    padding-top: 177px;
    height: 106vh;
  }
  section#page404 {
    padding-top: 200px;
    height: 105vh;
    padding-top: 177px;
    height: 106vh;
  }
  .fullpage {
      background-position-x: 45%;
  }
  header {
    .navbar {
      padding: 35px 20px;
    }
    .header_mailblock {
      display: none;
    }
    .header_phoneblock {
      padding: 8px 0 0 30px;
      background-position: 7px 14px;
    }
    .navbar-nav .nav-link {
      padding-right: .5rem!important;
      padding-left: .5rem!important;
    }
    .menu {
      padding-right: 60px;
    }
  }
  section#main {
    padding-top: 20vh;
    .test-drive_div {
      margin-right: 20px;
    }
    .test-drive_form {
      margin-right: 10px;
    }
  }
  section#sec8 .delivery_advantages .delivery_advantages-item:before {
    left: 2%;
    // display: none;
  }
  .quiz_questions form {
    .quantity-arrow-minus,
    .quantity-arrow-plus{
      top: 34px;
    }
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 {
    // text-align: center;
    float: none;
    // clear: both;
    width: 100%;
  }
  section#sec2 {
    .advantage_right {
      background-size: 247px;
      background-position: 402px 300px;
    }
  }
}

@media screen and (orientation : portrait) and (max-width: 809px){
  section#pageThanks {
    padding-top: 29vh;
  }
  section#page404 {
    padding-top: 29vh;
  }
}
@media screen and (max-width: 768px){
  .fullpage {
    background-position-x: 55%;
    background-position: top center;
  }
  header {
    button:focus {
      outline: 1px none;
    }
    .header_contacts{
      display: block;
      width: 100%;
    }
    .header_mailblock {
      display: block;
    }
    .navbar-brand {
      line-height: 1;
      padding: 0;
    }
    .navbar-light .navbar-nav .nav-link {
      color: rgba(0, 0, 0, 0.85);
      font-size: 1rem;
    }
    .navbar-light .navbar-toggler {
      color: rgba(0, 0, 0, 0.85);
      border-color: transparent;
    }
    .navbar-collapse.collapse.show {
      z-index: 3;
    }
  }
  section#main {
    padding-top: 5vh;
    padding-top: 10vh;
    h1 {
      font-size: 2.25rem;
      br{
        display: none;
      }
    }
    .slogan {
      line-height: 1.1;
      font-size: 1.75rem;
      br{
        display: none;
      }
    }
    .advantage_cycles {
      display: none;
      margin: 2rem 8px 2rem;
      font-size: 2.5rem;
      width: 50%;
      float: left;
      &.d-md-none{
        display: block;
        margin: 5.5rem 0 3rem;
        &:before {
          content: "";
          display: block;
          border-top: 8px solid #92cd75;
          width: 70px;
          width: 62px;
          padding-top: 22px;
        }
      }
    }
    .patent_block {
      margin-top: 4.25rem;
      margin-left: 3.25rem;
    }
    .test-drive_div {
      display: none;
      margin-right: 20px;
      float: left!important;
      &.d-md-none{
        display: block;
      }
    }
    .test-drive_form {
      margin-right: 0;
    }
    .main-left {
      margin-top: 40px;
      margin-bottom: 35px;
    }
  }
  section#sec2 {
    padding: 65px 0;
    background-position: top center;
    .advantage_right h3 {
      font-size: 2rem;
      line-height: 1.2;
    }
    .adv_block {
      margin: 35px 0;
    }
    .production-video.d-md-none .production-video__img .final-top-l__btn {
      margin: 58px auto 10px;
    }
    .advantage_right {
      //background-position: 260px 744px;
      background-position: 260px 750px;
      background-size: 220px;
    }
  }
  section#sec8 {
    .quiz_questions {
      width: 450px;
      margin: 0 auto 0;
    }
    .delivery_advantages .delivery_advantages-item:before {
      left: 0%;
      top: 30px;
    }
  }
  .quiz_questions form {
    .quantity-arrow-minus,
    .quantity-arrow-plus{
      top: 30px;
    }
  }
}

@media screen and (orientation : portrait) and (max-width: 768px){
  // section#pageThanks {
  //   height: 95vh;
  //   padding-top: 27vh;
  // }
  section#pageThanks {
    height: 86vh;
    padding-top: 23vh;
  }
  section#page404 {
    height: 90vh;
    height: 95vh;
    padding-top: 27vh;
  }
}
@media screen and (min-width: 641px) and (max-width: 768px){
  .menu2 {
    width: auto;
    padding-left: 85px;
  }
  .menu2 .nav-link {
    font-size: 1.2rem!important;
    padding-bottom: 1.3rem;
  }
  header {
    .header_contacts{
      margin: 15px 10px;
    }
    .header_phoneblock {
      background-position: 0px 18px;
      background-size: 20px;
      a {
        font-size: 1.6rem;
        font-size: 1.4rem;
      }
    }
    .header_mailblock {
      display: block;
      a{
        font-size: 1.6rem;
        font-size: 1.4rem;
      }
    }
    .navbar-brand {
      line-height: 1;
      padding: 0;
      img {
        height: 36px;
      }
    }
  }
  section#main {
    padding-top: 12vh;
    padding-top: 100px!important;
    h1 {
      text-align: center;
      font-size: 2.25rem!important;
    }
    .slogan{
      font-size: 1.5rem;
      text-align: center;
    }
    .advantage_cycles {
      display: block;
      width: 240px;
      &.d-md-none {
        display: none;
      }
    }
    .test-drive_div.d-md-none {
      display: block;
      float: right!important;
      width: 245px;
      margin: 22px 0 0;
    }
    .test-drive_div {
      display: none;
      &.d-md-none {
        display: block;
        float: right!important;
        width: 245px;
        margin: 22px 0 0;
        margin: 28px 0 0;
        span{
          line-height: 85px;
          font-size: 110px;
          width: 60px;
          line-height: 60px;
          font-size: 75px;
        }
        p{
          padding-left: 72px;
          br{
            display: none;
          }
        }
      }
    }
    .main-left {
      margin: 50px 0;
      margin: 0 0 50px;
    }
    .main-right{
      button.d-md-none{
        display: none;
      }
    }
    .test-drive_form {
      margin: -50px auto 0!important;
      float: none!important;
    }
    .patent_block{
      display: none!important;
    }
  }
}

@media screen and (max-width: 640px){
  .catalog_slider .card {
    margin: 20px;
  }
  .fullpage {
    background: #fff url(../img/main_320.jpg) no-repeat bottom center;
    background: #fff url(../img/main_320m2.jpg) no-repeat bottom center;
    background-size: cover;
  }
  header {
    .menu2 {
      width: 400px;
      .nav-link {
        font-size: 1.0rem!important;
        padding-bottom: 1.0rem!important;
      }
    }
    .navbar-toggler {
      padding-right: 6px;
    }
    .navbar {
      padding: 25px 20px;
    }
    .navbar-brand img {
      height: 35px;
      height: 20px;
      height: 16px;
      padding-left: 12px;
    }
    .header_phoneblock {
      background-position: 1px 15px;
      background-size: 1.125rem;
      a {
        font-size: 1.25rem;
      }
      span.menu_open{
        display: none;
      }
    }
    .header_contacts {
      padding: 35px 15px 35px;
      padding: 33px 12px 35px;
    }
    .header_mailblock {
      margin-top: 0;
      a {
        font-size: 1.25rem;
      }
    }
  }
  section#main{
    padding-top: 10vh;
    h1 {
      // font-size: 3rem;
      // font-size: 2.5rem;
      font-size: 2.25rem;
      line-height: 1.2;
    }
    .slogan {
      line-height: 1.35;
      font-size: 2rem;
      font-size: 1.7rem;
      margin-top: 1.9rem;
    }
    .main-left {
      margin-top: 5px;
      margin-bottom: 0px;
    }
    .advantage_cycles {
      //display: none;
      margin: 1rem 8px 2rem;
      font-size: 2.0rem;
      margin: 2.5rem 0px 3rem;
      //font-size: 2.875rem;
      font-size: 2.5rem;
      font-size: 2.25rem;
      div {
        font-size: 1rem;
        color: #465356;
        padding-top: 5px;
        padding-left: 6px;
      }
      &.d-md-none:before{
        border-top: 6px solid #92cd75;
        border-top: 5px solid #92cd75;
        width: 54px;
      }
      &:before {
        display: none;
      }
    }
    .vision640{
      padding: 1.4rem 2.4rem 1.4rem;
      //font-size: 24px;
      font-size: 18px;
    }
    .patent_block {
      display: none;
      margin-top: 4.25rem;
      margin-left: 3.25rem;
    }
    .test-drive_div {
      margin-right: 20px;
      float: left!important;
      margin-bottom: 25px;
      //margin: 73px 0 20px;
      margin: 50px 0 20px;
      span {
        // font-size: 50px;
        // line-height: 40px;
        // width: 45px;
        // width: 85px;
        // font-size: 100px;
        width: 60px;
        font-size: 80px;
        line-height: .8;
      }
      p {
        // font-size: 1.125rem;
        // padding-left: 45px;
        // font-size: 2.0rem;
        // padding-left: 85px;
        line-height: 1.25;
        font-size: 1.5rem;
        padding-left: 65px;
        &+p {
          display: none;
        }
      }
    }
    .test-drive_form {
      display: none;
      float: none!important;
      clear: both;
      margin: auto;
      width: 75%;
      h4 {
        font-family: "Uni Neue Bold";
        font-size: 1.2rem;
      }
    }
  }
  section#sec2 {
    padding: 53px 0 0;
    background: url(../img/section_02_320_4.jpg) no-repeat bottom center #fff;
    background-size: cover;
    .advantage_right {
      background-image: none;
      h3 {
        font-size: 1.15rem;
        line-height: 1.5;
        padding: 0;
      }
    }
    .adv_block {
      margin: 21px 0;
      &:before {
        width: 37px;
        padding-top: 20px;
      }
    }
    .production-video.d-md-none{
      .production-video__img {
        position: relative;
        .final-top-l__btn {
          position: relative;
          // width: 125px;
          // height: 125px;
          // margin: 48px auto -22px;
          width: 70px;
          height: 70px;
          margin: 48px auto;
          &:after,
          &:before {
            width: 125px;
            height: 125px;
            width: 70px;
            height: 70px;
          }
        }
        p {
          margin: 0;
          width: 100px;
          text-align: left;
          position: absolute;
          top: 48px;
          font-size: 10px;
          line-height: 1.6;
        }
      }
    }
    .adv_item {
      margin-bottom: 27px;
      span {
        line-height: 1.1;
        width: 100%;
      }
      &:nth-child(3) {
        padding-left: 20px;
      }
      &:nth-child(5) {
        display: none;
      }
      &:nth-child(6) {
        padding-left: 20px;
      }
    }
    .production-start {
      margin: 151px 0 0;
      height: 108px;
      width: 100%;
      .prodstart__left {
        padding: 12px 6px;
        padding: 29px 6px 0;
        span {
          font-size: 4.285rem;
          padding-left: 3px;
          line-height: 1;
        }
      }
      .prodstart__right {
        font-size: .75rem;
        padding: 33px 0 0;
        padding-left: 165px;
        line-height: 1.65;
      }
    }
  }
  section#sec3 {
    background: #24262b url(../img/section_03_320.jpg) no-repeat;
    background: #24262b url(../img/section_03mod2_320.jpg) no-repeat;
    background-size: cover;
    padding: 43px 0 0;
    h2 {
      font-size: 1.5rem;
      line-height: 1.25;
      margin-bottom: .75rem;
      &+p {
        font-size: 1.0rem;
        line-height: 1.35;
      }
    }
    .description {
      margin-top: 5px;
      .desc {
        font-size: .5rem;
        background-repeat: no-repeat;
        background-position: -8% 49%;
        background-position: -19px -12px;
        float: left;
        width: 100%;
        background-size: 60px;
        height: auto;
        padding: 11px 0;
        span {
          display: block;
          padding: 0;
          padding-top: 0;
          padding-bottom: 0;
          width: 22px;
          float: left;
          /* height: 15px; */
          text-align: center;
          line-height: 15px;
          font-size: 10px;
          &:after {
            top: -7px;
            left: -3px;
          }
        }
        &.desc1,
        &.desc2 {
          margin-top: 0;
        }
      }
      .desc-text {
        font-size: .625rem;
        padding: 0 35px;
        padding-right: 0;
        height: auto;
        br{
          display: none;
        }
      }
    }
    .description_block{
      form{
        width: 270px;
        padding: 25px 26px;
        padding: 25px 26px 18px;
        text-align: center;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #e0e6e6;
        margin: 233px auto 60px;
        box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
        border: none;
        h4 {
          font-size: 0.875rem;
          margin-bottom: 1.25rem;
          line-height: 1.5;
        }
        input[type=text] {
          border-radius: 26px;
          height: calc(2.45rem + 2px);
          font-size: 0.875rem;
          color: #6d7c7f;
          padding-left: 45px;
          background-image: url(../img/phone_icon.png);
          background-repeat: no-repeat;
          background-position: 19px;
        }
        input[name=test-drive_mail] {
          background-image: url(../img/mail_icon.png);
          background-repeat: no-repeat;
          background-position: 19px;
        }
        button[type=submit] {
          width: 100%;
          background: #fbd255 url(../img/download_icon.png) no-repeat;
          margin: 9px 0 0px;
          font-size: 0.8rem;
          padding: .685rem 3rem;
          padding-left: 0rem;
          background-position: 158px 12px;
        }
        .form-group {
          margin-bottom: 0.75rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
        label {
          color: #6d7c7f;
          font-size: .5625rem;
          margin: .79rem .15rem .1rem;
          margin-top: 0;
          margin-top: 12px;
          line-height: 1;
          text-align: left;
          &::before {
            // top: 0rem;
            // top: 3px;
          }
        }
      }
    }
  }
  section#sec4 {
    margin: 0;
    padding: 36px 0 70px;
    h2 {
      font-size: 1.125rem;
      line-height: 1.65;
      br{
        display: none;
      }
    }
    .quiz_block {
      margin-top: 35px;
      margin-bottom: 0px;
      height: auto;
      //height: 360px;
      //padding-bottom: 29px;
      min-height: unset;
      .quiz_left {
        width: 100%;
        float: none;
        form{
          margin-left: 15px;
          margin-right: 15px;
          h3{
            br{
              display: none;
            }
          }
          &#QuizRequestCallback{
            .row{
              display: block !important;
              .col{
                margin: 0 auto 15px;
                width: 400px;
              }
            }
          }
        }
      }
      .progress__label {
        font-size: .625rem;
        padding-top: 19px;
      }
      .progress {
        height: .45rem;
        width: auto;
        margin: auto 12px;
      }
      .question__title {
        font-size: 1rem;
        padding: 30px 0 2px;
      }
      .quiz__question {
        // padding-left: 40px;
        // padding-right: 5px;
        .card-body {
          display: block;
          width: auto;
          padding: 1rem 1.25rem;
          padding: 0;
          padding-left: 83px;
          height: 80px;
          .card-text {
            font-size: 10px;
            height: 36px;
            display: flex;
            align-items: center;
            padding: 8px;
            height: 100%;
          }
        }
        .card {
          text-align: left;
          position: relative;
          //margin-bottom: 22px;
          border-radius: 8px;
          display: block;
          border: 1px solid rgba(0, 0, 0, 0.04);
          margin-bottom: 5px;
          img {
            display: block;
            // padding: 8px;
            // border-radius: 20px;
            height: 80px;
            width: 80px;
            float: left;
            padding: 6px;
            border-radius: 12px;
            height: 100px;
            width: 100px;
            &+.card-body{
              height: 100px;
              padding-left: 100px;
              .card-text {
                font-size: 12px;
                font-family: "Uni Neue Regular";
              }
            }
          }
          &.card__mini{
            img {
              height: auto;
            }
            .card-body{
              height: auto;
              .card-text {
                height: 24px;
                font-size: 14px;
                justify-content: center;
              }
            }
          }
          &.card__mini2 {
            height: 100px;
          }
          &.card__mini3 {
            .card-body3 {
              height: 70px;
            }
          }
        }
      }

    }
  }
  section#sec5 {
    padding: 35px 0 19px;
    h2 {
      line-height: 1.5;
      font-size: 1.125rem;
      margin: 0 0.95rem .5rem;
      br {
        display: none;
      }
      &+p {
        margin: 18px 0 37px;
        font-size: .875rem;
        &:before {
          width: 35px;
          padding-top: 12px;
        }
      }
    }
    .col-1-5{
      //display: none;
      // &:first-child{
      //   display: flex;
      //   margin: auto;
      // }
    }
    .catalog_slider {
      //padding: 0 40px;
      padding: 0 20px;
      .card {
        margin: 20px 0;
        margin: 20px;
      }
    }
  }
  section#sec6 {
    padding: 46px 0 124px;
    background: #ccc url(../img/section_06_320.jpg) no-repeat;
    background-position: bottom center;
    background-size: cover;
    .laserEngraving_block {
      h2 {
        font-size: 1.125rem;
        line-height: 1.75;
        span {
          font-size: 1.125rem;
        }
      }
    }
    .economy_block {
      margin: 45px 0 -24px;
      margin: 45px 0 0;
      span {
        font-size: 12.5px;
        float: left;
      }
      .economy_value {
        font-size: 10px;
        float: left;
        line-height: 50px;
        padding: 0px 14px;
        margin: -12px 0 0;
        span {
          font-size: 50.5px;
          line-height: 1;
          padding-right: 6px;
        }
      }
      .economy_text {
        margin-top: -10px;
        padding: 0;
        width: 100%;
        font-size: 12px;
        &:before {
          width: 55px;
          padding-top: 16px;
        }
        br{
          display: none;
        }
      }
      .free_engraving {
        font-size: 10px;
        margin: 20px 0 15px;
        background: none;
        position: relative;
        width: 195px;
        padding-bottom: 0;
        &:before{
          content: "";
          display: block;
          position: absolute;
          background-image: url(../img/array_icon.png);
          background-repeat: no-repeat;
          width: 215px;
          height: 190px;
          background-size: 32px;
          background-position: 178px 137px;
          margin-top: 15px;
          transform: rotate(-70deg);
          margin: auto;
          width: 45px;
          height: 60px;
          background-position: 4px 6px;
          right: -27px;
        }
        br {
          display: none;
        }
      }
    }
    .bookingForm {
      form {
        width: 200px;
        padding: 4px 0 0;
        .form-group {
          margin-bottom: 13px;
          &:nth-last-child(2) {
            margin-bottom: 1.0rem;
          }
        }
        .custom-control-label {
          margin-right: -18px;
        }
      }
      input[name=laser-engraving_phone] {
        height: calc(2.5rem + 2px);
        font-size: 13px;
        padding-left: 48px;
      }
      button[type=submit] {
        width: 100%;
        font-size: 12px;
        padding: .75rem 1.3rem;
      }
    }
  }
  section#sec7 {
    background: #faf9fe;
    padding: 34px 0 64px;
    h2 {
      font-size: 1.125rem;
      line-height: 1.65;
      text-align: center;
      br{
        display: none;
      }
    }
    .comments_block {
      width: 100%;
      height: 400px;
      margin: 24px auto;
    }
    .slide-item {
      height: 380px;
      min-width: 290px;
    }
    .slick-dots {
      bottom: -76px;
      li button:before {
        font-size: 14px;
        // line-height: 50px;
        // width: 50px;
        // height: 50px;
      }
    }
    .customers_count {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 36px 0 0;
      span {
        width: 155px;
        font-size: 3.625rem;
        float: left;
      }
      p {
        font-size: 10px;
        margin: 20px 0 0 171px;
        line-height: 1.6;
        &:before {
          display: none;
        }
      }
    }
    .comments_logos {
      height: 170px;
      margin: 5px auto -13px;
      background: url(../img/comments_logos_320.png) no-repeat;
      background-size: cover;
      background-size: contain;
      background-position: top center;
    }
  }
  section#sec7{
    .slick-dots {
      bottom: -46px;
    }
    .slick-next,
    .slick-prev {
      // width: 28px;
      // height: 28px;
      // background-size: 6px;
      background-size: 8px;
      top: 48%;
      top: 47.6%;
    }
    .slick-prev {
      left: 3px;
      left: 8px;
      left: 0;
    }
    .slick-next {
      right: 5px;
      right: 8px;
      right: 0;
    }
    .slide-item {
      //height: 380px;
      //min-width: 290px;
      min-width: auto;
      width: 243px;
      // margin: auto;
      // margin: 0 25px;
      border: none;
      // height: 395px;
      // margin: 0 25px 15px;
      margin: 0 25px 30px;
      height: 380px;
      height: 360px;
      box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
      &:before {
        content: "";
        position: absolute;
        height: 30px;
        width: 200px;
        top: auto;
        bottom: -15px;
        left: 0;
        right: 0;
        /* width: 0%; */
        /* margin: auto; */
        border-radius: 15px;
        /* transition: .3s; */
        /* transition-delay: .3s; */
        background-color: #ffffff;
        z-index: -1;
        margin: auto;
        box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
        bottom: -20px;
        width: 190px;
        width: 75%;
      }
      &:after {
        content: "";
        position: absolute;
        height: 30px;
        width: 200px;
        top: auto;
        bottom: -15px;
        left: 0;
        right: 0;
        /* width: 0%; */
        /* margin: auto; */
        border-radius: 15px;
        /* transition: .3s; */
        /* transition-delay: .3s; */
        background-color: #ffffff;
        z-index: -1;
        margin: auto;
        box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
        bottom: -10px;
        width: 220px;
        width: 88%;
      }
    }
    .card_img_blk {
      background: none;
      border-radius: 0;
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 142px;
      height: 150px;
      img {
        height: auto;
        width: 130%;
        margin: -38px -37px 0;
        margin: auto;
        width: 100%;
       // margin-top: -5px;
      }
    }
    .card-img-overlay {
      top: 77px;
      top: 85px;
      width: 100%;
      bottom: auto;
      .card-title {
        color: #fff;
        margin-bottom: 0;
        font-size: 0.85rem;
      }
    }
    .card-body {
      position: absolute;
      width: 100%;
      padding: 15px;
      top: 142px;
      top: 145px;
      h5 {
        font-size: 14px;
        line-height: 1.45;
        padding: 0;
        text-align: center;
        margin-bottom: .55rem;
      }
      img {
        display: none;
      }
      p {
        font-size: 12px;
        text-align: center;
        margin-bottom: 17px;
        padding: 0 10px;
        text-align: left;
      }
      button[type=button] {
        font-size: 12px;
        padding: .75rem 2.3rem .55rem;
        padding-left: 1.35rem;
        padding-right: 3rem;
        // background-position: 156px 16px;
        // background-size: 15px;
        .speaker_icon {
          top: 13px;
          right: 18px;
          background-size: 16px;
          background-position: center;
        }
      }
    }
  }
  section#sec8 {
    padding: 49px 0 35px;
    padding: 49px 0 44px;
    background: #ccc url(../img/section_08_320.jpg) no-repeat;
    background-size: cover;
    h2 {
      line-height: 1.65;
      margin-bottom: 14px;
      font-size: 18px;
      text-align: center;
      br{
        display: none;
      }
    }
    p {
      font-size: 14px;
      text-align: center;
    }
    .delivery_advantages {
      margin: 29px 0 20px;
      height: auto;
      height: 190px;
      .delivery_advantages-item{
        height: auto;
        padding-left: 32px;
        background-size: 60px;
        background-position: -18px -6px;
        margin-left: 0px;
        span {
          font-size: 34px;
          float: left;
          padding-right: 10px;
          margin-top: -3px;
        }
        p {
          font-size: 12px;
          text-align: center;
          padding: 3px 0 0;
          line-height: 1.5;
        }
        &:nth-child(1){
          width: 60%;
        }
        &:nth-child(2){
          display: none;
        }
        &:nth-child(3){
          width: 40%;
        }
      }
    }
    .quiz_questions {
      margin: 35px 0;
      width: 100%;
      // padding: 15px 38px 25px;
      // padding: 15px 14px 25px;
      padding: 15px 14px 12px;
      form {
        padding: 0 24px;
        label {
          margin-bottom: 6px;
          font-size: 8px;
          padding-left: 13px;
          padding-left: 0;
        }
        .form-group {
          margin-bottom: 2px;
        }
        .align-items-end .form-group{
          margin-top: 14px;
          //margin-top: 9px;
          label{
            height: 42px;
            line-height: 42px;
            float: left;
            vertical-align: middle;
            padding-right: 17px;
          }
        }
        .quantity-arrow-minus {
          top: 1px;
          height: 40px;
          width: 38px;
          line-height: 40px;
          left: 74px;
          float: left;
          left: 133px;
        }
        .quantity-arrow-plus {
          top: 1px;
          height: 40px;
          width: 38px;
          line-height: 40px;
          right: 7px;
          left: 178px;
          float: left;
          left: 237px;
        }
        input[type=number] {
          height: calc(2.5rem + 2px);
          font-size: 12px;
          font-size: 16px;
          color: #6d7c7f;
          padding-left: 45px;
          padding-right: 45px;
          background-size: 12px;
          width: 146px;
          float: left;
        }
        button[type=submit] {
          width: 100%;
          margin-top: -5px;
          font-size: 14px;
          line-height: 1.3;
          padding: .75rem 1.3rem;
        }
        input[type=text]+span {
          top: 36px;
          right: 10px;
        }
      }
      input[type=text] {
        height: calc(2.5rem + 2px);
        font-size: 12px;
        font-size: 11px;
        padding-left: 35px;
        background-position: 13px;
        background-size: 11px;
      }
    }
    .result {
      margin: 17px 0 0;
      margin: 19px 0 0;
      .table {
        thead th {
          font-size: 10px;
          vertical-align: middle;
          line-height: 1.3;
        }
        td {
          font-size: 10px;
          line-height: 2.0;
          a {
            font-size: 10px;
          }
          &:first-child {
            padding-right: 8px;
          }
        }
      }
    }
  }
  section#sec9 {
    background: none;
    height: 563px;
    .address_block {
      width: 265px;
      width: auto;
      height: 316px;
      top: 178px;
      left: 0;
      right: 0;
      padding: 20px 10px;
      margin: 0 28px;
      h2 {
        line-height: 1.6;
        font-size: 18px;
        padding-left: 50px;
        margin-bottom: 11px;
      }
      address{
        font-size: 16px;
        padding: 0 11px;
        strong {
          font-size: 12px;
        }
        span {
          font-size: 18px;
        }
        .address_item {
          padding-left: 35px;
          &.address_item--phone {
            background-position: left 26px;
          }
          &.address_item--location {
            background-position: left 21px;
          }
          &.address_item--email {
            background-position: left 20px;
            background-position: left 26px;
            margin-top: -6px;
          }
        }
      }
    }
  }
  footer{
    .navbar-brand {
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 1.85rem 0 1.2rem;
      img {
        height: 28px;
      }
    }
    .navbar-dark .navbar-nav .nav-link {
      font-size: 10px;
      padding: 5px;
      margin: auto;
      text-align: center;
    }
    .footer_menu {
      padding: 0px;
    }
    .socials-list {
      padding: 36px 0 32px;
      text-align: center;
    }
    .socials {
      margin: 0 10px;
    }
  }
  .btn.button_play_pause.button_play_pause--animation:after,
  .btn.button_play_pause.button_play_pause--animation:before{
    width: 206px;
    height: 56px;
  }
}
@media screen and (orientation : portrait) and (max-width: 640px){
  section#pageThanks {
    height: 94vh;
    padding-top: 20vh;
  }
  section#page404 {
    height: 86vh;
    height: 92vh;
    height: 94vh;
    padding-top: 20vh;
  }
}

@media screen and (min-width: 0px) and (max-width: 370px){
  #orderModal {
    .modal-content {
      padding: 0;
      background-position: -72px 0%;
      h3 {
        text-align: center;
        margin: 10px 0 0px;
        font-size: 1.6rem;
      }
      .orderDiv {
        padding: 20px 0 0px;
        margin: auto;
        width: auto;
      }
    }
  }
  .catalog_slider .slick-dots li {
    margin: 0 3px;
  }
}
@media screen and (min-width: 0px) and (max-width: 480px){
  .btn.button_play_pause.button_play_pause--animation:after,
  .btn.button_play_pause.button_play_pause--animation:before {
    width: 203px;
    height: 56px;
  }
  header {
    .menu2 {
      width: 268px;
      padding: 13px 18px;
      .menu2_close {
        margin-bottom: 9px;
        font-size: 20px;
        line-height: 1;
      }
      .navbar-nav {
        text-align: right;
        padding-right: 28px;
      }
      .nav-link {
        font-size: 1rem!important;
        padding-bottom: .5rem!important;
      }
    }
    .navbar {
      padding: 16px 4px;
      padding: 17px 4px 15px;
    }
    .navbar-brand img {
      height: 10px;
      padding-left: 12px;
      width: 210px;
    }
    .navbar-toggler {
      padding: 0;
      padding-right: 9px;
      margin-top: -10px;
      margin-top: -14px;
    }
    .navbar-toggler-icon {
      width: 1.0em;
      height: 0.8em;
    }
    .header_contacts {
      padding: 9px 12px 9px;
    }
    .header_mailblock a {
      font-size: 10px;
    }
    .header_phoneblock {
      padding: 1px 0 0 30px;
      background-position: 15px 11px;
      background-size: 0.65rem;
      a {
        font-size: 10px;
      }
    }
  }
  #testDriveModal2 {
    .modal-dialog {
      width: 280px;
      margin: auto;
    }
    // .modal-body {
    //   padding: 0;
    // }
    .test-drive_form {
      width: 100%;
      padding: 15px 15px;
      h4 {
        font-size: 1.0rem;
      }
      .form-group {
        margin-bottom: .75rem;
      }
      button[type=submit] {
        padding: .75rem 1.0rem .95rem;
        font-size: 1rem;
        line-height: 1;
      }
      #testDrive2_range2+p span {
        &:first-child {
          width: 35px;
        }
        &:nth-child(2) {
          width: 62px;
        }
        &:nth-child(3) {
          width: 49px;
        }
        &:nth-child(4) {
          width: 62px;
        }
        &:nth-child(5) {
          width: 38px;
        }
      }
      #testDrive2_range1+p span{
        &:first-child {
          width: 27px;
        }
        &:nth-child(2) {
          width: 118px;
        }
        &:nth-child(3) {
          width: 33px;
        }
        &:nth-child(4) {
          width: 65px;
        }
      }
    }
  }
  section#main{
    padding-top: 22vh;
    h1 {
      font-size: 1.5rem;
      line-height: 1.3;
    }
    .slogan {
      line-height: 1.5;
      font-size: 0.9375rem;
      margin-top: 0.65rem;
    }
    .test-drive_div {
      margin: 25px 0 20px;
      margin: 27px 0 3px;
      span {
        width: 42px;
        font-size: 50px;
        line-height: .8;
      }
      p {
        font-size: 0.875rem;
        padding-left: 42px;
        line-height: 1.5;
      }
    }
    .vision640 {
      padding: 0.65rem 1.25rem 0.75rem;
      font-size: 12px;
    }
    .advantage_cycles {
      div {
        font-size: 9px;
        padding-top: 3px;
        padding-left: 2px;
      }
      &.d-md-none {
        margin: 2.65rem 0 3rem;
        font-size: 23px;
        &:before {
          border-top: 4px solid #92cd75;
          width: 30px;
          padding-top: 12px;
        }
      }
    }
  }
  .catalog_slider .card {
    //margin: 35px;
    margin: 10px;
    margin: 40px;
    margin: 20px;
    padding: 15px;
    .card-img-top {
      padding: 2rem 2rem 0;
    }
  }
  // .catalog_slider .slick-dots li button:before {
  //   font-size: 15px;
  //   line-height: 30px;
  //   width: 30px;
  //   height: 30px;
  // }
  .catalog_slider.slick-dotted.slick-slider {
    //margin-bottom: 30px;
    margin: 0;
  }
  .catalog_slider{
    .slick-dots {
      margin: -25px auto;
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
  section#sec5 {
    padding: 65px 0 65px;
    h2+p {
      margin: 35px 0 5px;
    }
  }
  section#sec8 .delivery_advantages .delivery_advantages-item:before{
    display: none;
  }
  section#sec4 {
    .quiz_block .quiz__question .card {
      margin-bottom: 15px;
      &.card__mini2 {
        img {
          width: auto;
        }
        .card-text2 {
          text-align: right;
          padding-right: 30px;
        }
      }
    }
    .quiz_block .progress__label {
      font-size: 14px;
    }
  }
  section#sec2 {
    background: url(../img/section_02.jpg) no-repeat bottom center #fff;
    background-size: cover;
    .advantage_left{
      background-image: url(../img/adv_button2.png);
      background-repeat: no-repeat;
      background-position: 76px -29px;
      background-position-x: center;
      background-size: 250px;
    }
  }
}
@media screen and (min-width: 360px) and (max-width: 480px){
  #orderModal .modal-content {
    background-position: -40px 0;
  }
  #orderModal .modal-content {
    background-position: -75px 0;
    padding: 0;
    h3 {
      font-size: 1.7rem;
      margin: 48px 0 4px;
    }
    .orderDiv {
      width: auto;
      padding: 22px 0 0px;
      margin: auto;
    }
  }
  #testDriveModal2 .test-drive_form button[type=submit] {
    padding: .95rem .5rem .95rem;
    font-size: 14px;
  }
  header {
    .navbar {
      padding: 27px 4px 15px;
      padding: 27px 10px 15px;
    }
    .navbar-toggler {
      padding-right: 16px;
      padding-right: 0;
      margin-top: -6px;
    }
    .navbar-toggler-icon {
      width: 1.6em;
      height: 1.2em;
    }
    .navbar-brand img {
      height: 14px;
      padding-left: 20px;
      padding-left: 5px;
      width: auto;
    }
    .header_contacts {
      padding: 25px 20px 9px;
      padding: 7px 5px 20px;
    }
    .header_mailblock a {
      font-size: 15px;
      font-size: 13px;
    }
    .header_phoneblock {
      padding: 6px 0 0 30px;
      background-position: 7px 11px;
      background-size: .95rem;
      a {
        font-size: 15px;
        font-size: 13px;
      }
    }
  }
  section#main {
    padding-top: 15vh;
    padding-top: 20vh;
    .main-left {
      margin-top: 4px;
      margin-bottom: 0;
    }
    h1 {
      font-size: 2.3rem;
      font-size: 1.75rem;
      line-height: 1.2;
      font-size: 1.6rem;
      margin: 0;
    }
    .slogan {
      line-height: 1.5;
      font-size: 1.4rem;
      margin-top: 1.0rem;
      line-height: 1.3;
      font-size: 1.2rem;
      margin: 0.5rem 0 1.75rem;
    }
    .test-drive_div {
      margin: 50px 0 12px;
      margin: 0px 0 12px;
      &.d-md-none{
        width: 100%;
      }
      span {
        width: 71px;
        font-size: 74px;
        width: 55px;
        font-size: 65px;
      }
      p {
        font-size: 1.3rem;
        padding-left: 71px;
        font-size: 1.0rem;
        padding-left: 55px;
      }
    }
    .vision640 {
      padding: 1.0rem 1.95rem 1.0rem;
      font-size: 18px;
      font-size: 14px;
    }
    .advantage_cycles{
      &.d-md-none {
        margin: 4.0rem 0 3rem;
        font-size: 35px;
        width: 100%;
        margin: 2rem 0 3rem;
        font-size: 30px;
        &:before {
          border-top: 5px solid #92cd75;
          width: 53px;
          padding-top: 19px;
          border-top: 4px solid #92cd75;
          width: 40px;
          padding-top: 15px;
        }
      }
      div {
        font-size: 14px;
        padding-top: 2px;
        padding-left: 6px;
      }
    }
  }
  section#sec3 {
    background-position: 0 -44px;
    background-position: 0 -117px;
  }
  section#sec8 {
    background-position: 0 -30px;
  }
  section#sec8 {
    background-position: 0 -110px;
  }
}
@media screen and (max-width: 420px){
  .block_thanks_main {
    .error_page {
      img {
        width: 270px;
      }
      h1 {
        font-size: 26px;
        margin: 25px 15px 18px;
        line-height: 1.2;
      }
      p {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
  section#pageThanks .block_thanks_main {
    h1 {
      font-size: 30px;
      line-height: 1;
    }
    p {
      font-size: 17px;
      line-height: 1.3;
      padding: 0 35px;
    }
  }
}
@media screen and (min-width: 375px){
  section#sec3 {
    //background-position: 0 -65px;
  }
  // section#sec8 {
  //   background-position: 0 -70px;
  //   background-position: 0 -59px;
  // }
}
//@media screen and (max-width: 375px){
@media screen and (max-width: 377px){
  .quiz_left .test__kviz06--item{
    padding: 0;
  }
  section#sec4 .quiz_block .quiz_left form {
    margin: auto;
    width: 100%;
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_1 {
    padding: 18px 13px;
    padding-right: 0;
    width: 160px;
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span {
    font-size: 42px;
    width: 135px;
    margin-right: 6px;
    padding: 0 26px 0 36px;
    width: 100%;
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span:after {
    width: 17px;
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini2 {
    margin-bottom: 15px;
  }
  section#sec3 {
    background-position: 0 -28px;
  }
  section#sec8 {
    background-position: 0 -77px;
    background-position: 0 -60px;
    .quiz_questions {
      form input[type=number] {
        width: 143px;
        width: 126px;
      }
      form .quantity-arrow-plus {
        left: 235px;
        left: 218px;
      }
    }
  }
}
@media screen and (max-width: 362px){
  section#sec8 .quiz_questions {
    form input[type=number] {
      width: 128px;
    }
    form .quantity-arrow-plus {
      left: 219px;
    }
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_1 {
    font-size: 14px;
    width: auto;
    line-height: 35px;
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span {
    line-height: 70px;
  }
}
@media screen and (max-width: 362px){
  section#page404 {
    height: 105vh;
    padding-top: 22vh;
  }
  section#sec4 .quiz_block .quiz__question .card img {
    height: 80px;
    width: 80px;
    width: auto;
    padding: 6px 0;
  }
  section#sec4 .quiz_block .quiz__question .card img {
    padding: 6px;
  }
  section#sec4 .quiz_block .quiz__question .card img+.card-body {
    height: 80px;
    padding-left: 0;
    .card-text{
      padding: 8px 5px;
    }
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini2 img {
    width: auto;
    height: 99px;
    padding: 25px 20px;
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini2 .card-text2 {
    height: 99px;
    font-size: 22px;
    width: auto;
  }
  section#sec4 .quiz_block .quiz__question {
    padding: 0;
  }
  section#sec4 .quiz_block .quiz_left form {
    margin: auto;
    width: auto;
    max-width: 100%;
    padding: 0 15px;
  }
  section#sec4 .quiz_block .quiz_left form h3 {
    font-size: 20px;
    line-height: 1.4;
  }
  section#sec8 .quiz_questions form .align-items-end .form-group label {
    height: 10px;
    line-height: 1;
    width: 100%;
  }
  section#sec8 .quiz_questions form .quantity-arrow-minus,
  section#sec8 .quiz_questions form .quantity-arrow-plus {
    top: 17px;
  }
  section#sec8 .quiz_questions form .quantity-arrow-minus {
    left: 7px;
  }
  section#sec8 .quiz_questions form .quantity-arrow-plus {
    left: 93px;
  }
  section#sec8 .quiz_questions form input[type=number] {
    width: 128px;
  }
  section#sec8 {
    background-position: 0 -45px;
  }
}
@media screen and (max-width: 325px){
  .block_thanks_main .error_page {
    img {
      width: 240px;
    }
    h1 {
      font-size: 23px;
      margin: 10px 15px 15px;
    }
    p {
      font-size: 16px;
      padding: 0 15px;
    }
  }
  section#sec8 {
    background-position: 0 -5px;
    // .quiz_questions form input[type=number] {
    //   width: 126px;
    // }
    // .quiz_questions form .quantity-arrow-plus {
    //   left: 217px;
    // }
  }
  .quiz_left .test__kviz06--item {
    padding: 0 15px;
  }
}
@media screen and (min-width: 470px) and (max-width: 480px){
  header {
    .header_contacts {
      padding: 25px 5px 10px;
    }
    .header_mailblock a {
      font-size: 16px;
    }
    .header_phoneblock a {
      font-size: 16px;
    }
  }
  section#main {
    h1 {
      font-size: 2.3rem;
    }
    .slogan {
      font-size: 1.4rem;
    }
    .test-drive_div{
      span {
        width: 71px;
        font-size: 74px;
      }
      p {
        font-size: 1.3rem;
        padding-left: 71px;
      }
    }
    .advantage_cycles.d-md-none {
      font-size: 35px;
    }
  }
}
@media screen and (min-width: 480px) and (max-width: 640px){
  #orderModal .modal-content {
    background-position: 3px 0;
    padding-left: 44px;
  }
  #orderModal .modal-content h3 {
    font-size: 2rem;
    margin: 70px 0 4px;
  }
  section#sec2 {
    padding: 50px 0 0;
    background-position: top center;
    .advantage_right h3 {
      font-size: 1.5rem;
      line-height: 1.5;
      padding: 0;
      text-align: center;
      padding: 0 10px;
    }
    .adv_block:before {
      display: none;
    }
    .adv_item {
      padding-left: 20px;
    }
    .production-video.d-md-none .production-video__img {
      p {
        padding-left: 20px;
        width: 120px;
        font-size: 12px;
      }
      .final-top-l__btn {
        width: 150px;
        height: 150px;
        margin: 48px auto 0px;
        width: 70px;
        height: 70px;
        margin: 90px auto;
        &:before,
        &:after {
          width: 70px;
          height: 70px;
        }
      }
    }
    .production-start {
      margin: 55px 0 0;
    }
  }
  section#sec3 {
    background-position: 0 -200px;
    h2 {
      font-size: 1.5rem;
      text-align: center;
      br{
        display: none;
      }
      &+p {
        font-size: 1.2rem;
        line-height: 1.35;
        text-align: center;
        br{
          display: none;
        }
      }
    }
    .description {
      // width: 60%;
      // margin: 5px auto 0;
      margin-left: 15px;
    }
    .description .desc {
      background-position: -18px -9px;
      background-size: 60px;
      span {
        line-height: 20px;
        width: 25px;
        font-size: 12px;
        margin-right: 15px;
        &:after{
          top: -5px;
          left: -2px;
        }
      }
      .desc-text {
        font-size: .85rem;
        font-family: "Uni Neue Regular";
      }
    }
    .description_block form {
      width: 300px;
      //margin: 154px auto 60px;
      //margin: 174px auto 40px;
      margin: 183px auto 40px;
    }
  }
  section#sec4 {
    padding: 36px 0 54px;
    h2 {
      line-height: 1.4;
      font-size: 1.5rem;
      text-align: center;
      br{
        display: none;
      }
    }
  }
  section#sec5 {
    padding: 65px 0 60px;
    //padding: 65px 0 185px;
    h2 {
      line-height: 1.4;
      font-size: 1.5rem;
      text-align: center;
      margin: 0;
      &+p {
        margin: 35px 0 50px;
        font-size: 1.2rem;
        &:before {
          padding-top: 20px;
        }
      }
    }
  }
  section#sec6 {
    //padding: 60px 0 175px;
    padding: 65px 0 183px;
    .laserEngraving_block h2 {
      line-height: 1.4;
      font-size: 1.5rem;
      text-align: center;
    }
    .economy_block {
      width: 310px;
      margin: 60px auto 10px;
      span {
        font-size: 15.5px;
      }
      .economy_value {
        font-size: 14px;
      }
      .economy_text {
        font-size: 14px;
        &:before {
          width: 67px;
          padding-top: 20px;
        }
      }
      .free_engraving {
        font-size: 12px;
        width: 265px;
        margin: 25px 0 20px;
      }
    }
    .bookingForm form {
      width: 240px;
      margin: auto;
      padding: 15px 0 15px;
      .form-group {
        margin-bottom: 16px;
      }
    }
  }
  section#sec7 {
    h2 {
      font-size: 1.5rem;
      text-align: center;
      line-height: 1.4;
    }
    .comments_block {
      height: 320px;
      height: 390px;
      float: none;
    }
    .slide-item {
      height: 320px;
      height: 340px;
    }
    .slick-dots {
      bottom: -65px;
    }
    .customers_count {
      padding: 25px 0 0;
      width: 315px;
      margin: auto;
      float: none;
      p {
        font-size: 10px;
        margin: 20px 0 0 168px;
      }
    }
    .comments_logos {
      margin: 0px auto -44px;
      height: 200px;
    }
    .comments_logos {
      margin: 35px auto -15px;
    }
    .card_img_blk{
      height: 162px;
      img {
        width: 60%;
        margin: 20px auto 0;
        width: 70%;
        margin: 0 auto;
        text-align: center;
      }
    }
    .card-img-overlay {
      top: 97px;
    }
    .card-body {
      top: 162px;
    }
  }
  section#sec8 {
    padding: 50px 0 20px;
    padding: 50px 0 35px;
    h2 {
      margin-bottom: 20px;
      font-size: 1.5rem;
      text-align: center;
      line-height: 1.4;
    }
    p {
      font-size: 16px;
    }
    .quiz_questions {
      margin: 2px auto 0;
      margin: -13px auto 0;
      padding: 15px 14px 12px;
      width: 350px;
      width: 365px;
    }
    .delivery_advantages {
      margin: 29px 0 20px;
      height: auto;
      .delivery_advantages-item {
        //margin: 0 0 15px;
        margin: 0 5% 15px;
        width: 40%;
        span {
          font-size: 39px;
        }
        p {
          font-size: 14px;
        }
        &:nth-child(1),
        &:nth-child(3) {
          width: 40%;
        }
      }
    }
    .result .table {
      thead th {
        font-size: 12px;
      }
      td {
        font-size: 12px;
        a {
          font-size: 12px;
        }
      }
    }
    .quiz_questions form .align-items-end .form-group {
      text-align: center!important;
      button[type=submit] {
        max-width: 230px;
      }
    }
  }
  section#sec9 {
    height: 561px;
    .address_block {
      width: 300px;
      height: 330px;
      left: 50px;
      top: 160px;
      left: 0;
      top: 170px;
      margin: auto;
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 768px){
  .fullpage {
    height: auto;
    padding-bottom: 120px;
  }
  header .header_phoneblock span.menu2_open,
  header .header_phoneblock span.menu_open {
    margin: 0 0 0 99px;
  }
  section#sec2 {
    padding: 65px 0;
    background-position: top center;
    .advantage_right h3 {
      font-size: 2rem;
      line-height: 1.2;
    }
    .adv_block {
      margin: 35px 0;
      margin: 35px 25px;
    }
    .production-video.d-md-none .production-video__img .final-top-l__btn {
      //margin: 58px auto 10px;
      margin: 8rem auto 8rem;
      width: 70px;
      height: 70px;
      &:before,
      &:after {
        width: 70px;
        height: 70px;
      }
    }
    .production-start .prodstart__right {
      font-size: .85rem;
      line-height: 1.5;
    }
  }
  section#sec3 {
    background-position: 0 -70px;
    padding: 60px 0;
    background-position: 0 -35px;
    padding: 65px 0 147px;
    h2 {
      font-size: 2rem;
      line-height: 1.2;
      &+p {
        font-size: 1.3rem;
        line-height: 1.35;
      }
    }
    .send-catalog_form form {
      margin: 45px 0 60px;
    }
    .description{
      display: none;
      &.description_vision640{
        display: block!important;
        margin-top: 35px;
      }
    }
  }
  section#sec4 {
    padding: 65px 0 65px;
    h2 {
      font-size: 2rem;
      line-height: 1.2;
    }
    .quiz_block{
      margin-top: 60px;
      margin-bottom: 17px;
      .progress__label {
        font-size: .95rem;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .question__title {
        font-size: 1.5rem;
      }
      .quiz__question {
        padding-left: 10px;
        padding-right: 10px;
        .card {
          margin-bottom: 10px;
        }
        .card-body .card-text {
          font-size: 14px;
          font-size: 10px;
        }
      }
    }
  }
  section#sec5 {
    padding: 88px 0 241px;
    padding: 88px 0 60px;
    h2 {
      font-size: 2rem;
      line-height: 1.2;
      &+p {
        margin: 35px 0 50px;
        margin: 40px 0 65px;
        font-size: 1.5rem;
        &:before {
          width: 50px;
          padding-top: 25px;
        }
      }
    }
  }
  section#sec6 {
    padding: 110px 0 216px;
    .laserEngraving_block h2 {
      font-size: 2rem;
      line-height: 1.2;
    }
    .economy_block {
      margin: 80px auto 40px;
      .free_engraving {
        font-size: 14px;
        margin: 35px 0 20px;
      }
    }
  }
  section#sec7 {
    padding: 60px 0 63px;
    h2 {
      font-size: 2rem;
      line-height: 1.2;
    }
    .comments_block {
      margin: 35px auto;
    }
    .comments_logos {
      margin: 10px auto -37px;
      height: 250px;
    }
  }
  section#sec7 .comments_block {
    width: 100%;
  }
  section#sec8 {
    padding: 75px 0 86px;
    h2 {
      font-size: 2rem;
      line-height: 1.2;
    }
    p {
      font-size: 18px;
    }
    .delivery_advantages {
      margin: 40px 0 40px;
      .delivery_advantages-item {
        width: 45%;
        margin: 0 2.5% 15px;
        padding-left: 48px;
        background-size: 80px;
        background-position: -18px 2px;
        span {
          font-size: 55px;
          margin-top: 0;
        }
        p {
          font-size: 18px;
          padding: 0;
          display: flex;
          align-items: center;
          line-height: 1;
          height: 82px;
        }
        &:nth-child(1),
        &:nth-child(3) {
          width: 45%;
        }
      }
    }
    .quiz_questions {
      width: 380px;
      width: 450px;
      margin: 0 auto 0;
    }
  }
  section#sec9 {
    height: 720px;
  }
}
// @media screen and (min-width: 641px) and (max-width: 767px){

// }
@media screen and (min-width: 768px) and (max-width: 1023px){
  section#main {
    padding-top: 10vh;
    .patent_block {
      font-size: 1.0rem;
      height: 44px;
      background-size: 32px;
      display: none;
    }
    .test-drive_div {
      display: block!important;
      margin-right: 0;
      float: left!important;
      &.d-md-none {
        display: none!important;
      }
    }
    .test-drive_form {
      margin-top: -150px;
    }
    .slogan {
      br{
        display: none;
      }
    }
    .main-left {
      margin: 50px 0;
    }
  }
  .advantage_right {
    //background: none;
    background-position: 250px 260px;
    background-size: 500px;
  }
  section#sec2 {
    .production_process{
      display: none;
    }
  }
  section#sec3 {
    padding: 85px 0 100px;
    //background-position: -405px 0;
    background-position: -505px 0;
    background-position: -660px 0;
    h2 {
      line-height: 1.2;
      font-size: 2.25rem;
      br{
        display: none;
      }
      &+p {
        line-height: 1.35;
        font-size: 1.45rem;
        br{
          display: none;
        }
      }
    }
    .send-catalog_form form {
      margin: 45px 0 140px;
      margin: 55px 0 250px;
      margin: 55px 30px 250px;
    }
  }
  .description .desc{
    height: auto;
    background-position: 1px 0px;
    &.desc1,
    &.desc2 {
      margin-top: 0;
    }
  }
  section#sec4 {
    padding: 5px 0 65px;
    margin-top: -100px;
    h2 {
      line-height: 1.2;
      font-size: 2.25rem;
      br{
        display: none;
      }
    }
    .quiz_block {
      height: auto;
      .quiz_left {
        width: 100%;
        float: none;
      }
      .question__title {
        font-size: 2.0rem;
        padding: 55px 0 30px;
      }
      .progress__label {
        font-size: 1.65rem;
      }
      .quiz__question .card {
        margin-bottom: 30px;
      }
      .quiz__question .card img {
        width: 100%;
        padding: 25px;
      }
    }
  }
  section#sec5 {
    padding: 88px 0 30px;
    h2 {
      line-height: 1.2;
      font-size: 2.25rem;
      br{
        display: none;
      }
    }
    .col-1-5, .col-lg-1-5, .col-md-1-5, .col-sm-1-5, .col-xs-1-5 {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      width: 50%;
    }
    .card {
      margin-bottom: 30px;
      .card-img-top {
        padding: 1.25rem;
        padding: 2rem 5rem 0;
      }
      span {
        width: 50px;
        height: 50px;
        top: -20px;
        right: -20px;
        line-height: 50px;
        font-size: .95rem;
      }
    }
  }
  section#sec6{
    padding: 110px 0 285px;
    padding: 110px 0 345px;
    .laserEngraving_block h2 {
      line-height: 1.2;
      font-size: 2.25rem;
      text-align: center;
      br {
        display: none;
      }
      span {
        font-size: 2.25rem;
      }
    }
    .economy_block {
      margin: 120px 100px 40px;
      margin-bottom: 0;
      .economy_text {
        font-size: 1.0rem;
      }
      .free_engraving {
        font-size: 1.0rem;
        margin: 35px 0 20px;
        background-position: 13px 110px;
        background-position: 13px 90px;
        margin: 0px 0 20px;
        line-height: 1.3;
      }
    }
    .bookingForm form {
      margin: auto;
      padding: 0;
      margin-left: 80px;
    }
  }
  section#sec7 {
    padding: 85px 0 70px;
    h2 {
      line-height: 1.2;
      font-size: 2.25rem;
      text-align: center;
      br {
        display: none;
      }
    }
    .comments_block {
      margin: 56px auto;
      width: 100%;
      /* height: 570px; */
    }
    .slide-item {
      min-width: 690px;
      max-width: 690px;
      // min-width: 100%;
      // max-width: 100%;
      // max-width: 90%;
      height: 370px;
    }
    .customers_count {
      float: none;
      clear: both;
      margin: 56px auto 0;
      width: 500px;
      height: 220px;
    }
    .comments_logos {
      margin: 0 auto;
      height: 280px;
      background: url(../img/comments_logos_320.png) no-repeat;
      background-size: contain;
      background-position: 50%;
    }
    .customers_count {
      height: 320px;
      span{
        float: left;
      }
      p {
        float: left;
        margin: 7px 0 0 25px;
        &:before {
          display: none;
        }
      }
    }
    .card-body {
      img {
        display: none;
      }
      p {
        padding-left: 50px;
      }
    }
  }

  section#sec8 {
    padding: 85px 0 85px;
    background: #ccc url(../img/section_08_320.jpg) no-repeat;
    background-size: cover;
    h2 {
      line-height: 1.2;
      font-size: 2.25rem;
      text-align: center;
    }
    p {
      font-size: 20px;
      text-align: center;
    }
    .delivery_advantages {
      height: 210px;
    }
    .quiz_questions form .form-group.text-right {
      margin: 15px auto;
      text-align: center!important;
    }
    .quiz_questions form input[type=number]{
      width: 200px;
    }
    .quiz_questions form .quantity-arrow-plus {
      right: 0;
      left: 159px;
    }
    .quiz_questions .result .table td{
      padding: 4px 10px 2px;
    }
  }
  section#sec9 .address_block {
    top: 160px;
  }
  footer{
    .navbar-dark .navbar-brand {
      display: flex;
    }
    .navbar-dark .navbar-nav .nav-link {
      padding: .5rem 1rem;
    }
    .socials-list {
      padding: 0;
    }
    .socials {
      margin: 2px;
    }
  }
}
@media screen and (min-width: 962px) and (max-width: 992px){
  .menu2 {
    width: auto;
    padding-left: 85px;
  }
  .menu2 .nav-link {
    font-size: 1.2rem!important;
    padding-bottom: 0.65rem;
  }
  section#main {
    padding-top: 125px;
    padding-top: 155px;
    padding-bottom: 40px;
    h1 {
      font-size: 2.0rem;
      text-align: center;
      br{
        display: none;
      }
    }
    .slogan {
      font-size: 1.4rem;
      text-align: center;
      br{
        display: none;
      }
    }
    .main-left {
      margin-top: 25px;
    }
    .advantage_cycles {
      font-size: 2rem;
      margin: 3rem 8px 3rem;
    }
    .patent_block {
      display: none;
    }
    .test-drive_div {
      float: left!important;
    }
    .test-drive_form {
      margin-right: 0;
      margin-top: -150px;
    }
  }
}

//@media screen and (min-width: 1024px) and (max-width: 1279px){
@media screen and (min-width: 992px) and (max-width: 1279px){
  .advantage_right {
    background-position: 160px 300px;
    background-size: 400px;
    background-position: 210px 380px;
  }
  header .navbar-nav .nav-link {
    padding-right: 0.95rem!important;
    padding-left: 0.95rem!important;
  }
  section#main {
    padding-top: 14vh;
    h1 {
      font-size: 2.6rem;
      text-align: center;
      br{
        display: none;
      }
    }
    .slogan {
      font-size: 1.5rem;
      text-align: center;
    }
    .advantage_cycles {
      font-size: 2.6rem;
      &:before {
        width: 55px;
      }
    }
    .test-drive_div {
      float: left!important;
    }
    .test-drive_div span {
      font-size: 115px;
      line-height: 85px;
    }
    .main-left {
      min-width: 100%;
    }
    .main-right {
      min-width: 100%;
    }
    .patent_block{
      display: none;
    }
    .test-drive_form {
      margin-right: 10px;
      margin-top: -150px;
    }
  }
  section#sec2 {
    .advantage_right h3 {
      font-size: 1.5rem;
      line-height: 1.3;
    }
  }
  section#sec3 {
    background-position: 62% bottom;
  }
  section#sec4 {
    .quiz_block .quiz_right {
      display: none!important;
    }
    .quiz_block .quiz_left{
      width: 100%;
    }
  }
  section#sec7 {
    h2 {
      text-align: center;
    }
    .comments_block {
      margin: 56px auto;
      float: none;
    }
    // .customers_count {
    //   margin: 56px auto 0;
    //   float: none;
    //   padding-bottom: 0;
    //   height: 270px;
    //   span {
    //     font-size: 3.6rem;
    //   }
    //   p:before {
    //     width: 55px;
    //   }
    // }
    .customers_count {
      margin: 56px auto 0;
      float: none;
      padding-bottom: 0;
      height: 140px;
      width: 455px;
      span {
        font-size: 3.6rem;
        float: left;
      }
      p {
        margin: 3px 0 3px 20px;
        display: block;
        float: left;
      }
      p:before {
        display: none;
      }
    }
    .comments_block {
      margin: 56px auto 115px;
    }
    .slick-dots {
      bottom: -95px;
    }
  }
  section#sec8{
    background-position: 65% bottom;
    background-position: 52% bottom;
    .quiz_questions {
      margin: 35px 0;
      width: 400px;
    }
    .delivery_advantages {
      margin: 25px 5% 20px;
      width: 90%;
      height: 490px;
      .delivery_advantages-item {
        background-position: -13px 14px;
        padding-left: 45px;
        span {
          padding-right: 15px;
        }
      }
    }
  }
  section#sec9{
    .address_block {
      width: 405px;
      h2 {
        font-size: 1.8rem;
        text-align: center;
      }
    }
  }
  footer {
    .navbar-dark .navbar-nav .nav-link {
      padding: .5rem 1rem;
    }
  }
}

@media screen and (min-width: 1280px) and (max-width: 1365px){
  section#main {
    padding-top: 17vh;
  }
  .fullpage {
    background-position: 55% bottom;
  }
  section#sec3 {
    background-position: 0 -65px;
    background-position: 43% bottom;
    padding: 85px 0 200px;
  }
  section#sec8 {
    background-position: 60% bottom;
  }
}
@media screen and (min-width: 1366px){
  section#sec3 {
    background-position: 54% bottom;
    padding: 80px 0 187px;
    padding: 80px 0 186px;
  }
  section#sec8 {
    background-position: 60% bottom;
  }
  section#sec9 .address_block {
    width: 400px;
  }
}

@media screen and (max-width: 1028px) and (min-width: 1018px){
  section#main {
    padding-top: 11vh;
  }
  section#sec2 .advantage_right{
    background-position: 240px 360px;
  }
  section#sec9 .address_block {
    top: 213px;
    left: calc(50% - 215px);
  }
  .fullpage {
    height: auto;
    padding-bottom: 20px;
  }
}
/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  section#main {
    padding-top: 21vh;
  }
}
/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  section#sec3 {
    background-position: 0 -116px;
  }
  section#sec8 {
    background-position: 0 -115px;
  }
  section#sec7 .slide-item {
    height: 360px;
  }
  section#sec7 .customers_count {
    height: 155px;
    margin: auto;
    width: 300px;
    float: none;
    clear: both;
  }
}
/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  section#main {
    padding-top: 19vh;
    h1 {
      font-size: 1.75rem;
    }
    .slogan {
      font-size: 1.4rem;
    }
    .test-drive_div {
      span {
        width: 71px;
        font-size: 74px;
      }
      p {
        font-size: 1.3rem;
        padding-left: 71px;
      }
    }
    .advantage_cycles.d-md-none {
      margin: 4rem 0 3rem;
      font-size: 35px;
    }
  }
  section#sec7 .customers_count {
    height: 200px;
    height: 155px;
  }
  // section#sec8 .quiz_questions {
  //   form input[type=number] {
  //     width: 143px;
  //   }
  //   form .quantity-arrow-plus {
  //     left: 235px;
  //   }
  // }
}

@media screen and (min-width: 641px) and (max-width: 769px) and (max-height: 1060px){
  .fullpage {
    height: auto;
  }
  section#main {
    padding-top: 125px;
    padding-bottom: 40px;
  }
  section#sec9 .address_block {
    top: 212px;
    left: calc(50% - 215px);
  }
}
//@media screen and (orientation : portrait) and (min-width: 641px) and (max-width: 784px){
@media screen and (orientation : portrait) and (min-width: 641px) and (max-width: 767px){
  section#sec3 {
    background-position: -272px 0px;
  }
  section#sec3 h2 {
    text-align: center;
    br{
      display: none;
    }
    &+p {
      br{
        display: none;
      }
    }
  }
  section#sec3 .description.description_vision640 {
    display: block!important;
  }
  .description .desc.desc1, .description .desc.desc2 {
    margin-top: 0;
  }
  .description .desc {
    height: auto;
    background-position: 1px 0;
  }
}

@media screen and (orientation : landscape) and (max-width: 960px){
  section#pageThanks {
    padding-top: 129px;
    height: 106vh;
  }
  section#page404 {
    padding-top: 15vh;
    height: 105vh;
  }
  .catalog_slider .card {
    margin: 0;
    margin-bottom: 22px;
  }
  .fullpage {
    height: auto;
  }
  header {
    .header_contacts {
      padding: 0px 12px 35px;
    }
  }
  section#main {
    padding-top: 125px;
    padding-bottom: 40px;
    padding-top: 50px!important;
    padding-top: 105px!important;
    h1 {
      font-size: 2.0rem;
      br{
        display: none;
      }
    }
    .slogan{
      font-size: 1.5rem;
      br{
        display: none;
      }
    }
    .advantage_cycles {
      font-size: 2.0rem;
    }
    .test-drive_div{
      float: left!important;
    }
    .patent_block{
      display: none!important;
    }
    .test-drive_form {
      margin-right: 0;
      margin-top: -150px;
      margin-top: -215px;
      margin-top: -335px;
    }
    .main-left {
      margin-top: 25px;
    }
    .test-drive_div {
      display: none!important;
      &.d-md-none {
        display: block!important;
        p {
          font-size: 1.1rem;
        }
      }
    }

  }
  .menu2 {
    width: 400px;
    .nav-link {
      font-size: 1rem!important;
      padding-bottom: 1.2rem;
    }
  }
  section#sec3 .description {
    display: none!important;
    &.description_vision640 {
      display: block!important;
    }
  }
  // .slick-dotted.slick-slider {
  //   margin: 25px;
  // }
  // .catalog_slider .card {
  //   margin: 10px;
  //   padding: 15px;
  // }
  section#sec7 .customers_count {
    //height: 200px;
    height: auto;
  }
  .quiz_questions form {
    .quantity-arrow-minus,
    .quantity-arrow-plus{
      top: 30px;
    }
  }
}


@media screen and (orientation : landscape) and (max-width: 820px){
  .block_thanks_main .error_page {
    img{
      width: 250px;
    }
    h1 {
      font-size: 26px;
      margin: 15px 0 5px;
      line-height: 1.45;
    }
    p {
      font-size: 21px;
      line-height: 1.55;
      margin-bottom: 30px;
    }
  }
  section#pageThanks {
    padding-top: 78px;
    height: 106vh;
  }
}
@media screen and (orientation : landscape) and (min-width: 810px) and (max-width: 815px){
  section#pageThanks {
    padding-top: 25px;
    height: 106vh;
  }
  section#page404 {
    padding-top: 19vh;
    height: 106vh;
    .error_page .btn.btn-yellow{
      display: none;
    }
  }
  section#sec3 {
    background-position: -450px -60px;
  }
}
//@media screen and (orientation : landscape) and (width: 667px){ /// ????????
@media screen and (orientation : landscape) and (min-width: 665px) and (max-width: 686px){
  .fullpage.fullpage--404 {
    padding-bottom: 60px;
  }
  section#pageThanks {
    padding-top: 10px;
    padding-top: 17px;
    height: 106vh;
  }
  section#page404 {
    padding-top: 9vh;
    height: 107vh;
    padding-top: 15vh;
    height: 110vh;
  }
  .block_thanks_main .error_page {
    h1 {
      font-size: 23px;
      margin: 15px 0 5px;
      line-height: 1.45;
    }
    p {
      font-size: 19px;
      line-height: 1.55;
      margin-bottom: 30px;
    }
  }
  .fullpage {
    background-position-x: -189px;
  }
  .advantage_right {
    background: none;
  }
  .adv_item:nth-child(3) {
    padding-left: 0;
  }
  .adv_item:nth-child(5) {
    display: none;
  }
  section#sec2 .advantage_right h3 {
    padding-left: 5px;
    text-align: center;
  }
  .adv_block:before {
    display: none;
  }
  .description .desc {
    height: auto;
    background-position: 1px 1px;
  }
  .desc1,
  .desc2 {
    margin-top: 0;
  }
  section#sec3 {
    padding: 65px 0 160px;
    background-position: 0 0;
    background-position: 14% 0;
    background-position: 13% 0;
    h2 {
      text-align: center;
      br {
        display: none;
      }
      &+p {
        text-align: center;
        br {
          display: none;
        }
      }
    }
    .description.description_vision640 {
      margin: 25px auto;
    }
    .send-catalog_form form {
      margin: 45px auto 60px;
    }
  }
  .production-start {
    margin: 55px auto 0;
  }
  section#sec4 {
    margin-top: -160px;
    h2 {
      //font-size: 1.5rem;
      br {
        display: none;
      }
    }
    .quiz_block {
      height: auto;
      min-height: unset;
      .quiz_left {
        width: 100%;
        float: none;
        clear: both;
      }
      .progress {
        width: 100%;
      }
      .question__title {
        padding: 30px 0 10px;
      }
    }
  }
  .quiz_questions form .form-group {
    text-align: center!important;
    button[type=submit]{
      margin-top: 15px;
    }
  }
  section#sec5 {
    padding: 88px 0 88px;
    h2 {
      // br {
      //   display: none;
      // }
    }
  }
  section#sec6 {
    background-position-x: 54.5%;
    .laserEngraving_block h2 {
      text-align: center;
      br:first-child {
        display: none;
      }
    }
    .bookingForm{
      margin-bottom: 50px;
    }
    .economy_block {
      margin: 80px auto 0px;
    }
  }
  section#sec7 {
    h2 {
      text-align: center;
      br{
        display: none;
      }
    }
    .slide-item {
      min-width: 500px;
      max-width: 500px;
      max-width: 100%;
    }
    .card-body {
      padding-left: 255px;
      padding-right: 25px;
      img {
        display: none;
      }
      h5 {
        font-size: 1.45rem;
        padding: 0 0 10px;
        padding-left: 0;
      }
      button[type=button] {
        display: none;
      }
    }
    .customers_count {
      width: 230px;
      float: none;
      clear: both;
      height: 330px;
      padding: 80px 0 0;
      margin: 95px auto 0;
    }
    .comments_logos {
      margin: 20px auto 0px;
      height: 220px;
      background: url(../img/comments_logos_320.png) no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  section#sec8 {
    background-position: 70% 0;
    background: #333539 url(../img/section_08_320.jpg) no-repeat;
    background-size: cover;
    background-position: 100% 21%;
    h2 {
      text-align: center;
      br{
        display: none;
      }
      &+p{
        text-align: center;
      }
    }
    .quiz_questions form .form-row.align-items-end .form-group:first-child {
      flex: 0 0 50%;
      flex: 0 0 100%;
      &+.form-group {
        text-align: center!important;
        margin: 20px auto;
      }
    }
  }
  section#sec9 .address_block {
    top: 212px;
    left: calc(50% - 215px);
  }
  .navbar-dark .navbar-brand {
    width: 100%;
    margin: auto;
    padding: 25px 0 20px;
    text-align: center;
  }
  footer .footer_menu {
    padding: 0;
  }
  footer .navbar-dark .navbar-nav .nav-link {
    text-align: center;
  }
  .socials-list {
    padding: 25px 0;
    text-align: center;
  }
  #test__kviz01,
  #test__kviz03{
    .row .col-12{
      flex: 0 0 50%;
    }
  }
  section#sec4 .quiz_block .quiz__question .card {
    margin-bottom: 25px;
  }
  section#sec4 .quiz_block .quiz_left form {
    margin-left: 15px;
    margin-right: 15px;
    h3 {
      br{
        display: none;
      }
    }
  }
  section#sec4 {
    h2 {
      font-size: 26px;
      br{
        display: none;
      }
    }
  }
  .quiz_questions form {
    input[type=number]{
      width: 175px;
      margin: auto;
    }
    .quantity-arrow-minus{
      left: 120px;
    }
    .quantity-arrow-plus{
      right: 118px;
    }
  }
  section#sec7 .card-body p{
    padding-left: 0;
  }
}
@media screen and (orientation : landscape) and (min-width: 733px) and (max-width: 738px){
  //@media screen and (orientation : landscape) and (width: 736px){
  section#pageThanks {
    padding-top: 18px;
    height: 106vh;
    padding-top: 0px;
    height: 108vh;
  }
  section#page404 {
    padding-top: 15vh;
    height: 122vh;
    padding-top: 8vh;
    height: 127vh;
    padding-top: 11vh;
    height: 138vh;
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini2 .card-text2 {
    line-height: 90px;
  }
  section#sec7 .customers_count {
    margin-bottom: 0;
  }
  .fullpage {
    background-position-x: -189px;
  }
  .advantage_right {
    background: none;
    h3 {
      padding-left: 5px;
      text-align: center;
    }
  }
  .adv_item:nth-child(3) {
    padding-left: 0;
  }
  .adv_item:nth-child(5) {
    display: none;
  }
  .adv_block:before {
    display: none;
  }
  .production-start {
    margin: 80px auto 0;
  }
  section#sec3 {
    background-position: -110px 48px;
    padding: 120px 0 147px;
    background-position-x: -140px;
    h2 {
      text-align: center;
      br{
        display: none;
      }
      &+p {
        text-align: center;
      }
    }
    .description.description_vision640 {
      margin-top: 15px;
    }
    .send-catalog_form form {
      margin: 45px auto 60px;
    }
  }
  .desc1,
  .desc2 {
    margin-top: 0;
  }
  .description .desc {
    background-position: 1px 1px;
    height: auto;
  }
  section#sec4 {
    margin-top: -135px;
    .quiz_block {
      height: auto;
      min-height: auto;
      .quiz_left {
        width: 100%;
        float: none;
        clear: both;
      }
      .progress {
        width: 100%;
      }
      .question__title {
        padding: 25px 0 10px;
      }
      .quiz__question {
        padding-left: 20px;
        padding-right: 20px;
        h3 br{
          display: none;
        }
        .card img {
          width: 50%;
          height: auto;
          margin: auto;
          width: 100%;
        }
      }
    }
    h2{
      font-size: 26px;
      br{
        display: none;
      }
    }
  }
  .quiz_questions form {
    input[type=number]{
      width: 175px;
      margin: auto;
    }
    .quantity-arrow-minus{
      left: 120px;
    }
    .quantity-arrow-plus{
      right: 118px;
    }
  }
  section#sec6 {
    background-position-x: 55%;
    .laserEngraving_block h2 {
      text-align: center;
    }
    .bookingForm form {
      margin: -80px 0 60px;
    }
  }
  section#sec7 {
    .slide-item {
      min-width: 500px;
      max-width: 100%;
      height: 358px;
    }
    h2 {
      text-align: center;
      br{
        display: none;
      }
    }
    .card-body {
      padding-left: 255px;
      padding-right: 25px;
      img {
        display: none;
      }
      button[type=button] {
        display: none;
      }
      p{
        padding-left: 0;
      }
      h5 {
        padding: 0;
        text-align: center;
        font-size: 1.35rem;
      }
    }
    .card_img_blk {
      height: 358px;
      height: 356px;
    }
    .customers_count {
      height: 330px;
      width: 500px;
      text-align: center;
      padding: 53px 61px;
      p:before {
        margin: auto;
      }
    }
    .comments_logos {
      height: 40px;
    }
  }
  section#sec8 {
    background: #ccc url(../img/section_08_320.jpg) no-repeat;
    background-size: cover;
    background-position: 0 -165px;
    h2 br{
      display: none;
    }
  }
  section#sec9 .address_block{
    top: 210px;
    left: calc(50% - 215px);
  }
  .quiz_questions form .form-group{
    text-align: center!important;
    button[type=submit]{
      margin-top: 15px;
    }
  }
  .quiz_questions form .form-row .form-group:nth-child(2){
    text-align: center!important;
    margin-top: 15px;
  }
  .navbar-dark .navbar-brand {
    width: 100%;
    text-align: center;
    padding: 20px 0;
  }
  footer .footer_menu {
    padding: 0px 0 25px;
  }
  footer .navbar-dark .navbar-nav .nav-link {
    text-align: center;
  }
  .socials-list {
    padding: 30px 0 0;
    text-align: center;
  }
}
//@media screen and (orientation : portrait) and (width: 414px){
@media screen and (orientation : portrait) and (min-width: 411px) and (max-width: 417px){
  .quiz_left .test__kviz06--item{
    padding: 0;
  }
  #orderModal .modal-content {
    background-position: -38px 0;
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini2 {
    margin-bottom: 15px;
  }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span {
    font-size: 40px;
  }
  section#sec4 .quiz_block .quiz_left form {
    width: 100%;
    margin: auto;
  }
}
@media screen and (orientation : portrait) and (width: 768px){
  section#main{
    padding-top: 125px!important;
    padding-top: 80px!important;
    .test-drive_form {
      float: right!important;
      margin: -150px auto 0!important;
    }
  }
  section#sec7{
    .comments_block {
      margin: 56px auto 115px;
    }
    .slick-dots {
      bottom: -95px;
    }
    .customers_count {
      height: 210px;
    }
  }
}

@media screen and (orientation : landscape) and (min-width: 635px) and (max-width: 645px){
  .fullpage.fullpage--404 {
    padding-bottom: 60px;
  }
  section#page404 {
    padding-top: 21vh;
    height: 120vh;
  }
  .block_thanks_main .error_page {
    h1 {
      font-size: 22px;
      margin: 15px 0 5px;
      line-height: 1.25;
    }
    p {
      font-size: 16px;
      line-height: 1.55;
      margin-bottom: 30px;
    }
  }
}
@media screen and (orientation : landscape) and (max-width: 570px){
  section#sec4 .quiz_block .quiz__question .card.card__mini2 img {
    width: auto;
  }
  section#pageThanks {
    padding-top: 0px;
    height: 106vh;
    .block_thanks_main {
      h1 {
        font-size: 35px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}
@media screen and (orientation : landscape) and (max-width: 486px){
  section#pageThanks {
    padding-top: 13px;
    height: 117vh;
  }
}

// @media screen and (max-width: 460px){
//   section#pageThanks .block_thanks_main {
//     h1 {
//       font-size: 30px;
//       line-height: 1;
//     }
//     p {
//       font-size: 17px;
//       line-height: 1.3;
//     }
//   }
// }
