@charset "UTF-8";
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(../../../node_modules/jquery-ui-dist/jquery-ui.min.css);
@import url(../../../node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css);
@import url(override.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #24262b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c2c2c4; }

.table-hover .table-dark:hover {
  background-color: #b5b5b7; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b5b5b7; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #bbc0c5;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(199, 199, 199, 0.24); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #24262b;
  border-color: #24262b; }
  .btn-dark:hover {
    color: #fff;
    background-color: #131416;
    border-color: #0d0d0f; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(36, 38, 43, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #24262b;
    border-color: #24262b; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #0d0d0f;
    border-color: #070708; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(36, 38, 43, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #24262b;
  background-color: transparent;
  background-image: none;
  border-color: #24262b; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #24262b;
    border-color: #24262b; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(36, 38, 43, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #24262b;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #24262b;
    border-color: #24262b; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(36, 38, 43, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #b6b6b6; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b6b6b6; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: transparent; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #b6b6b6; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #b6b6b6; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #bbc0c5;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(187, 192, 197, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #bbc0c5;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #bbc0c5; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0.1rem 0.25rem rgba(53, 77, 93, 0.75); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0.1rem 0.25rem rgba(53, 77, 93, 0.75); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0.1rem 0.25rem rgba(53, 77, 93, 0.75); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1.125rem;
    height: 1.125rem;
    margin-top: -0.25rem;
    background-color: #a5c9ec;
    border: 0.3125rem solid #fff;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #a5c9ec; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.625rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1.125rem;
    height: 1.125rem;
    background-color: #a5c9ec;
    border: 0.3125rem solid #fff;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #a5c9ec; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.625rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1.125rem;
    height: 1.125rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #a5c9ec;
    border: 0.3125rem solid #fff;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media screen and (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #a5c9ec; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.625rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5625rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #24262b; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0d0d0f; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #131416;
  background-color: #d3d4d5;
  border-color: #c2c2c4; }
  .alert-dark hr {
    border-top-color: #b5b5b7; }
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #131416;
  background-color: #c2c2c4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #131416;
    background-color: #b5b5b7; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #131416;
    border-color: #131416; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      color: #000;
      text-decoration: none;
      opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      transition: none; } }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #24262b !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0d0d0f !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #24262b !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #24262b !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0d0d0f !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../img/slick-carousel/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./../fonts/slick-carousel/slick.eot");
  src: url("./../fonts/slick-carousel/slick.eot?#iefix") format("embedded-opentype"), url("./../fonts/slick-carousel/slick.woff") format("woff"), url("./../fonts/slick-carousel/slick.ttf") format("truetype"), url("./../fonts/slick-carousel/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("../img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("../img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: visible !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("../img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("../img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("../img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-columns {
    column-count: 5; } }

@media (min-width: 1200px) {
  .card-columns {
    column-count: 5; } }

.col-1-5,
.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5 {
  position: relative;
  min-height: 1px;
  padding-right: 11px;
  padding-left: 11px; }

.col-xs-1-5 {
  width: 20%;
  float: left; }

@media (min-width: 768px) {
  .col-sm-1-5 {
    width: 20%;
    float: left; } }

@media (min-width: 992px) {
  .col-md-1-5 {
    width: 20%;
    float: left; } }

@media (min-width: 1200px) {
  .col-lg-1-5 {
    width: 20%;
    float: left; } }

@font-face {
  font-family: "Uni Neue Light";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/UniNeueLight.ttf") format("opentype"); }

@font-face {
  font-family: "Uni Neue Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/UniNeueRegular.ttf") format("opentype"); }

@font-face {
  font-family: "Uni Neue Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/UniNeueBold.ttf") format("opentype"); }

@font-face {
  font-family: "Uni Neue Heavy";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/UniNeueHeavy.ttf") format("opentype"); }

body {
  min-width: 100%;
  min-width: 320px;
  width: 100%;
  display: inline-block;
  display: block;
  color: #333;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-family: "Uni Neue Regular";
  font-weight: normal;
  font-size: 16px; }

.form-control {
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.18); }

/* keyframes ------------------------------------------*/
@-webkit-keyframes circle {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(3.275);
    transform: scale(3.275);
    opacity: 0; } }

@keyframes circle {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(3.275);
    transform: scale(3.275);
    opacity: 0; } }

@-webkit-keyframes circle2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scaleX(1.25) scaleY(1.85);
    transform: scaleX(1.25) scaleY(1.85);
    opacity: 0; } }

@keyframes circle2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scaleX(1.25) scaleY(1.85);
    transform: scaleX(1.25) scaleY(1.85);
    opacity: 0; } }

@-webkit-keyframes circle3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scaleX(1.2) scaleY(1.5);
    transform: scaleX(1.2) scaleY(1.5);
    opacity: 0; } }

@keyframes circle3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scaleX(1.2) scaleY(1.5);
    transform: scaleX(1.2) scaleY(1.5);
    opacity: 0; } }

.modal-backdrop.show {
  opacity: .85; }

ymaps [class$="-map-copyrights-promo"],
ymaps [class$="-copyright_logo_no"] {
  display: none !important; }

.catalog.catalog_slider .col-1-5:focus {
  outline: none; }

.modal-content {
  background-color: #000;
  border: 0px solid #000; }

.navbar-light .navbar-toggler {
  outline: none; }

.btn-yellow {
  color: #000;
  background-color: #fbd255;
  border-color: #fbd255;
  border-radius: 10px;
  padding: .75rem 1.3rem .75rem;
  transition: all 0.25s linear;
  background: radial-gradient(ellipse at center, #fcda61 1%, #fabc2f 100%);
  background: linear-gradient(-76deg, #ffae00 0%, #fbd761 100%);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.29);
  box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
  box-shadow: 0 2px 0 0 #c3880a, 1px 1px 0px #ffaf06 !important;
  border-color: #fec847;
  background: linear-gradient(-76deg, #ffae00 30%, #fed527 100%); }

.custom-checkbox {
  min-height: 0; }

.custom-control-label {
  margin: 0;
  margin-bottom: .5rem;
  cursor: pointer;
  margin-top: 0px;
  margin-top: -5px;
  color: #b6b6b6;
  font-size: 9px; }

.navbar-brand img {
  height: 25px; }

.fullpage {
  background: url(../img/main.jpg) no-repeat bottom center #fff;
  background-size: cover;
  width: 100%;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  position: relative; }
  .fullpage--404, .fullpage--thanks {
    background: url(../img/section_404.jpg) no-repeat bottom center #fff;
    background-size: cover; }

header {
  max-width: 1400px;
  margin: auto;
  position: relative;
  z-index: 1; }
  header .navbar {
    padding: 35px 35px; }
  header .navbar-nav .nav-link {
    color: #333333;
    font-size: 12px;
    font-size: .8rem;
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important; }
    header .navbar-nav .nav-link:focus, header .navbar-nav .nav-link:hover {
      text-decoration: underline; }
  header .header_mailblock {
    margin-top: .2rem;
    float: left; }
    header .header_mailblock a {
      color: #333;
      font-size: 0.9rem;
      display: block; }
  header .header_phoneblock {
    padding-left: 70px;
    padding-left: 72px;
    float: right;
    background-image: url(../img/whatsapp_icon.png);
    background-image: url(../img/whatsapp2_icon.png);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 47px 6px;
    background-position: 47px 13px;
    padding: 8px 0 0 72px; }
    header .header_phoneblock a {
      color: #333;
      font-size: 18px;
      font-size: 1.15rem;
      font-family: "Uni Neue Bold";
      font-weight: normal; }
    header .header_phoneblock span.menu_open,
    header .header_phoneblock span.menu2_open {
      display: block;
      line-height: 1;
      line-height: 1.4;
      text-align: right;
      color: #333;
      font-size: 12px;
      font-size: .75rem;
      text-decoration: dashed;
      text-decoration: underline;
      cursor: pointer;
      margin: 0 0 0 66px;
      text-decoration: none;
      border-bottom: 1px dashed #333; }

section {
  padding: 70px 0;
  position: relative;
  overflow: hidden; }

section#page404,
section#pageThanks {
  padding-top: 210px;
  margin-top: -180px;
  padding-bottom: 82px;
  text-align: center; }

.block_thanks_main {
  margin: 75px auto 0; }
  .block_thanks_main .error_page h1 {
    font-size: 32px;
    margin: 27px 0 21px;
    line-height: 1.45; }
  .block_thanks_main .error_page p {
    font-size: 21px;
    line-height: 1.55;
    margin-bottom: 39px;
    color: #465356;
    font-family: "Uni Neue Light"; }
  .block_thanks_main .error_page a {
    font-family: "Uni Neue Bold";
    font-size: 16px;
    color: #000000;
    padding: .8rem 3.8rem .8rem; }

section#pageThanks .block_thanks_main {
  margin: 125px auto 0; }

section#main {
  padding-top: 210px;
  margin-top: -180px;
  padding-bottom: 82px; }
  section#main .main-left {
    margin-top: 50px; }
  section#main h1 {
    font-size: 48px;
    font-size: 3.0rem;
    color: #333;
    line-height: 1.1; }

.slogan {
  font-weight: 300;
  font-size: 30px;
  font-size: 1.9rem;
  font-family: "Uni Neue Light";
  color: #465356;
  margin-top: 1rem;
  line-height: 1.3; }

.advantage_cycles {
  color: #000;
  font-size: 2.875rem;
  line-height: 1.3;
  margin: 3.75rem 0;
  margin: 5rem 8px 3.0rem; }
  .advantage_cycles span {
    font-family: "Uni Neue Light"; }
  .advantage_cycles:before {
    content: "";
    display: block;
    border-top: 5px solid #92cd75;
    width: 68px;
    width: 70px;
    padding-top: 20px; }
  .advantage_cycles div {
    font-size: 1rem;
    color: #465356; }

.patent_block {
  display: inline-block;
  height: 38px;
  background-image: url(../img/patent_icon.png);
  background-repeat: no-repeat;
  font-size: 0.875rem;
  font-family: "Uni Neue Light";
  color: #465356;
  margin-top: .65rem;
  margin-left: .45rem;
  padding-left: 50px;
  line-height: 1.3;
  line-height: 40px; }
  .patent_block a {
    color: #333;
    text-decoration: underline !important;
    border-bottom: 1px dashed #333;
    text-decoration: none !important;
    font-family: "Uni Neue Regular";
    cursor: pointer; }
    .patent_block a:hover {
      text-decoration: none !important; }

.test-drive_div {
  margin-right: 70px;
  margin-right: 60px;
  margin-bottom: 30px;
  margin-bottom: 15px; }
  .test-drive_div span {
    font-size: 145px;
    line-height: 100px;
    line-height: 115px;
    color: #92cd75;
    display: block;
    width: 100px;
    float: left; }
  .test-drive_div p {
    padding-left: 100px;
    font-size: 1.125rem;
    color: #000; }
    .test-drive_div p + p {
      font-size: 0.875rem;
      color: #465356; }

.test-drive_form {
  width: 300px;
  width: 320px;
  background: #fff;
  padding: 30px;
  padding: 28px 32px;
  margin-right: 70px;
  margin-right: 63px;
  margin-right: 45px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px rgba(199, 199, 199, 0.19), 2px 2px 18px rgba(206, 206, 206, 0.48);
  box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
  border: none; }
  .test-drive_form h4 {
    font-family: "Uni Neue Bold";
    font-weight: 400;
    font-size: 21px;
    font-size: 1.3125rem;
    font-size: 1.2rem; }
  .test-drive_form label {
    color: #6d7c7f;
    color: #b6b6b6;
    font-size: 10px;
    font-size: .625rem;
    margin: 0.75rem 0.15rem;
    margin: .79rem 0.15rem 0.1rem;
    margin-top: 0;
    line-height: 1; }
  .test-drive_form p {
    margin: -5px 5px 0;
    margin: -5px -5px 0;
    margin-right: -15px; }
    .test-drive_form p span {
      color: #465356;
      font-size: 9px;
      display: inline-block;
      text-align: center; }
  .test-drive_form #testDrive2_range1 + p span:first-child,
  .test-drive_form #testDrive_range1 + p span:first-child {
    text-align: center;
    width: 30px; }
  .test-drive_form #testDrive2_range1 + p span:nth-child(2),
  .test-drive_form #testDrive_range1 + p span:nth-child(2) {
    width: 118px; }
  .test-drive_form #testDrive2_range1 + p span:nth-child(3),
  .test-drive_form #testDrive_range1 + p span:nth-child(3) {
    width: 35px; }
  .test-drive_form #testDrive2_range1 + p span:last-child,
  .test-drive_form #testDrive_range1 + p span:last-child {
    text-align: right;
    width: 65px; }
  .test-drive_form #testDrive2_range2 + p span:first-child,
  .test-drive_form #testDrive_range2 + p span:first-child {
    text-align: left;
    width: 35px; }
  .test-drive_form #testDrive2_range2 + p span:nth-child(2),
  .test-drive_form #testDrive_range2 + p span:nth-child(2) {
    width: 69px; }
  .test-drive_form #testDrive2_range2 + p span:nth-child(3),
  .test-drive_form #testDrive_range2 + p span:nth-child(3) {
    width: 42px; }
  .test-drive_form #testDrive2_range2 + p span:nth-child(4),
  .test-drive_form #testDrive_range2 + p span:nth-child(4) {
    width: 74px;
    text-align: center; }
  .test-drive_form #testDrive2_range2 + p span:last-child,
  .test-drive_form #testDrive_range2 + p span:last-child {
    text-align: right;
    width: 38px; }
  .test-drive_form .form-group {
    margin-bottom: 1.5rem; }
    .test-drive_form .form-group:nth-child(1) {
      margin-bottom: 0rem;
      margin-bottom: .5rem;
      margin-bottom: .75rem; }
    .test-drive_form .form-group:nth-child(4) {
      margin-bottom: .5rem;
      margin-bottom: .75rem; }
    .test-drive_form .form-group:last-child {
      margin-bottom: 0; }
  .test-drive_form input[type=text] {
    border-radius: 19px;
    border-radius: 26px;
    height: calc(2.75rem + 2px);
    font-size: 16px;
    color: #6d7c7f;
    padding-left: 45px;
    background-image: url(../img/phone_icon.png);
    background-repeat: no-repeat;
    background-position: 19px; }
  .test-drive_form button[type=submit] {
    width: 100%;
    font-family: "Uni Neue Bold";
    font-weight: 400; }

section#sec2 {
  background: url(../img/section_02.jpg) no-repeat bottom center #fff;
  padding: 70px 0 0;
  background-position: top center;
  background-size: cover; }

.advantage_right {
  background-image: url(../img/adv_button2.png);
  background-repeat: no-repeat;
  background-position: 310px 377px;
  background-size: 315px; }
  .advantage_right h3 {
    font-size: 2rem;
    color: #eef3f3;
    padding-left: 55px;
    padding-top: 30px;
    line-height: 1.4;
    padding-right: 5px; }

.production-video__img {
  width: 100%;
  height: auto; }
  .production-video__img p {
    margin-top: 45px;
    text-align: center;
    font-family: "Uni Neue Bold";
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    margin-left: 10px; }

.production-start {
  margin: 83px -10px 0;
  margin: 80px 0px 0;
  width: 360px;
  height: 135px;
  background: #2f3237;
  color: #fff;
  font-size: 0.875rem;
  font-family: "Uni Neue Light"; }

.prodstart__left {
  float: left;
  padding: 10px 20px;
  font-size: 1rem; }
  .prodstart__left span {
    font-size: 5rem; }

.prodstart__right {
  font-size: .875rem;
  padding: 35px 0 0;
  padding-left: 200px; }

.adv_block {
  margin: 45px 55px;
  margin-right: 0; }
  .adv_block:before {
    content: "";
    display: block;
    border-top: 5px solid #92cd75;
    width: 68px;
    width: 70px;
    padding-top: 20px; }

.adv_item {
  color: #dce3e3;
  font-size: 0.875rem;
  font-family: "Uni Neue Light";
  width: 50%;
  padding: 20px 0;
  padding: 43px 0 0px;
  float: left;
  height: 85px; }
  .adv_item:nth-child(3) {
    padding-left: 65px; }
  .adv_item span {
    color: #fff;
    font-size: 2.625rem;
    display: block;
    width: 100px;
    float: left;
    line-height: 1; }

.final-top-l__btn {
  position: relative;
  width: 232px;
  height: 232px;
  border-radius: 100%;
  background: url(../img/play_icon.png) no-repeat center/cover;
  cursor: pointer;
  margin: 8rem auto 8rem;
  width: 70px;
  height: 70px;
  background: #fff url(../img/media-play-symbol3.svg) no-repeat center/cover;
  background-position: center center;
  background-size: 15px;
  background-image: url(../img/media-play-symbol3.svg);
  background: linear-gradient(-35deg, #ffe25b 0%, #c79216 0%, #fed527 100%);
  background: linear-gradient(-35deg, #ffe25b 0%, #c79216 50%, #fed527 100%);
  background: linear-gradient(-35deg, #ffe25b 0%, #c79216 100%);
  background: linear-gradient(-35deg, #c79216 0%, #ffe25b 100%);
  border: #684e0e 1px solid;
  border-color: #946900;
  border-top-width: 0;
  border-bottom-width: 2px; }
  .final-top-l__btn span {
    display: block;
    background-image: url(../img/media-play-symbol3.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px;
    width: 70px;
    height: 70px; }
  .final-top-l__btn:before, .final-top-l__btn:after {
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid #fbb921;
    border-radius: 50%;
    -webkit-animation: circle 1.7s linear infinite;
    animation: circle 1.7s linear infinite; }
  .final-top-l__btn:before {
    -webkit-animation: circle 2s linear infinite;
    animation: circle 2s linear infinite; }
  .final-top-l__btn:after {
    -webkit-animation: circle 2s 1s linear infinite;
    animation: circle 2s 1s linear infinite; }

section#sec3 {
  background: #24262b url(../img/section_03.png) no-repeat;
  padding: 80px 0 70px;
  padding: 80px 0 185px;
  background-position: top center;
  background-position: bottom center;
  background-size: cover; }
  section#sec3 h2 {
    font-size: 2.75rem;
    line-height: 1.3; }
  section#sec3 h2 + p {
    font-size: 1.875rem;
    color: #465356;
    font-family: "Uni Neue Light"; }

.send-catalog_form form {
  width: 310px;
  padding: 27px;
  width: 320px;
  padding: 28px 32px;
  margin-top: 60px;
  margin-bottom: 90px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e0e6e6;
  box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
  border: none; }

.send-catalog_form h4 {
  font-size: 1.125rem;
  line-height: 1.45;
  margin-bottom: 30px; }

.send-catalog_form input[type=text] {
  border-radius: 26px;
  height: calc(2.75rem + 2px);
  font-size: 16px;
  color: #6d7c7f;
  padding-left: 45px;
  background-image: url(../img/phone_icon.png);
  background-repeat: no-repeat;
  background-position: 19px; }

.send-catalog_form input[name=test-drive_mail] {
  background-image: url(../img/mail_icon.png);
  background-repeat: no-repeat;
  background-position: 19px; }

.send-catalog_form button[type=submit] {
  width: 100%;
  padding: .75rem 3rem .75rem;
  box-shadow: 0 2px 0 0 #c3880a, 1px 1px 0 #ffaf06 !important;
  border-color: #fec847;
  padding-left: 2rem;
  padding-right: 4rem;
  position: relative; }
  .send-catalog_form button[type=submit]:before {
    content: "";
    display: block;
    background: url(../img/download_icon.png) no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    right: 56px; }

.send-catalog_form .form-group:last-child {
  margin-bottom: 0; }

.send-catalog_form label {
  color: #6d7c7f;
  font-size: .625rem;
  margin: .79rem .15rem .1rem;
  margin-top: 0;
  line-height: 1;
  text-align: left; }

.description {
  margin-top: 82px; }
  .description .desc {
    font-size: 0.875rem;
    font-family: "Uni Neue Bold";
    color: #fff;
    display: block;
    height: 83px;
    width: 33.33%;
    float: left; }
    .description .desc span {
      display: block;
      padding: 38px;
      padding: 0;
      padding-top: 30px;
      padding-bottom: 30px;
      width: 81px;
      position: relative;
      z-index: 1;
      text-align: center; }
      .description .desc span:after {
        position: absolute;
        top: 26px;
        left: 0;
        right: 0;
        margin: auto;
        width: 30px;
        height: 30px;
        content: '';
        border-radius: 50%;
        background: linear-gradient(3deg, #7cc340 0, #93dc54 100%);
        box-shadow: 0 0 25px 3px #6db94d73;
        z-index: -1; }
  .description .desc-text {
    font-size: 0.75rem;
    color: #000;
    text-transform: uppercase;
    padding: 0 25px;
    padding-right: 0; }

.desc1 {
  margin-top: 60px; }

.desc2 {
  margin-top: 30px; }

.desc3 {
  margin-top: 0px; }

section#sec4 {
  background: #24262b;
  padding: 70px 0 70px;
  padding-top: 0px;
  background: #24262b url(../img/section_04.png) no-repeat;
  background-position: top center; }
  section#sec4 h2 {
    text-align: center;
    color: #eef3f3;
    line-height: 1.35; }
  section#sec4 .quiz_block {
    box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.19), 2px 2px 18px rgba(239, 239, 239, 0.48);
    box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
    border: none;
    height: 600px;
    height: auto;
    min-height: 600px;
    background: #fff;
    border-radius: 10px;
    padding: 25px;
    padding: 30px;
    padding: 30px 13px;
    padding: 13px;
    margin-top: 95px;
    margin-bottom: 95px; }
    section#sec4 .quiz_block .slick-dots li button:before {
      content: "";
      display: block;
      background: #e7e6f1;
      opacity: 1;
      border-radius: 50%;
      width: 14px;
      height: 14px; }
    section#sec4 .quiz_block .slick-dots li.slick-active button:before {
      background: #77d575;
      opacity: 1; }
    section#sec4 .quiz_block .slick-next, section#sec4 .quiz_block .slick-prev {
      height: 40px;
      width: 40px;
      background: #77d575;
      border-radius: 50%; }
    section#sec4 .quiz_block .slick-next:before, section#sec4 .quiz_block .slick-prev:before {
      font-size: 40px;
      line-height: 1.15;
      opacity: 1;
      display: block;
      content: "";
      width: 40px;
      height: 40px;
      background-image: url(../img/next-arrow_white.svg);
      background-repeat: no-repeat;
      background-position: 50%; }
    section#sec4 .quiz_block .slick-next:before, section#sec4 .quiz_block .slick-prev:before {
      color: white !important;
      opacity: 1; }
    section#sec4 .quiz_block .slick-prev:before {
      background-image: url(../img/prev-arrow_white.svg); }
    section#sec4 .quiz_block .quiz_left {
      width: 855px;
      width: 842px;
      width: 845px;
      float: left; }
      section#sec4 .quiz_block .quiz_left form {
        padding: 25px 0px;
        max-width: 666px;
        margin: auto;
        padding: 0;
        margin-top: -5px; }
        section#sec4 .quiz_block .quiz_left form h3 {
          font-size: 21px;
          color: #000000;
          line-height: 1.6;
          margin-bottom: 36px; }
        section#sec4 .quiz_block .quiz_left form input[type=text] {
          border-radius: 26px;
          height: calc(2.75rem + 2px);
          font-size: 16px;
          color: #6d7c7f;
          padding-left: 45px; }
        section#sec4 .quiz_block .quiz_left form input[name=quiz_callback_phone] {
          background-image: url(../img/phone_icon.png);
          background-repeat: no-repeat;
          background-position: 19px; }
        section#sec4 .quiz_block .quiz_left form input[name=quiz_callback_name] {
          background-image: url(../img/user_icon.png);
          background-repeat: no-repeat;
          background-position: 19px; }
        section#sec4 .quiz_block .quiz_left form button[type=submit] {
          box-shadow: 0 2px 0 0 #c3880a, 1px 1px 0 #ffaf06 !important;
          border-color: #fec847;
          position: relative;
          margin-top: 30px;
          width: auto;
          padding: .9rem 4rem; }
        section#sec4 .quiz_block .quiz_left form .custom-control-label {
          text-align: left; }
        section#sec4 .quiz_block .quiz_left form .custom-checkbox {
          width: 238px;
          margin: 25px auto; }
    section#sec4 .quiz_block .progress {
      height: .75rem;
      width: 525px;
      margin: auto;
      border-radius: 10px;
      background: linear-gradient(-45deg, rgba(204, 204, 205, 0.71) 25%, #ededed 25%, #ededed 50%, rgba(204, 204, 205, 0.71) 50%, rgba(204, 204, 205, 0.71) 75%, #ededed 75%, #ededed);
      background-size: 6px 6px;
      box-shadow: inset 4px 4px 4px -3px rgba(0, 0, 0, 0.5); }
      section#sec4 .quiz_block .progress span {
        background: #78b731;
        background: linear-gradient(90deg, #94dd55 0, #6fb433 100%);
        background: linear-gradient(90deg, rgba(148, 221, 85, 0.75) 0, rgba(111, 180, 51, 0.75) 100%);
        box-shadow: inset 4px 4px 4px -3px rgba(0, 0, 0, 0.5); }
    section#sec4 .quiz_block .progress__label {
      font-family: "Uni Neue Bold";
      color: #24262b;
      font-size: 0.875rem;
      padding-bottom: 7px;
      padding-top: 17px; }
    section#sec4 .quiz_block .question__title {
      font-family: "Uni Neue Light";
      color: #24262b;
      font-size: 1.875rem;
      padding: 75px 0 30px;
      padding: 60px 0 30px; }
    section#sec4 .quiz_block .quiz__question {
      padding-left: 20px;
      padding-right: 20px; }
      section#sec4 .quiz_block .quiz__question #test__kviz04 {
        margin: 0 50px; }
        section#sec4 .quiz_block .quiz__question #test__kviz04 .col-12.col-sm-6.col-lg-3:focus {
          outline: none; }
      section#sec4 .quiz_block .quiz__question .slick-next {
        right: -45px; }
      section#sec4 .quiz_block .quiz__question .slick-prev {
        left: -45px; }
      section#sec4 .quiz_block .quiz__question .card {
        cursor: pointer;
        box-shadow: 1px 1px 2px rgba(245, 245, 245, 0.19), 1px 1px 15px rgba(202, 202, 202, 0.48);
        border: none; }
        section#sec4 .quiz_block .quiz__question .card .card-text {
          color: #24262b;
          font-size: 12px;
          font-family: "Uni Neue Bold";
          text-transform: uppercase;
          height: 36px; }
        section#sec4 .quiz_block .quiz__question .card img {
          padding: 8px;
          border-radius: 20px;
          border-radius: 15px; }
        section#sec4 .quiz_block .quiz__question .card:hover {
          background: #dfe6e7;
          box-shadow: 1px 1px 3px rgba(165, 165, 165, 0.19), 2px 2px 18px rgba(189, 189, 189, 0.48); }
        section#sec4 .quiz_block .quiz__question .card.card__mini {
          margin-bottom: 30px; }
          section#sec4 .quiz_block .quiz__question .card.card__mini img {
            border-radius: 15px;
            width: 100%; }
          section#sec4 .quiz_block .quiz__question .card.card__mini .card-body {
            padding: 10px 1.25rem;
            margin-top: -8px; }
            section#sec4 .quiz_block .quiz__question .card.card__mini .card-body .card-text {
              height: 18px; }
        section#sec4 .quiz_block .quiz__question .card.card__mini2 {
          margin-bottom: 30px; }
          section#sec4 .quiz_block .quiz__question .card.card__mini2 img {
            width: 50%;
            margin: 0;
            float: left;
            padding: 25px; }
          section#sec4 .quiz_block .quiz__question .card.card__mini2 .card-text2 {
            width: 50%;
            float: right;
            padding: 25px;
            margin: 0;
            line-height: 110px;
            padding: 0;
            font-size: 30px;
            font-size: 26px;
            font-family: "Uni Neue Light"; }
        section#sec4 .quiz_block .quiz__question .card.card__mini3 {
          margin-bottom: 22px;
          width: 330px; }
          section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 {
            height: 60px; }
            section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_1 {
              text-align: left;
              float: left;
              padding: 18px 20px;
              padding-right: 0;
              width: 175px; }
            section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 {
              float: right;
              height: 60px; }
              section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 sup {
                font-size: 10px;
                text-transform: uppercase;
                text-align: left;
                /* height: 60px; */
                top: 20px; }
              section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span {
                content: none;
                position: relative;
                width: 0;
                height: 0;
                color: black;
                display: block;
                font-size: 48px;
                font-family: "Uni Neue Light";
                height: 60px;
                line-height: 60px;
                /* line-height: 1; */
                width: 135px;
                background: 0 0;
                top: 0;
                padding: 0 30px 0 45px;
                margin-right: 10px;
                text-align: right;
                padding: 0 35px 0 45px;
                display: inline-block;
                width: 100%; }
                section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span:before {
                  display: block;
                  content: 'скидка';
                  position: absolute;
                  font-size: 10px;
                  text-transform: uppercase;
                  left: 3px;
                  top: -9px;
                  font-family: "Uni Neue Regular"; }
                section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span:after {
                  display: block;
                  position: absolute;
                  content: '%';
                  font-size: 24px;
                  width: 25px;
                  height: 60px;
                  right: 10px;
                  top: 7px;
                  font-family: "Uni Neue Regular";
                  text-align: left; }
          section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-text2 {
            width: 50%;
            float: right;
            padding: 25px;
            margin: 0;
            line-height: 110px;
            padding: 0;
            font-size: 30px;
            font-size: 26px;
            font-family: "Uni Neue Light"; }
      section#sec4 .quiz_block .quiz__question .card-body {
        padding: 1.0rem 1.25rem; }
    section#sec4 .quiz_block .quiz_right {
      margin-left: 24px;
      padding-left: 845px;
      padding-left: 830px;
      padding-left: 835px; }
      section#sec4 .quiz_block .quiz_right .quiz_right__inner {
        padding-left: 15px;
        box-shadow: -10px 0 17px rgba(204, 204, 204, 0.14);
        box-shadow: -10px 0 40px rgba(204, 204, 204, 0.23); }
      section#sec4 .quiz_block .quiz_right p {
        color: #465356;
        font-size: 0.75rem; }
      section#sec4 .quiz_block .quiz_right .right_background {
        height: 275px;
        background: url(../img/quiz_rightbackground.jpg) no-repeat;
        background-size: cover;
        background-position: bottom center;
        border-radius: 0 10px 0 0; }
        section#sec4 .quiz_block .quiz_right .right_background h4 {
          font-size: 1.5rem;
          color: #000000;
          padding: 22px 0 0; }
      section#sec4 .quiz_block .quiz_right form {
        padding: 25px 0px;
        width: 260px;
        margin: auto; }
        section#sec4 .quiz_block .quiz_right form h5 {
          font-size: 1.3125rem;
          color: #000000; }
        section#sec4 .quiz_block .quiz_right form input[name=quiz_right_phone] {
          border-radius: 26px;
          height: calc(2.75rem + 2px);
          font-size: 16px;
          color: #6d7c7f;
          padding-left: 45px;
          background-image: url(../img/phone_icon.png);
          background-repeat: no-repeat;
          background-position: 19px; }
        section#sec4 .quiz_block .quiz_right form button[type=submit] {
          width: 100%;
          padding: .75rem 3rem .75rem;
          box-shadow: 0 2px 0 0 #c3880a, 1px 1px 0 #ffaf06 !important;
          border-color: #fec847;
          padding-left: 2rem;
          padding-right: 4rem;
          position: relative; }
          section#sec4 .quiz_block .quiz_right form button[type=submit]:before {
            content: "";
            display: block;
            background: url(../img/call_icon.png) no-repeat;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 16px;
            right: 56px; }
        section#sec4 .quiz_block .quiz_right form .custom-control-label {
          text-align: left; }
  section#sec4 .test__kviz06--item .card__mini3 {
    float: right; }
  section#sec4 .test__kviz06--item:nth-child(2n) .card__mini3 {
    float: left; }

section#sec5 {
  background: #faf9fe;
  background-position: top center;
  padding: 95px 0 40px;
  padding: 95px 0 33px;
  background: #faf9fe url(../img/section_05.jpg) no-repeat; }
  section#sec5 h2 {
    text-align: center;
    line-height: 1.35; }
  section#sec5 h2 + p {
    text-align: center;
    font-size: 1.3125rem;
    color: #465356;
    margin: 35px 0 60px;
    font-family: "Uni Neue Light"; }
    section#sec5 h2 + p:before {
      content: "";
      display: block;
      border-top: 5px solid #92cd75;
      width: 68px;
      width: 70px;
      padding-top: 20px;
      margin: auto; }

.product_item .product_item-inner {
  background: #fff;
  height: 287px;
  border-radius: 10px;
  padding: 25px;
  margin: 0 8px;
  margin-bottom: 30px;
  border: 1px solid #ccc; }

.card-columns .card {
  margin-bottom: 1.3rem;
  border-radius: 15px;
  border-radius: 10px; }

.card {
  box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.19), 2px 2px 18px rgba(239, 239, 239, 0.48);
  text-align: center;
  position: relative;
  margin-bottom: 22px;
  border-radius: 8px; }
  .card span {
    content: '';
    display: block;
    width: 46px;
    height: 46px;
    top: -10px;
    right: -5px;
    position: absolute;
    background: #88d05f;
    border-radius: 50%;
    color: #fff;
    line-height: 46px;
    text-align: center;
    font-family: "Uni Neue Bold";
    font-size: 0.875rem; }
  .card .card-img-top {
    padding: 1.25rem;
    padding: 2rem 3rem 0; }
  .card .card-text {
    font-size: 14px;
    font-family: "Uni Neue Regular";
    color: #000; }
    .card .card-text strike {
      float: left;
      padding: 4px;
      padding-right: 0px;
      margin-right: -25px; }
    .card .card-text strong {
      font-size: 24px;
      font-family: "Uni Neue Bold"; }
  .card a.btn,
  .card button {
    padding: .5rem 2.0rem .5rem; }

section#sec6 {
  background-position: top center;
  padding: 95px 0 35px;
  background: #ccc url(../img/section_06.jpg) no-repeat;
  background-size: cover;
  color: #fff; }
  section#sec6 .laserEngraving_block h2 {
    font-size: 2rem;
    line-height: 1.3; }
    section#sec6 .laserEngraving_block h2 span {
      font-family: "Uni Neue Light";
      font-size: 1.6875rem; }
  section#sec6 .economy_block {
    margin: 65px 0 30px; }
    section#sec6 .economy_block span {
      font-family: "Uni Neue Bold";
      font-size: 1.09375rem; }
    section#sec6 .economy_block .economy_value {
      font-family: "Uni Neue Light";
      font-size: 0.9375rem;
      margin: -10px 0 5px; }
      section#sec6 .economy_block .economy_value span {
        font-family: "Uni Neue Light";
        font-size: 4rem; }
    section#sec6 .economy_block .economy_text {
      font-family: "Uni Neue Light";
      font-size: 0.9375rem;
      float: left;
      padding-right: 130px; }
      section#sec6 .economy_block .economy_text:before {
        content: "";
        display: block;
        border-top: 5px solid #92cd75;
        width: 70px;
        padding-top: 25px; }
    section#sec6 .economy_block .free_engraving {
      font-family: "Uni Neue Light";
      font-size: 0.75rem;
      padding-top: 1rem;
      line-height: 1.8;
      color: #dce3e3;
      float: left;
      background-image: url(../img/array_icon.png);
      background-repeat: no-repeat;
      background-position: bottom left;
      padding-bottom: 90px;
      margin-bottom: -90px;
      background-position: 13px 93px; }
  section#sec6 .bookingForm form {
    width: 295px;
    padding: 28px 32px;
    padding-left: 0; }
  section#sec6 .bookingForm input[name=laser-engraving_phone] {
    box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.19), 2px 2px 18px rgba(239, 239, 239, 0.48);
    background-color: #4e4543;
    border: 1px solid #4e4543;
    border-radius: 26px;
    height: calc(2.75rem + 2px);
    font-size: 16px;
    color: #6d7c7f;
    padding-left: 45px;
    background-image: url(../img/phone_icon.png);
    background-repeat: no-repeat;
    background-position: 19px; }
  section#sec6 .bookingForm button[type=submit] {
    width: 100%; }
  section#sec6 .bookingForm .form-group:nth-last-child(2) {
    margin-bottom: 1.4rem; }

section#sec7 {
  background-position: top center;
  padding: 95px 0 35px;
  padding: 63px 0 35px;
  background: #ccc url(../img/section_07.jpg) no-repeat;
  background-size: cover; }
  section#sec7 h2 {
    font-size: 2.75rem;
    line-height: 1.3; }
  section#sec7 .comments_block {
    margin: 40px 0;
    height: 520px;
    height: 370px;
    width: 815px;
    margin: 40px auto;
    float: left;
    margin: 56px auto;
    margin-left: 24px; }
  section#sec7 .customers_count {
    width: 300px;
    height: 370px;
    float: right;
    margin: 56px 0;
    padding: 53px 61px;
    padding-right: 0;
    font-family: "Uni Neue Light"; }
    section#sec7 .customers_count span {
      font-size: 5.25rem; }
    section#sec7 .customers_count p {
      color: #465356;
      font-size: 1.125rem;
      margin: 38px 8px;
      margin: 5px 8px; }
      section#sec7 .customers_count p:before {
        content: "";
        display: block;
        border-top: 5px solid #92cd75;
        width: 68px;
        width: 70px;
        padding-top: 28px; }
  section#sec7 .comments_logos {
    margin: 0;
    margin: 110px auto;
    margin: 110px auto 40px;
    height: 63px;
    background: url(../img/comments_logos.png) no-repeat;
    background-size: contain;
    background-position: 0px 2px; }
  section#sec7 .slick-prev:before,
  section#sec7 .slick-next:before {
    font-size: 40px;
    content: ''; }
  section#sec7 .slick-prev,
  section#sec7 .slick-next {
    width: 40px;
    height: 40px;
    width: 50px;
    height: 50px;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
    box-shadow: 2px 2px 18px rgba(142, 142, 142, 0.26), 2px 2px 0px rgba(226, 226, 226, 0.1);
    border: none; }
  section#sec7 .slick-prev {
    left: -18px;
    left: -23px;
    background-image: url(../img/prev-arrow.svg); }
  section#sec7 .slick-next {
    right: -20px;
    right: -25px;
    background-image: url(../img/next-arrow.svg); }
  section#sec7 .slick-dots {
    bottom: -45px;
    bottom: -90px;
    bottom: -112px;
    bottom: -70px; }
    section#sec7 .slick-dots li {
      width: 50px;
      height: 50px; }
      section#sec7 .slick-dots li button {
        width: 50px;
        height: 50px; }
        section#sec7 .slick-dots li button:before {
          font-size: 25px;
          line-height: 50px;
          width: 50px;
          height: 50px;
          color: #e7e7f2; }
      section#sec7 .slick-dots li.slick-active button:before {
        color: #92cd75;
        color: #88d05f; }
  section#sec7 .slide-item {
    min-width: 815px;
    max-width: 815px;
    height: 370px;
    border-radius: 10px;
    margin-bottom: 0;
    margin-bottom: 35px;
    margin-bottom: 45px; }
    section#sec7 .slide-item:focus {
      outline: none; }
    section#sec7 .slide-item:before, section#sec7 .slide-item:after {
      content: "";
      position: absolute;
      height: 30px;
      width: 200px;
      top: auto;
      bottom: -15px;
      left: 0;
      right: 0;
      border-radius: 15px;
      border-radius: 0 0 11px 11px;
      background-color: #fff;
      z-index: -1;
      margin: auto;
      box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
      bottom: -20px;
      width: 190px;
      width: 75%;
      bottom: -35px;
      width: 83%; }
    section#sec7 .slide-item:after {
      bottom: -10px;
      width: 88%;
      bottom: -17.5px;
      width: 87%; }
  section#sec7 .card_img {
    position: absolute;
    width: 230px; }
  section#sec7 .card_img_blk {
    position: absolute;
    width: 230px;
    height: 369px;
    background: url(../img/avatar.png) no-repeat;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    background-size: cover; }
    section#sec7 .card_img_blk img {
      width: 493px;
      margin-left: -97px;
      height: auto; }
  section#sec7 .card_img_blk--2 {
    background: url(../img/avatar2.png) no-repeat;
    background-size: cover; }
  section#sec7 .card-img-overlay {
    width: 230px;
    top: auto; }
    section#sec7 .card-img-overlay .card-title {
      color: #fff;
      margin-bottom: 0rem; }
    section#sec7 .card-img-overlay p {
      color: #bfc5c7; }
  section#sec7 .card-body {
    padding: 2rem 2.75rem 2rem;
    padding: 2rem 4.0rem 2rem;
    padding-left: 230px; }
    section#sec7 .card-body h5 {
      font-size: 1.5rem;
      padding: 0 0 27px;
      padding-left: 50px;
      text-align: left; }
    section#sec7 .card-body img {
      margin-left: 50px;
      margin-right: 37px;
      border: 1px solid #ebebee;
      border-radius: 10px; }
    section#sec7 .card-body p {
      color: #465356;
      font-size: 1rem;
      font-family: "Uni Neue Light";
      display: block;
      text-align: left;
      margin-bottom: 1.5rem;
      padding-left: 50px; }
    section#sec7 .card-body button[type=button] {
      padding: 1rem 3rem 1rem;
      border-color: #fec847;
      padding-left: 2rem;
      padding-right: 4rem;
      position: relative; }
      section#sec7 .card-body button[type=button] .speaker_icon {
        content: "";
        display: block;
        background: url(../img/speaker_icon.png) no-repeat;
        width: 20px;
        height: 15px;
        position: absolute;
        top: 22px;
        right: 30px; }

section#sec8 {
  background-position: top center;
  padding: 80px 0 35px;
  padding: 78px 0 35px;
  background: #ccc url(../img/section_08mod0.jpg) no-repeat;
  color: #fff; }
  section#sec8 h2 {
    font-size: 2rem;
    line-height: 1.4;
    margin-bottom: 20px;
    margin-bottom: 17px; }
  section#sec8 p {
    font-family: "Uni Neue Light";
    font-size: 1.3125rem; }

.quiz_questions {
  margin: 47px 0;
  margin: 47px 0;
  background: #fff;
  border-radius: 10px;
  padding: 30px 15px 25px;
  padding: 20px 18px 25px;
  padding: 20px 25px 25px;
  box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
  border: none; }
  .quiz_questions form .form-group {
    margin-bottom: 9px;
    position: relative; }
  .quiz_questions form label {
    color: #878787;
    font-size: 9px;
    text-transform: uppercase;
    padding: 0;
    padding-left: 22px;
    margin-bottom: 6px; }
  .quiz_questions form input[type=text] {
    position: relative;
    background-image: url(../img/location_icon.png);
    background-repeat: no-repeat;
    border-radius: 26px;
    height: calc(2.75rem + 2px);
    font-size: 16px;
    color: #6d7c7f;
    padding-left: 45px;
    background-position: 20px;
    background-size: 12px; }
    .quiz_questions form input[type=text] + span {
      position: absolute;
      top: 39px;
      right: 12px;
      content: '';
      transform: rotate(90deg);
      background: url(../img/right-arrow.svg);
      background-color: #ffffff;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      background-size: 8px;
      background-repeat: no-repeat;
      background-position: 50%;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16); }
  .quiz_questions form input[type=number] {
    box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.28), 2px 2px 18px rgba(239, 239, 239, 0.66);
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.18);
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.18), 2px 2px 18px rgba(239, 239, 239, 0.66);
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.18), 2px 2px 9px rgba(0, 0, 0, 0.08);
    border-radius: 26px;
    height: calc(2.75rem + 2px);
    font-size: 16px;
    color: #6d7c7f;
    padding-left: 45px;
    padding-right: 45px;
    background-size: 12px;
    text-align: center;
    -moz-appearance: textfield; }
    .quiz_questions form input[type=number]::-webkit-inner-spin-button, .quiz_questions form input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; }
  .quiz_questions form .quantity-arrow-minus,
  .quiz_questions form .quantity-arrow-plus {
    cursor: pointer;
    color: #333;
    font-family: "Uni Neue Light";
    font-size: 24px;
    line-height: 45px;
    padding: 0;
    width: 45px;
    height: 44px;
    box-sizing: border-box;
    outline: 0;
    box-shadow: none;
    border: none;
    background: #fff;
    position: absolute;
    font-size: 40px;
    line-height: 1;
    line-height: 43px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    color: #7c7e7c; }
  .quiz_questions form .quantity-arrow-minus {
    top: 29px;
    left: 7px;
    border-radius: 50% 0 0 50%;
    border-right: 1px solid #ccc;
    box-shadow: 3px 0px 2px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 3px 0px 4px rgba(204, 204, 204, 0.3);
    box-shadow: 10px 0 17px rgba(204, 204, 204, 0.21);
    height: 43px;
    top: 30px; }
  .quiz_questions form .quantity-arrow-plus {
    top: 29px;
    right: 7px;
    left: auto;
    border-radius: 0 50% 50% 0;
    border-left: 1px solid #ccc;
    box-shadow: -3px 0 2px 0 rgba(0, 0, 0, 0.07);
    box-shadow: -3px 0px 4px rgba(204, 204, 204, 0.3);
    box-shadow: -10px 0 17px rgba(204, 204, 204, 0.21);
    height: 43px;
    top: 30px; }
  .quiz_questions .result {
    margin: 20px 0 0;
    margin: 17px 0 0; }
    .quiz_questions .result .table-bordered {
      border: 1px solid #dee2e6;
      border: none; }
    .quiz_questions .result .table {
      margin: 0; }
      .quiz_questions .result .table thead th {
        color: #333;
        font-size: 14px;
        font-weight: normal;
        font-family: "Uni Neue Bold";
        border-bottom-width: 1px; }
        .quiz_questions .result .table thead th:focus {
          outline: none; }
      .quiz_questions .result .table td, .quiz_questions .result .table th {
        border: 1px solid #dee2e6;
        border: none;
        border-right: 1px solid #dee2e6;
        padding: .75rem 0;
        padding: 2px 0 0; }
        .quiz_questions .result .table td:last-child, .quiz_questions .result .table th:last-child {
          border-right: none; }
      .quiz_questions .result .table th {
        padding: 4px 0 13px;
        padding: 5px 0 5px;
        border-bottom: 1px solid #ccc;
        padding-left: 25px;
        padding-left: 20px; }
        .quiz_questions .result .table th:first-child {
          padding-left: 0;
          padding-left: 2px; }
      .quiz_questions .result .table td {
        padding: 2px 0 0;
        padding: 5px 0 0;
        padding: 4px 0 2px;
        font-size: 14px;
        font-weight: normal;
        font-family: "Uni Neue Light";
        font-family: "Uni Neue Regular";
        padding-left: 25px;
        color: #333; }
        .quiz_questions .result .table td:first-child {
          padding-left: 0;
          padding-left: 2px; }
        .quiz_questions .result .table td a {
          font-size: 14px;
          font-weight: normal;
          color: #333;
          text-decoration: none; }
          .quiz_questions .result .table td a:hover {
            text-decoration: none; }

.delivery_advantages {
  width: 100%;
  height: 510px;
  margin: 47px 0 20px;
  margin: 39px 0 20px; }
  .delivery_advantages .delivery_advantages-item {
    font-family: "Uni Neue Light";
    padding-left: 122px;
    float: left;
    width: 50%;
    height: 96px;
    margin: 0 0 36px;
    position: relative; }
    .delivery_advantages .delivery_advantages-item:before {
      position: absolute;
      top: 41px;
      left: 24%;
      width: 22px;
      height: 22px;
      content: '';
      -webkit-border-radius: 50%;
      background: url(../img/checked7.svg) no-repeat center center, -webkit-linear-gradient(136deg, #85ce49 0, #72b937 100%);
      background-size: 15px,100%;
      box-shadow: 0 0 25px 2px #7cc440; }
    .delivery_advantages .delivery_advantages-item span {
      font-size: 64px;
      color: #fff;
      display: block;
      float: left;
      padding-right: 28px; }
    .delivery_advantages .delivery_advantages-item p {
      font-size: 18px;
      color: #dce3e3;
      padding: 22px 0 0;
      float: left;
      margin: 0;
      line-height: 1.3; }

section#sec9 {
  height: 720px;
  position: relative;
  background-position: top center;
  margin: 0;
  padding: 0;
  background: #ccc url(../img/section_09.jpg) no-repeat; }
  section#sec9 .address_block {
    background: #faf9fe;
    width: 430px;
    height: 497px;
    padding: 55px 50px;
    position: absolute;
    z-index: 1;
    top: 125px;
    box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66); }
    section#sec9 .address_block h2 {
      font-family: "Uni Neue Light";
      font-size: 2rem;
      line-height: 1.35; }
    section#sec9 .address_block address {
      font-size: 1.3125rem;
      padding: 18px;
      padding-left: 0; }
      section#sec9 .address_block address .address_item {
        margin-bottom: 1.125rem;
        padding-left: 43px;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: left center; }
        section#sec9 .address_block address .address_item.address_item--phone {
          background-image: url(../img/whatsapp_icon.png);
          background-image: url(../img/whatsapp2_icon.png);
          background-position: left 41px;
          background-position: left 38px;
          background-size: 22px; }
        section#sec9 .address_block address .address_item.address_item--location {
          background-image: url(../img/location_icon.png);
          background-position: left 39px;
          background-position: left 35px; }
        section#sec9 .address_block address .address_item.address_item--email {
          background-image: url(../img/mail_icon.png);
          background-position: left 37px;
          background-position: left 38px; }
      section#sec9 .address_block address span {
        font-family: "Uni Neue Bold";
        font-size: 1.6875rem; }
      section#sec9 .address_block address strong {
        font-weight: normal;
        font-size: 0.75rem;
        color: #465356; }

.btn-yellow.focus,
.btn-yellow:focus {
  outline: 0;
  box-shadow: none; }

.slick-prev:before,
.slick-next:before {
  color: #464547 !important; }

footer {
  position: relative;
  overflow: hidden; }
  footer .footer_menu {
    padding: 85px 0;
    padding: 73px 0;
    padding: 70px 0; }
  footer .navbar-dark .navbar-nav .nav-link {
    font-size: .9rem;
    font-family: "Uni Neue Light";
    text-decoration: underline;
    color: #fff;
    padding-right: 6rem;
    padding-left: 6rem; }

.socials-list {
  padding: 0;
  padding-right: 70px;
  font-size: 0; }

.socials {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-left: 5px;
  margin-left: 15px;
  background-image: url(../img/social_icons.png);
  background-repeat: no-repeat;
  background-size: cover; }

.socials.social_vk {
  background-position: 0px 0px; }

.socials.social_fb {
  background-position: -31px 0px; }

.socials.social_in {
  background-position: -62px 0px; }

#orderModal .modal-content {
  padding: 23px 50px 20px;
  padding: 23px 50px 0;
  padding: 0px 0 0;
  padding-left: 46px;
  padding-left: 44px;
  margin: auto;
  border: none;
  background: url(../img/orderModal.png) no-repeat;
  background-size: cover;
  border-radius: 8px;
  max-width: 497px; }
  #orderModal .modal-content h3 {
    font-size: 2.0rem;
    text-align: center;
    margin: 70px 0 4px; }
  #orderModal .modal-content .orderDiv {
    width: 300px;
    padding: 22px 0px 48px;
    padding: 22px 0 29px;
    margin: auto; }
    #orderModal .modal-content .orderDiv p {
      text-align: center;
      margin-bottom: 2rem; }
    #orderModal .modal-content .orderDiv input[name=order_phone] {
      border-radius: 26px;
      height: calc(2.75rem + 2px);
      font-size: 16px;
      color: #6d7c7f;
      padding-left: 45px;
      background-image: url(../img/phone_icon.png);
      background-repeat: no-repeat;
      background-position: 19px; }
    #orderModal .modal-content .orderDiv input[name=order_name] {
      border-radius: 26px;
      height: calc(2.75rem + 2px);
      font-size: 16px;
      color: #6d7c7f;
      padding-left: 45px;
      background-image: url(../img/user_icon.png);
      background-repeat: no-repeat;
      background-position: 19px; }
    #orderModal .modal-content .orderDiv button[type=submit] {
      width: 100%; }
    #orderModal .modal-content .orderDiv .form-group {
      margin: 0 1rem 1.3rem; }

#youtubeModal .modal-body {
  padding: 0; }

#patentModal .modal-content {
  width: 396px;
  margin: auto; }
  #patentModal .modal-content .modal-body {
    padding: 0; }

#testDriveModal2 .modal-dialog {
  width: 322px;
  margin: auto; }

#testDriveModal2 .modal-body {
  padding: 0; }

#testDriveModal2 .test-drive_form {
  border: 0;
  border-radius: 10px; }

#goodSendModal .modal-content {
  background-color: #fff;
  width: 350px;
  padding: 20px; }
  #goodSendModal .modal-content h4 {
    text-align: center;
    margin-bottom: 1.2rem; }
    #goodSendModal .modal-content h4 + p {
      text-align: center; }

#badSendModal .modal-content {
  background-color: #fff;
  width: 350px;
  padding: 20px; }
  #badSendModal .modal-content h4 {
    text-align: center;
    margin-bottom: 1.2rem;
    color: red;
    font-size: 32px; }
    #badSendModal .modal-content h4 + p {
      text-align: center;
      font-size: 19px; }

#dontLeaveModal .modal-content {
  padding: 23px 50px 20px;
  padding-left: 110px;
  margin: auto;
  border-radius: 10px;
  border: none;
  background: url(../img/dontLeaveModal.png) no-repeat; }

#dontLeaveModal .modal-header {
  justify-content: center;
  border-bottom: none; }
  #dontLeaveModal .modal-header h5 {
    font-size: 1rem;
    color: #465356; }

#dontLeaveModal .modal-body h3 {
  font-size: 2rem;
  color: #333;
  margin: 2px 0 20px;
  line-height: 1.4; }

#dontLeaveModal .promoCodeDiv {
  width: 260px;
  padding: 22px 0 0; }
  #dontLeaveModal .promoCodeDiv input[name=promo-code_phone] {
    border-radius: 26px;
    height: calc(2.75rem + 2px);
    font-size: 16px;
    color: #6d7c7f;
    padding-left: 45px;
    background-image: url(../img/phone_icon.png);
    background-repeat: no-repeat;
    background-position: 19px; }
  #dontLeaveModal .promoCodeDiv button[type=submit] {
    width: 100%; }

#dontLeaveModal .modal-footer {
  justify-content: flex-start;
  border: none;
  padding-bottom: 0; }
  #dontLeaveModal .modal-footer p {
    color: #b6b6b6;
    font-family: "Uni Neue Regular";
    font-size: 0.5625rem;
    margin: 15px 0 0;
    margin: 10px 0;
    border: none;
    text-align: left; }

.menu,
.menu_open,
.menu2,
.menu2_open {
  font-size: 19px;
  font-weight: normal; }

.menu,
.menu2 {
  z-index: 2000;
  bottom: 0;
  width: 657px;
  background-color: #23262c;
  padding: 50px 246px 20px 60px;
  overflow: auto;
  transition: all .3s ease-in-out;
  transform: translate(100%);
  position: fixed;
  right: 0;
  top: 0;
  color: #fbfbfc; }
  .menu p,
  .menu2 p {
    line-height: 1.85;
    font-size: 1.6875rem;
    font-family: "Uni Neue Light";
    padding: 0px 11px; }
  .menu.active,
  .menu2.active {
    transform: translate(0%); }
  .menu a,
  .menu2 a {
    color: #fbfbfc; }
  .menu .menu_close,
  .menu .menu2_close,
  .menu2 .menu_close,
  .menu2 .menu2_close {
    display: table;
    margin-left: auto;
    margin-bottom: 100px;
    margin-bottom: 93px;
    cursor: pointer;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-size: 36px;
    font-weight: 700;
    line-height: 20px;
    transition: opacity .5s, top .5s;
    color: #fff;
    opacity: 0.8;
    font-weight: normal;
    font-family: "Uni Neue Light"; }
    .menu .menu_close:hover,
    .menu .menu2_close:hover,
    .menu2 .menu_close:hover,
    .menu2 .menu2_close:hover {
      opacity: 1; }
  .menu form,
  .menu2 form {
    width: 260px;
    padding: 36px 0 32px;
    padding-left: 0; }
    .menu form input[type=text],
    .menu2 form input[type=text] {
      border-radius: 26px;
      height: calc(2.75rem + 2px);
      font-size: 16px;
      color: #6d7c7f;
      padding-left: 45px;
      background-image: url(../img/phone_icon.png);
      background-repeat: no-repeat;
      background-position: 19px;
      border: 1px solid #22252c; }
    .menu form input[name=callback_name],
    .menu2 form input[name=callback_name] {
      background-image: url(../img/user_icon.png); }
    .menu form button[type=submit],
    .menu2 form button[type=submit] {
      width: 100%; }

.menu2 {
  width: 534px;
  padding: 36px 37px 20px 60px; }
  .menu2 .menu2_close {
    margin-bottom: 33px; }
  .menu2 .navbar-nav {
    text-align: right;
    padding-right: 62px; }
  .menu2 .nav-link {
    color: #fff !important;
    font-size: 2rem !important;
    padding-bottom: 1.5rem;
    font-family: "Uni Neue Light"; }

#audioModal audio {
  display: none; }

.audio-comment_block {
  line-height: 54px;
  background: #f1f2f4;
  width: 100%;
  height: 54px;
  padding: 0;
  margin: 0; }

.audio-player {
  height: 100%;
  margin: auto; }

.control_play_pause {
  float: left;
  padding: 0 5px;
  padding-left: 10px; }

.control_stop {
  float: left;
  padding: 0 5px; }

.control_volume {
  float: right;
  padding: 0 10px 0 5px; }

.control_progress {
  float: left;
  padding: 0 10px; }
  .control_progress progress {
    display: block;
    height: 14px;
    width: 16em;
    margin: 20px 0;
    color: #e0a500;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

progress::-webkit-progress-bar,
progress::-moz-progress-bar,
progress::progress-bar {
  background: #e0a500; }

progress::-webkit-progress-value {
  background: #e0a500; }

progress::-moz-progress-bar {
  background-color: #e0a500; }

.playbacktime {
  font-size: 0.9rem;
  float: left;
  padding: 0 10px;
  width: 64px;
  height: 54px;
  color: #333; }

.btn.button_play_pause {
  position: relative;
  box-shadow: 0 4px 0 0 #987311, 1px 1px 6px rgba(0, 0, 0, 0.24) !important; }
  .btn.button_play_pause:hover {
    -webkit-transform: translateY(3px);
    -ms-transform: translateY(3px);
    transform: translateY(3px);
    box-shadow: 0 1px 0 0 #987311, 1px 1px 6px rgba(0, 0, 0, 0.24) !important;
    transition: all 0.25s linear; }
  .btn.button_play_pause.button_play_pause--animation {
    -webkit-transform: translateY(3px);
    -ms-transform: translateY(3px);
    transform: translateY(3px);
    box-shadow: 0 1px 0 0 #987311, 1px 1px 6px rgba(0, 0, 0, 0.24) !important;
    transition: all 0.25s linear; }
    .btn.button_play_pause.button_play_pause--animation:before, .btn.button_play_pause.button_play_pause--animation:after {
      content: "";
      width: 268px;
      height: 68px;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -6px;
      right: 0;
      border: 1px solid #fbd256;
      border-radius: 13px; }
    .btn.button_play_pause.button_play_pause--animation:before {
      -webkit-animation: circle3 2s linear infinite;
      animation: circle3 2s linear infinite; }
    .btn.button_play_pause.button_play_pause--animation:after {
      -webkit-animation: circle3 2s 1s linear infinite;
      animation: circle3 2s 1s linear infinite; }

.ui-widget.ui-widget-content {
  max-height: 410px;
  border-radius: 25px;
  border: 1px solid #dcdcdd;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 420px !important;
  margin-top: 10px !important; }

.ui-menu-item .ui-menu-item-wrapper {
  padding: 2px 20px;
  padding: 3px 10px 0px;
  font-size: 14px;
  font-family: "Uni Neue Regular"; }
  .ui-menu-item .ui-menu-item-wrapper.ui-state-active {
    padding: 3px 10px 0px;
    border: none;
    background: none;
    color: inherit;
    text-decoration: underline; }

section#sec5 .slick-next, section#sec5 .slick-prev {
  z-index: 1;
  height: 40px;
  width: 40px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 2px 2px 18px rgba(142, 142, 142, 0.26), 2px 2px 0 rgba(226, 226, 226, 0.1); }
  section#sec5 .slick-next:before, section#sec5 .slick-prev:before {
    font-size: 40px;
    line-height: 1.15;
    opacity: 1;
    display: block;
    content: "";
    width: 40px;
    height: 40px;
    background-image: url(../img/next-arrow.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    color: black !important;
    opacity: 1; }

section#sec5 .slick-prev:before {
  background-image: url(../img/prev-arrow.svg); }

.slick-active:focus {
  outline: none; }

section#pageThanks {
  padding-top: 25vh; }
  section#pageThanks .block_thanks_main h1 {
    font-size: 46px; }
  section#pageThanks .block_thanks_main p {
    font-size: 26px; }

@media screen and (max-width: 1366px) {
  section#pageThanks {
    padding-top: 25vh; }
  section#page404 {
    padding-top: 155px; }
    section#page404 .error_page h1 br {
      display: none; }
    section#page404 .error_page p br {
      display: none; }
  .fullpage {
    background-position-x: -100px;
    background-position-x: 68%; }
  header .navbar {
    padding: 35px 5px;
    padding: 5px 10px; }
  header .header_phoneblock {
    padding: 8px 0 0 50px;
    background-position: 27px 14px; }
  header .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  header .menu {
    width: auto;
    padding-right: 180px; }
    header .menu .menu_close {
      margin-bottom: 40px; }
    header .menu p {
      line-height: 1.5; }
  header .navbar-brand {
    margin-right: 0; }
  section#main {
    margin-top: -121px;
    padding: 130px 0 0;
    padding-top: 22vh; }
    section#main h1 {
      font-size: 2.25rem; }
    section#main .test-drive_div {
      margin-bottom: 15px; }
      section#main .test-drive_div p {
        font-size: 1.0rem;
        font-size: 0.95rem;
        line-height: 1.125;
        margin-bottom: .75rem;
        font-family: "Uni Neue Bold"; }
        section#main .test-drive_div p + p {
          font-size: .85rem;
          font-family: "Uni Neue Regular"; }
      section#main .test-drive_div span {
        font-size: 100px;
        line-height: 0.8; }
    section#main .slogan {
      line-height: 1.1;
      font-size: 1.75rem; }
    section#main .advantage_cycles {
      font-size: 2.25rem;
      line-height: 1.3;
      margin: 2.5rem 8px 3rem; }
    section#main .test-drive_form {
      padding: 25px 32px 24px; }
      section#main .test-drive_form h4 {
        font-size: 1.0rem;
        font-size: 1.2rem;
        font-family: "Uni Neue Regular";
        font-family: "Uni Neue Bold"; }
      section#main .test-drive_form button[type=submit] {
        font-size: .85rem; }
  section#sec2 .advantage_right {
    background-image: url(../img/adv_button2.png);
    background-repeat: no-repeat;
    background-position: 265px 305px;
    background-size: 315px; }
    section#sec2 .advantage_right h3 {
      font-size: 1.75rem;
      line-height: 1.3; }
  section#sec3 {
    background-position-x: 62%; }
    section#sec3 h2 {
      font-size: 2.25rem;
      line-height: 1.1; }
      section#sec3 h2 + p {
        font-size: 1.75rem;
        line-height: 1.1; }
    section#sec3 .description {
      margin-top: 35px; }
    section#sec3 .send-catalog_form form {
      margin: 45px 0 60px; }
  section#sec4 {
    margin-top: -45px; }
    section#sec4 h2 {
      font-size: 1.75rem;
      line-height: 1.25; }
    section#sec4 .quiz_block {
      margin-top: 55px;
      margin-bottom: 25px; }
    section#sec4 .quiz_block .question__title {
      font-size: 1.75rem; }
  section#sec5 {
    padding: 75px 0 35px; }
    section#sec5 h2 {
      text-align: center;
      line-height: 1.2; }
      section#sec5 h2 + p {
        margin: 35px 0 50px; }
  section#sec6 {
    background-position-x: 50%; }
  section#sec7 h2 {
    font-size: 2.25rem;
    line-height: 1.2; }
  section#sec7 .customers_count span {
    font-size: 4.25rem; }
  section#sec7 .comments_logos {
    margin: 90px auto 60px;
    margin: 75px auto 40px; }
  section#sec7 .slick-dots {
    bottom: -90px; }
  section#sec7 .slick-dots {
    bottom: -75px; }
  section#sec8 {
    background-position-x: 65%; }
    section#sec8 h2 {
      line-height: 1.2;
      margin-bottom: 10px; }
    section#sec8 .quiz_questions {
      margin: 35px 0;
      width: 435px; }
    section#sec8 .delivery_advantages {
      margin: 25px 0 20px; }
      section#sec8 .delivery_advantages .delivery_advantages-item {
        width: 50%;
        height: 85px;
        background-position: 40px 15px;
        padding-left: 110px;
        background-position: 10px 15px;
        padding-left: 80px;
        background-repeat: no-repeat; }
        section#sec8 .delivery_advantages .delivery_advantages-item:before {
          left: 12%; }
  section#sec9 .address_block h2 {
    line-height: 1.1; }
  footer .footer_menu {
    padding: 60px 0; } }

@media screen and (orientation: landscape) and (max-width: 1366px) {
  section#page404 {
    padding-top: 200px; } }

@media screen and (max-width: 1280px) {
  section#page404 {
    padding-top: 255px;
    padding-top: 225px; }
  .fullpage {
    background-position-x: 48%; }
  header .navbar {
    padding: 35px 25px; }
  section#main {
    padding-top: 24vh; }
    section#main h1 {
      font-size: 3rem; }
      section#main h1 br {
        display: none; }
    section#main .slogan br {
      display: none; }
    section#main .advantage_cycles {
      margin: 3rem 8px 3rem;
      font-size: 2.875rem; }
    section#main .test-drive_div {
      margin-bottom: 40px; }
      section#main .test-drive_div p {
        font-size: 1.125rem; }
        section#main .test-drive_div p + p {
          font-size: .875rem; }
      section#main .test-drive_div span {
        font-size: 145px;
        line-height: 115px; } }

@media screen and (max-width: 1280px) and (max-device-height: 800px) {
  .fullpage {
    background-position-x: 50%; }
  section#main {
    padding-top: 23vh;
    padding-top: 21vh; }
    section#main .test-drive_div {
      margin-bottom: 15px; }
  section#sec7 .slick-dots {
    bottom: -70px; } }

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .header_mailblock {
    display: none; }
  section#sec2 .advantage_right {
    background-size: 247px; } }

@media screen and (max-width: 1024px) {
  section#pageThanks {
    padding-top: 177px;
    height: 106vh; }
  section#page404 {
    padding-top: 200px;
    height: 105vh;
    padding-top: 177px;
    height: 106vh; }
  .fullpage {
    background-position-x: 45%; }
  header .navbar {
    padding: 35px 20px; }
  header .header_mailblock {
    display: none; }
  header .header_phoneblock {
    padding: 8px 0 0 30px;
    background-position: 7px 14px; }
  header .navbar-nav .nav-link {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  header .menu {
    padding-right: 60px; }
  section#main {
    padding-top: 20vh; }
    section#main .test-drive_div {
      margin-right: 20px; }
    section#main .test-drive_form {
      margin-right: 10px; }
  section#sec8 .delivery_advantages .delivery_advantages-item:before {
    left: 2%; }
  .quiz_questions form .quantity-arrow-minus,
  .quiz_questions form .quantity-arrow-plus {
    top: 34px; }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 {
    float: none;
    width: 100%; }
  section#sec2 .advantage_right {
    background-size: 247px;
    background-position: 402px 300px; } }

@media screen and (orientation: portrait) and (max-width: 809px) {
  section#pageThanks {
    padding-top: 29vh; }
  section#page404 {
    padding-top: 29vh; } }

@media screen and (max-width: 768px) {
  .fullpage {
    background-position-x: 55%;
    background-position: top center; }
  header button:focus {
    outline: 1px none; }
  header .header_contacts {
    display: block;
    width: 100%; }
  header .header_mailblock {
    display: block; }
  header .navbar-brand {
    line-height: 1;
    padding: 0; }
  header .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.85);
    font-size: 1rem; }
  header .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.85);
    border-color: transparent; }
  header .navbar-collapse.collapse.show {
    z-index: 3; }
  section#main {
    padding-top: 5vh;
    padding-top: 10vh; }
    section#main h1 {
      font-size: 2.25rem; }
      section#main h1 br {
        display: none; }
    section#main .slogan {
      line-height: 1.1;
      font-size: 1.75rem; }
      section#main .slogan br {
        display: none; }
    section#main .advantage_cycles {
      display: none;
      margin: 2rem 8px 2rem;
      font-size: 2.5rem;
      width: 50%;
      float: left; }
      section#main .advantage_cycles.d-md-none {
        display: block;
        margin: 5.5rem 0 3rem; }
        section#main .advantage_cycles.d-md-none:before {
          content: "";
          display: block;
          border-top: 8px solid #92cd75;
          width: 70px;
          width: 62px;
          padding-top: 22px; }
    section#main .patent_block {
      margin-top: 4.25rem;
      margin-left: 3.25rem; }
    section#main .test-drive_div {
      display: none;
      margin-right: 20px;
      float: left !important; }
      section#main .test-drive_div.d-md-none {
        display: block; }
    section#main .test-drive_form {
      margin-right: 0; }
    section#main .main-left {
      margin-top: 40px;
      margin-bottom: 35px; }
  section#sec2 {
    padding: 65px 0;
    background-position: top center; }
    section#sec2 .advantage_right h3 {
      font-size: 2rem;
      line-height: 1.2; }
    section#sec2 .adv_block {
      margin: 35px 0; }
    section#sec2 .production-video.d-md-none .production-video__img .final-top-l__btn {
      margin: 58px auto 10px; }
    section#sec2 .advantage_right {
      background-position: 260px 750px;
      background-size: 220px; }
  section#sec8 .quiz_questions {
    width: 450px;
    margin: 0 auto 0; }
  section#sec8 .delivery_advantages .delivery_advantages-item:before {
    left: 0%;
    top: 30px; }
  .quiz_questions form .quantity-arrow-minus,
  .quiz_questions form .quantity-arrow-plus {
    top: 30px; } }

@media screen and (orientation: portrait) and (max-width: 768px) {
  section#pageThanks {
    height: 86vh;
    padding-top: 23vh; }
  section#page404 {
    height: 90vh;
    height: 95vh;
    padding-top: 27vh; } }

@media screen and (min-width: 641px) and (max-width: 768px) {
  .menu2 {
    width: auto;
    padding-left: 85px; }
  .menu2 .nav-link {
    font-size: 1.2rem !important;
    padding-bottom: 1.3rem; }
  header .header_contacts {
    margin: 15px 10px; }
  header .header_phoneblock {
    background-position: 0px 18px;
    background-size: 20px; }
    header .header_phoneblock a {
      font-size: 1.6rem;
      font-size: 1.4rem; }
  header .header_mailblock {
    display: block; }
    header .header_mailblock a {
      font-size: 1.6rem;
      font-size: 1.4rem; }
  header .navbar-brand {
    line-height: 1;
    padding: 0; }
    header .navbar-brand img {
      height: 36px; }
  section#main {
    padding-top: 12vh;
    padding-top: 100px !important; }
    section#main h1 {
      text-align: center;
      font-size: 2.25rem !important; }
    section#main .slogan {
      font-size: 1.5rem;
      text-align: center; }
    section#main .advantage_cycles {
      display: block;
      width: 240px; }
      section#main .advantage_cycles.d-md-none {
        display: none; }
    section#main .test-drive_div.d-md-none {
      display: block;
      float: right !important;
      width: 245px;
      margin: 22px 0 0; }
    section#main .test-drive_div {
      display: none; }
      section#main .test-drive_div.d-md-none {
        display: block;
        float: right !important;
        width: 245px;
        margin: 22px 0 0;
        margin: 28px 0 0; }
        section#main .test-drive_div.d-md-none span {
          line-height: 85px;
          font-size: 110px;
          width: 60px;
          line-height: 60px;
          font-size: 75px; }
        section#main .test-drive_div.d-md-none p {
          padding-left: 72px; }
          section#main .test-drive_div.d-md-none p br {
            display: none; }
    section#main .main-left {
      margin: 50px 0;
      margin: 0 0 50px; }
    section#main .main-right button.d-md-none {
      display: none; }
    section#main .test-drive_form {
      margin: -50px auto 0 !important;
      float: none !important; }
    section#main .patent_block {
      display: none !important; } }

@media screen and (max-width: 640px) {
  .catalog_slider .card {
    margin: 20px; }
  .fullpage {
    background: #fff url(../img/main_320.jpg) no-repeat bottom center;
    background: #fff url(../img/main_320m2.jpg) no-repeat bottom center;
    background-size: cover; }
  header .menu2 {
    width: 400px; }
    header .menu2 .nav-link {
      font-size: 1rem !important;
      padding-bottom: 1rem !important; }
  header .navbar-toggler {
    padding-right: 6px; }
  header .navbar {
    padding: 25px 20px; }
  header .navbar-brand img {
    height: 35px;
    height: 20px;
    height: 16px;
    padding-left: 12px; }
  header .header_phoneblock {
    background-position: 1px 15px;
    background-size: 1.125rem; }
    header .header_phoneblock a {
      font-size: 1.25rem; }
    header .header_phoneblock span.menu_open {
      display: none; }
  header .header_contacts {
    padding: 35px 15px 35px;
    padding: 33px 12px 35px; }
  header .header_mailblock {
    margin-top: 0; }
    header .header_mailblock a {
      font-size: 1.25rem; }
  section#main {
    padding-top: 10vh; }
    section#main h1 {
      font-size: 2.25rem;
      line-height: 1.2; }
    section#main .slogan {
      line-height: 1.35;
      font-size: 2rem;
      font-size: 1.7rem;
      margin-top: 1.9rem; }
    section#main .main-left {
      margin-top: 5px;
      margin-bottom: 0px; }
    section#main .advantage_cycles {
      margin: 1rem 8px 2rem;
      font-size: 2.0rem;
      margin: 2.5rem 0px 3rem;
      font-size: 2.5rem;
      font-size: 2.25rem; }
      section#main .advantage_cycles div {
        font-size: 1rem;
        color: #465356;
        padding-top: 5px;
        padding-left: 6px; }
      section#main .advantage_cycles.d-md-none:before {
        border-top: 6px solid #92cd75;
        border-top: 5px solid #92cd75;
        width: 54px; }
      section#main .advantage_cycles:before {
        display: none; }
    section#main .vision640 {
      padding: 1.4rem 2.4rem 1.4rem;
      font-size: 18px; }
    section#main .patent_block {
      display: none;
      margin-top: 4.25rem;
      margin-left: 3.25rem; }
    section#main .test-drive_div {
      margin-right: 20px;
      float: left !important;
      margin-bottom: 25px;
      margin: 50px 0 20px; }
      section#main .test-drive_div span {
        width: 60px;
        font-size: 80px;
        line-height: .8; }
      section#main .test-drive_div p {
        line-height: 1.25;
        font-size: 1.5rem;
        padding-left: 65px; }
        section#main .test-drive_div p + p {
          display: none; }
    section#main .test-drive_form {
      display: none;
      float: none !important;
      clear: both;
      margin: auto;
      width: 75%; }
      section#main .test-drive_form h4 {
        font-family: "Uni Neue Bold";
        font-size: 1.2rem; }
  section#sec2 {
    padding: 53px 0 0;
    background: url(../img/section_02_320_4.jpg) no-repeat bottom center #fff;
    background-size: cover; }
    section#sec2 .advantage_right {
      background-image: none; }
      section#sec2 .advantage_right h3 {
        font-size: 1.15rem;
        line-height: 1.5;
        padding: 0; }
    section#sec2 .adv_block {
      margin: 21px 0; }
      section#sec2 .adv_block:before {
        width: 37px;
        padding-top: 20px; }
    section#sec2 .production-video.d-md-none .production-video__img {
      position: relative; }
      section#sec2 .production-video.d-md-none .production-video__img .final-top-l__btn {
        position: relative;
        width: 70px;
        height: 70px;
        margin: 48px auto; }
        section#sec2 .production-video.d-md-none .production-video__img .final-top-l__btn:after, section#sec2 .production-video.d-md-none .production-video__img .final-top-l__btn:before {
          width: 125px;
          height: 125px;
          width: 70px;
          height: 70px; }
      section#sec2 .production-video.d-md-none .production-video__img p {
        margin: 0;
        width: 100px;
        text-align: left;
        position: absolute;
        top: 48px;
        font-size: 10px;
        line-height: 1.6; }
    section#sec2 .adv_item {
      margin-bottom: 27px; }
      section#sec2 .adv_item span {
        line-height: 1.1;
        width: 100%; }
      section#sec2 .adv_item:nth-child(3) {
        padding-left: 20px; }
      section#sec2 .adv_item:nth-child(5) {
        display: none; }
      section#sec2 .adv_item:nth-child(6) {
        padding-left: 20px; }
    section#sec2 .production-start {
      margin: 151px 0 0;
      height: 108px;
      width: 100%; }
      section#sec2 .production-start .prodstart__left {
        padding: 12px 6px;
        padding: 29px 6px 0; }
        section#sec2 .production-start .prodstart__left span {
          font-size: 4.285rem;
          padding-left: 3px;
          line-height: 1; }
      section#sec2 .production-start .prodstart__right {
        font-size: .75rem;
        padding: 33px 0 0;
        padding-left: 165px;
        line-height: 1.65; }
  section#sec3 {
    background: #24262b url(../img/section_03_320.jpg) no-repeat;
    background: #24262b url(../img/section_03mod2_320.jpg) no-repeat;
    background-size: cover;
    padding: 43px 0 0; }
    section#sec3 h2 {
      font-size: 1.5rem;
      line-height: 1.25;
      margin-bottom: .75rem; }
      section#sec3 h2 + p {
        font-size: 1.0rem;
        line-height: 1.35; }
    section#sec3 .description {
      margin-top: 5px; }
      section#sec3 .description .desc {
        font-size: .5rem;
        background-repeat: no-repeat;
        background-position: -8% 49%;
        background-position: -19px -12px;
        float: left;
        width: 100%;
        background-size: 60px;
        height: auto;
        padding: 11px 0; }
        section#sec3 .description .desc span {
          display: block;
          padding: 0;
          padding-top: 0;
          padding-bottom: 0;
          width: 22px;
          float: left;
          /* height: 15px; */
          text-align: center;
          line-height: 15px;
          font-size: 10px; }
          section#sec3 .description .desc span:after {
            top: -7px;
            left: -3px; }
        section#sec3 .description .desc.desc1, section#sec3 .description .desc.desc2 {
          margin-top: 0; }
      section#sec3 .description .desc-text {
        font-size: .625rem;
        padding: 0 35px;
        padding-right: 0;
        height: auto; }
        section#sec3 .description .desc-text br {
          display: none; }
    section#sec3 .description_block form {
      width: 270px;
      padding: 25px 26px;
      padding: 25px 26px 18px;
      text-align: center;
      background: #fff;
      border-radius: 10px;
      border: 1px solid #e0e6e6;
      margin: 233px auto 60px;
      box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
      border: none; }
      section#sec3 .description_block form h4 {
        font-size: 0.875rem;
        margin-bottom: 1.25rem;
        line-height: 1.5; }
      section#sec3 .description_block form input[type=text] {
        border-radius: 26px;
        height: calc(2.45rem + 2px);
        font-size: 0.875rem;
        color: #6d7c7f;
        padding-left: 45px;
        background-image: url(../img/phone_icon.png);
        background-repeat: no-repeat;
        background-position: 19px; }
      section#sec3 .description_block form input[name=test-drive_mail] {
        background-image: url(../img/mail_icon.png);
        background-repeat: no-repeat;
        background-position: 19px; }
      section#sec3 .description_block form button[type=submit] {
        width: 100%;
        background: #fbd255 url(../img/download_icon.png) no-repeat;
        margin: 9px 0 0px;
        font-size: 0.8rem;
        padding: .685rem 3rem;
        padding-left: 0rem;
        background-position: 158px 12px; }
      section#sec3 .description_block form .form-group {
        margin-bottom: 0.75rem; }
        section#sec3 .description_block form .form-group:last-child {
          margin-bottom: 0; }
      section#sec3 .description_block form label {
        color: #6d7c7f;
        font-size: .5625rem;
        margin: .79rem .15rem .1rem;
        margin-top: 0;
        margin-top: 12px;
        line-height: 1;
        text-align: left; }
  section#sec4 {
    margin: 0;
    padding: 36px 0 70px; }
    section#sec4 h2 {
      font-size: 1.125rem;
      line-height: 1.65; }
      section#sec4 h2 br {
        display: none; }
    section#sec4 .quiz_block {
      margin-top: 35px;
      margin-bottom: 0px;
      height: auto;
      min-height: unset; }
      section#sec4 .quiz_block .quiz_left {
        width: 100%;
        float: none; }
        section#sec4 .quiz_block .quiz_left form {
          margin-left: 15px;
          margin-right: 15px; }
          section#sec4 .quiz_block .quiz_left form h3 br {
            display: none; }
          section#sec4 .quiz_block .quiz_left form#QuizRequestCallback .row {
            display: block !important; }
            section#sec4 .quiz_block .quiz_left form#QuizRequestCallback .row .col {
              margin: 0 auto 15px;
              width: 400px; }
      section#sec4 .quiz_block .progress__label {
        font-size: .625rem;
        padding-top: 19px; }
      section#sec4 .quiz_block .progress {
        height: .45rem;
        width: auto;
        margin: auto 12px; }
      section#sec4 .quiz_block .question__title {
        font-size: 1rem;
        padding: 30px 0 2px; }
      section#sec4 .quiz_block .quiz__question .card-body {
        display: block;
        width: auto;
        padding: 1rem 1.25rem;
        padding: 0;
        padding-left: 83px;
        height: 80px; }
        section#sec4 .quiz_block .quiz__question .card-body .card-text {
          font-size: 10px;
          height: 36px;
          display: flex;
          align-items: center;
          padding: 8px;
          height: 100%; }
      section#sec4 .quiz_block .quiz__question .card {
        text-align: left;
        position: relative;
        border-radius: 8px;
        display: block;
        border: 1px solid rgba(0, 0, 0, 0.04);
        margin-bottom: 5px; }
        section#sec4 .quiz_block .quiz__question .card img {
          display: block;
          height: 80px;
          width: 80px;
          float: left;
          padding: 6px;
          border-radius: 12px;
          height: 100px;
          width: 100px; }
          section#sec4 .quiz_block .quiz__question .card img + .card-body {
            height: 100px;
            padding-left: 100px; }
            section#sec4 .quiz_block .quiz__question .card img + .card-body .card-text {
              font-size: 12px;
              font-family: "Uni Neue Regular"; }
        section#sec4 .quiz_block .quiz__question .card.card__mini img {
          height: auto; }
        section#sec4 .quiz_block .quiz__question .card.card__mini .card-body {
          height: auto; }
          section#sec4 .quiz_block .quiz__question .card.card__mini .card-body .card-text {
            height: 24px;
            font-size: 14px;
            justify-content: center; }
        section#sec4 .quiz_block .quiz__question .card.card__mini2 {
          height: 100px; }
        section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 {
          height: 70px; }
  section#sec5 {
    padding: 35px 0 19px; }
    section#sec5 h2 {
      line-height: 1.5;
      font-size: 1.125rem;
      margin: 0 0.95rem .5rem; }
      section#sec5 h2 br {
        display: none; }
      section#sec5 h2 + p {
        margin: 18px 0 37px;
        font-size: .875rem; }
        section#sec5 h2 + p:before {
          width: 35px;
          padding-top: 12px; }
    section#sec5 .catalog_slider {
      padding: 0 20px; }
      section#sec5 .catalog_slider .card {
        margin: 20px 0;
        margin: 20px; }
  section#sec6 {
    padding: 46px 0 124px;
    background: #ccc url(../img/section_06_320.jpg) no-repeat;
    background-position: bottom center;
    background-size: cover; }
    section#sec6 .laserEngraving_block h2 {
      font-size: 1.125rem;
      line-height: 1.75; }
      section#sec6 .laserEngraving_block h2 span {
        font-size: 1.125rem; }
    section#sec6 .economy_block {
      margin: 45px 0 -24px;
      margin: 45px 0 0; }
      section#sec6 .economy_block span {
        font-size: 12.5px;
        float: left; }
      section#sec6 .economy_block .economy_value {
        font-size: 10px;
        float: left;
        line-height: 50px;
        padding: 0px 14px;
        margin: -12px 0 0; }
        section#sec6 .economy_block .economy_value span {
          font-size: 50.5px;
          line-height: 1;
          padding-right: 6px; }
      section#sec6 .economy_block .economy_text {
        margin-top: -10px;
        padding: 0;
        width: 100%;
        font-size: 12px; }
        section#sec6 .economy_block .economy_text:before {
          width: 55px;
          padding-top: 16px; }
        section#sec6 .economy_block .economy_text br {
          display: none; }
      section#sec6 .economy_block .free_engraving {
        font-size: 10px;
        margin: 20px 0 15px;
        background: none;
        position: relative;
        width: 195px;
        padding-bottom: 0; }
        section#sec6 .economy_block .free_engraving:before {
          content: "";
          display: block;
          position: absolute;
          background-image: url(../img/array_icon.png);
          background-repeat: no-repeat;
          width: 215px;
          height: 190px;
          background-size: 32px;
          background-position: 178px 137px;
          margin-top: 15px;
          transform: rotate(-70deg);
          margin: auto;
          width: 45px;
          height: 60px;
          background-position: 4px 6px;
          right: -27px; }
        section#sec6 .economy_block .free_engraving br {
          display: none; }
    section#sec6 .bookingForm form {
      width: 200px;
      padding: 4px 0 0; }
      section#sec6 .bookingForm form .form-group {
        margin-bottom: 13px; }
        section#sec6 .bookingForm form .form-group:nth-last-child(2) {
          margin-bottom: 1.0rem; }
      section#sec6 .bookingForm form .custom-control-label {
        margin-right: -18px; }
    section#sec6 .bookingForm input[name=laser-engraving_phone] {
      height: calc(2.5rem + 2px);
      font-size: 13px;
      padding-left: 48px; }
    section#sec6 .bookingForm button[type=submit] {
      width: 100%;
      font-size: 12px;
      padding: .75rem 1.3rem; }
  section#sec7 {
    background: #faf9fe;
    padding: 34px 0 64px; }
    section#sec7 h2 {
      font-size: 1.125rem;
      line-height: 1.65;
      text-align: center; }
      section#sec7 h2 br {
        display: none; }
    section#sec7 .comments_block {
      width: 100%;
      height: 400px;
      margin: 24px auto; }
    section#sec7 .slide-item {
      height: 380px;
      min-width: 290px; }
    section#sec7 .slick-dots {
      bottom: -76px; }
      section#sec7 .slick-dots li button:before {
        font-size: 14px; }
    section#sec7 .customers_count {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 36px 0 0; }
      section#sec7 .customers_count span {
        width: 155px;
        font-size: 3.625rem;
        float: left; }
      section#sec7 .customers_count p {
        font-size: 10px;
        margin: 20px 0 0 171px;
        line-height: 1.6; }
        section#sec7 .customers_count p:before {
          display: none; }
    section#sec7 .comments_logos {
      height: 170px;
      margin: 5px auto -13px;
      background: url(../img/comments_logos_320.png) no-repeat;
      background-size: cover;
      background-size: contain;
      background-position: top center; }
  section#sec7 .slick-dots {
    bottom: -46px; }
  section#sec7 .slick-next,
  section#sec7 .slick-prev {
    background-size: 8px;
    top: 48%;
    top: 47.6%; }
  section#sec7 .slick-prev {
    left: 3px;
    left: 8px;
    left: 0; }
  section#sec7 .slick-next {
    right: 5px;
    right: 8px;
    right: 0; }
  section#sec7 .slide-item {
    min-width: auto;
    width: 243px;
    border: none;
    margin: 0 25px 30px;
    height: 380px;
    height: 360px;
    box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66); }
    section#sec7 .slide-item:before {
      content: "";
      position: absolute;
      height: 30px;
      width: 200px;
      top: auto;
      bottom: -15px;
      left: 0;
      right: 0;
      /* width: 0%; */
      /* margin: auto; */
      border-radius: 15px;
      /* transition: .3s; */
      /* transition-delay: .3s; */
      background-color: #ffffff;
      z-index: -1;
      margin: auto;
      box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
      bottom: -20px;
      width: 190px;
      width: 75%; }
    section#sec7 .slide-item:after {
      content: "";
      position: absolute;
      height: 30px;
      width: 200px;
      top: auto;
      bottom: -15px;
      left: 0;
      right: 0;
      /* width: 0%; */
      /* margin: auto; */
      border-radius: 15px;
      /* transition: .3s; */
      /* transition-delay: .3s; */
      background-color: #ffffff;
      z-index: -1;
      margin: auto;
      box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
      bottom: -10px;
      width: 220px;
      width: 88%; }
  section#sec7 .card_img_blk {
    background: none;
    border-radius: 0;
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 142px;
    height: 150px; }
    section#sec7 .card_img_blk img {
      height: auto;
      width: 130%;
      margin: -38px -37px 0;
      margin: auto;
      width: 100%; }
  section#sec7 .card-img-overlay {
    top: 77px;
    top: 85px;
    width: 100%;
    bottom: auto; }
    section#sec7 .card-img-overlay .card-title {
      color: #fff;
      margin-bottom: 0;
      font-size: 0.85rem; }
  section#sec7 .card-body {
    position: absolute;
    width: 100%;
    padding: 15px;
    top: 142px;
    top: 145px; }
    section#sec7 .card-body h5 {
      font-size: 14px;
      line-height: 1.45;
      padding: 0;
      text-align: center;
      margin-bottom: .55rem; }
    section#sec7 .card-body img {
      display: none; }
    section#sec7 .card-body p {
      font-size: 12px;
      text-align: center;
      margin-bottom: 17px;
      padding: 0 10px;
      text-align: left; }
    section#sec7 .card-body button[type=button] {
      font-size: 12px;
      padding: .75rem 2.3rem .55rem;
      padding-left: 1.35rem;
      padding-right: 3rem; }
      section#sec7 .card-body button[type=button] .speaker_icon {
        top: 13px;
        right: 18px;
        background-size: 16px;
        background-position: center; }
  section#sec8 {
    padding: 49px 0 35px;
    padding: 49px 0 44px;
    background: #ccc url(../img/section_08_320.jpg) no-repeat;
    background-size: cover; }
    section#sec8 h2 {
      line-height: 1.65;
      margin-bottom: 14px;
      font-size: 18px;
      text-align: center; }
      section#sec8 h2 br {
        display: none; }
    section#sec8 p {
      font-size: 14px;
      text-align: center; }
    section#sec8 .delivery_advantages {
      margin: 29px 0 20px;
      height: auto;
      height: 190px; }
      section#sec8 .delivery_advantages .delivery_advantages-item {
        height: auto;
        padding-left: 32px;
        background-size: 60px;
        background-position: -18px -6px;
        margin-left: 0px; }
        section#sec8 .delivery_advantages .delivery_advantages-item span {
          font-size: 34px;
          float: left;
          padding-right: 10px;
          margin-top: -3px; }
        section#sec8 .delivery_advantages .delivery_advantages-item p {
          font-size: 12px;
          text-align: center;
          padding: 3px 0 0;
          line-height: 1.5; }
        section#sec8 .delivery_advantages .delivery_advantages-item:nth-child(1) {
          width: 60%; }
        section#sec8 .delivery_advantages .delivery_advantages-item:nth-child(2) {
          display: none; }
        section#sec8 .delivery_advantages .delivery_advantages-item:nth-child(3) {
          width: 40%; }
    section#sec8 .quiz_questions {
      margin: 35px 0;
      width: 100%;
      padding: 15px 14px 12px; }
      section#sec8 .quiz_questions form {
        padding: 0 24px; }
        section#sec8 .quiz_questions form label {
          margin-bottom: 6px;
          font-size: 8px;
          padding-left: 13px;
          padding-left: 0; }
        section#sec8 .quiz_questions form .form-group {
          margin-bottom: 2px; }
        section#sec8 .quiz_questions form .align-items-end .form-group {
          margin-top: 14px; }
          section#sec8 .quiz_questions form .align-items-end .form-group label {
            height: 42px;
            line-height: 42px;
            float: left;
            vertical-align: middle;
            padding-right: 17px; }
        section#sec8 .quiz_questions form .quantity-arrow-minus {
          top: 1px;
          height: 40px;
          width: 38px;
          line-height: 40px;
          left: 74px;
          float: left;
          left: 133px; }
        section#sec8 .quiz_questions form .quantity-arrow-plus {
          top: 1px;
          height: 40px;
          width: 38px;
          line-height: 40px;
          right: 7px;
          left: 178px;
          float: left;
          left: 237px; }
        section#sec8 .quiz_questions form input[type=number] {
          height: calc(2.5rem + 2px);
          font-size: 12px;
          font-size: 16px;
          color: #6d7c7f;
          padding-left: 45px;
          padding-right: 45px;
          background-size: 12px;
          width: 146px;
          float: left; }
        section#sec8 .quiz_questions form button[type=submit] {
          width: 100%;
          margin-top: -5px;
          font-size: 14px;
          line-height: 1.3;
          padding: .75rem 1.3rem; }
        section#sec8 .quiz_questions form input[type=text] + span {
          top: 36px;
          right: 10px; }
      section#sec8 .quiz_questions input[type=text] {
        height: calc(2.5rem + 2px);
        font-size: 12px;
        font-size: 11px;
        padding-left: 35px;
        background-position: 13px;
        background-size: 11px; }
    section#sec8 .result {
      margin: 17px 0 0;
      margin: 19px 0 0; }
      section#sec8 .result .table thead th {
        font-size: 10px;
        vertical-align: middle;
        line-height: 1.3; }
      section#sec8 .result .table td {
        font-size: 10px;
        line-height: 2.0; }
        section#sec8 .result .table td a {
          font-size: 10px; }
        section#sec8 .result .table td:first-child {
          padding-right: 8px; }
  section#sec9 {
    background: none;
    height: 563px; }
    section#sec9 .address_block {
      width: 265px;
      width: auto;
      height: 316px;
      top: 178px;
      left: 0;
      right: 0;
      padding: 20px 10px;
      margin: 0 28px; }
      section#sec9 .address_block h2 {
        line-height: 1.6;
        font-size: 18px;
        padding-left: 50px;
        margin-bottom: 11px; }
      section#sec9 .address_block address {
        font-size: 16px;
        padding: 0 11px; }
        section#sec9 .address_block address strong {
          font-size: 12px; }
        section#sec9 .address_block address span {
          font-size: 18px; }
        section#sec9 .address_block address .address_item {
          padding-left: 35px; }
          section#sec9 .address_block address .address_item.address_item--phone {
            background-position: left 26px; }
          section#sec9 .address_block address .address_item.address_item--location {
            background-position: left 21px; }
          section#sec9 .address_block address .address_item.address_item--email {
            background-position: left 20px;
            background-position: left 26px;
            margin-top: -6px; }
  footer .navbar-brand {
    width: 100%;
    margin: 0;
    text-align: center;
    padding: 1.85rem 0 1.2rem; }
    footer .navbar-brand img {
      height: 28px; }
  footer .navbar-dark .navbar-nav .nav-link {
    font-size: 10px;
    padding: 5px;
    margin: auto;
    text-align: center; }
  footer .footer_menu {
    padding: 0px; }
  footer .socials-list {
    padding: 36px 0 32px;
    text-align: center; }
  footer .socials {
    margin: 0 10px; }
  .btn.button_play_pause.button_play_pause--animation:after,
  .btn.button_play_pause.button_play_pause--animation:before {
    width: 206px;
    height: 56px; } }

@media screen and (orientation: portrait) and (max-width: 640px) {
  section#pageThanks {
    height: 94vh;
    padding-top: 20vh; }
  section#page404 {
    height: 86vh;
    height: 92vh;
    height: 94vh;
    padding-top: 20vh; } }

@media screen and (min-width: 0px) and (max-width: 370px) {
  #orderModal .modal-content {
    padding: 0;
    background-position: -72px 0%; }
    #orderModal .modal-content h3 {
      text-align: center;
      margin: 10px 0 0px;
      font-size: 1.6rem; }
    #orderModal .modal-content .orderDiv {
      padding: 20px 0 0px;
      margin: auto;
      width: auto; }
  .catalog_slider .slick-dots li {
    margin: 0 3px; } }

@media screen and (min-width: 0px) and (max-width: 480px) {
  .btn.button_play_pause.button_play_pause--animation:after,
  .btn.button_play_pause.button_play_pause--animation:before {
    width: 203px;
    height: 56px; }
  header .menu2 {
    width: 268px;
    padding: 13px 18px; }
    header .menu2 .menu2_close {
      margin-bottom: 9px;
      font-size: 20px;
      line-height: 1; }
    header .menu2 .navbar-nav {
      text-align: right;
      padding-right: 28px; }
    header .menu2 .nav-link {
      font-size: 1rem !important;
      padding-bottom: 0.5rem !important; }
  header .navbar {
    padding: 16px 4px;
    padding: 17px 4px 15px; }
  header .navbar-brand img {
    height: 10px;
    padding-left: 12px;
    width: 210px; }
  header .navbar-toggler {
    padding: 0;
    padding-right: 9px;
    margin-top: -10px;
    margin-top: -14px; }
  header .navbar-toggler-icon {
    width: 1.0em;
    height: 0.8em; }
  header .header_contacts {
    padding: 9px 12px 9px; }
  header .header_mailblock a {
    font-size: 10px; }
  header .header_phoneblock {
    padding: 1px 0 0 30px;
    background-position: 15px 11px;
    background-size: 0.65rem; }
    header .header_phoneblock a {
      font-size: 10px; }
  #testDriveModal2 .modal-dialog {
    width: 280px;
    margin: auto; }
  #testDriveModal2 .test-drive_form {
    width: 100%;
    padding: 15px 15px; }
    #testDriveModal2 .test-drive_form h4 {
      font-size: 1.0rem; }
    #testDriveModal2 .test-drive_form .form-group {
      margin-bottom: .75rem; }
    #testDriveModal2 .test-drive_form button[type=submit] {
      padding: .75rem 1.0rem .95rem;
      font-size: 1rem;
      line-height: 1; }
    #testDriveModal2 .test-drive_form #testDrive2_range2 + p span:first-child {
      width: 35px; }
    #testDriveModal2 .test-drive_form #testDrive2_range2 + p span:nth-child(2) {
      width: 62px; }
    #testDriveModal2 .test-drive_form #testDrive2_range2 + p span:nth-child(3) {
      width: 49px; }
    #testDriveModal2 .test-drive_form #testDrive2_range2 + p span:nth-child(4) {
      width: 62px; }
    #testDriveModal2 .test-drive_form #testDrive2_range2 + p span:nth-child(5) {
      width: 38px; }
    #testDriveModal2 .test-drive_form #testDrive2_range1 + p span:first-child {
      width: 27px; }
    #testDriveModal2 .test-drive_form #testDrive2_range1 + p span:nth-child(2) {
      width: 118px; }
    #testDriveModal2 .test-drive_form #testDrive2_range1 + p span:nth-child(3) {
      width: 33px; }
    #testDriveModal2 .test-drive_form #testDrive2_range1 + p span:nth-child(4) {
      width: 65px; }
  section#main {
    padding-top: 22vh; }
    section#main h1 {
      font-size: 1.5rem;
      line-height: 1.3; }
    section#main .slogan {
      line-height: 1.5;
      font-size: 0.9375rem;
      margin-top: 0.65rem; }
    section#main .test-drive_div {
      margin: 25px 0 20px;
      margin: 27px 0 3px; }
      section#main .test-drive_div span {
        width: 42px;
        font-size: 50px;
        line-height: .8; }
      section#main .test-drive_div p {
        font-size: 0.875rem;
        padding-left: 42px;
        line-height: 1.5; }
    section#main .vision640 {
      padding: 0.65rem 1.25rem 0.75rem;
      font-size: 12px; }
    section#main .advantage_cycles div {
      font-size: 9px;
      padding-top: 3px;
      padding-left: 2px; }
    section#main .advantage_cycles.d-md-none {
      margin: 2.65rem 0 3rem;
      font-size: 23px; }
      section#main .advantage_cycles.d-md-none:before {
        border-top: 4px solid #92cd75;
        width: 30px;
        padding-top: 12px; }
  .catalog_slider .card {
    margin: 10px;
    margin: 40px;
    margin: 20px;
    padding: 15px; }
    .catalog_slider .card .card-img-top {
      padding: 2rem 2rem 0; }
  .catalog_slider.slick-dotted.slick-slider {
    margin: 0; }
  .catalog_slider .slick-dots {
    margin: -25px auto; }
  .catalog_slider .slick-prev {
    left: 0; }
  .catalog_slider .slick-next {
    right: 0; }
  section#sec5 {
    padding: 65px 0 65px; }
    section#sec5 h2 + p {
      margin: 35px 0 5px; }
  section#sec8 .delivery_advantages .delivery_advantages-item:before {
    display: none; }
  section#sec4 .quiz_block .quiz__question .card {
    margin-bottom: 15px; }
    section#sec4 .quiz_block .quiz__question .card.card__mini2 img {
      width: auto; }
    section#sec4 .quiz_block .quiz__question .card.card__mini2 .card-text2 {
      text-align: right;
      padding-right: 30px; }
  section#sec4 .quiz_block .progress__label {
    font-size: 14px; }
  section#sec2 {
    background: url(../img/section_02.jpg) no-repeat bottom center #fff;
    background-size: cover; }
    section#sec2 .advantage_left {
      background-image: url(../img/adv_button2.png);
      background-repeat: no-repeat;
      background-position: 76px -29px;
      background-position-x: center;
      background-size: 250px; } }

@media screen and (min-width: 360px) and (max-width: 480px) {
  #orderModal .modal-content {
    background-position: -40px 0; }
  #orderModal .modal-content {
    background-position: -75px 0;
    padding: 0; }
    #orderModal .modal-content h3 {
      font-size: 1.7rem;
      margin: 48px 0 4px; }
    #orderModal .modal-content .orderDiv {
      width: auto;
      padding: 22px 0 0px;
      margin: auto; }
  #testDriveModal2 .test-drive_form button[type=submit] {
    padding: .95rem .5rem .95rem;
    font-size: 14px; }
  header .navbar {
    padding: 27px 4px 15px;
    padding: 27px 10px 15px; }
  header .navbar-toggler {
    padding-right: 16px;
    padding-right: 0;
    margin-top: -6px; }
  header .navbar-toggler-icon {
    width: 1.6em;
    height: 1.2em; }
  header .navbar-brand img {
    height: 14px;
    padding-left: 20px;
    padding-left: 5px;
    width: auto; }
  header .header_contacts {
    padding: 25px 20px 9px;
    padding: 7px 5px 20px; }
  header .header_mailblock a {
    font-size: 15px;
    font-size: 13px; }
  header .header_phoneblock {
    padding: 6px 0 0 30px;
    background-position: 7px 11px;
    background-size: .95rem; }
    header .header_phoneblock a {
      font-size: 15px;
      font-size: 13px; }
  section#main {
    padding-top: 15vh;
    padding-top: 20vh; }
    section#main .main-left {
      margin-top: 4px;
      margin-bottom: 0; }
    section#main h1 {
      font-size: 2.3rem;
      font-size: 1.75rem;
      line-height: 1.2;
      font-size: 1.6rem;
      margin: 0; }
    section#main .slogan {
      line-height: 1.5;
      font-size: 1.4rem;
      margin-top: 1.0rem;
      line-height: 1.3;
      font-size: 1.2rem;
      margin: 0.5rem 0 1.75rem; }
    section#main .test-drive_div {
      margin: 50px 0 12px;
      margin: 0px 0 12px; }
      section#main .test-drive_div.d-md-none {
        width: 100%; }
      section#main .test-drive_div span {
        width: 71px;
        font-size: 74px;
        width: 55px;
        font-size: 65px; }
      section#main .test-drive_div p {
        font-size: 1.3rem;
        padding-left: 71px;
        font-size: 1.0rem;
        padding-left: 55px; }
    section#main .vision640 {
      padding: 1.0rem 1.95rem 1.0rem;
      font-size: 18px;
      font-size: 14px; }
    section#main .advantage_cycles.d-md-none {
      margin: 4.0rem 0 3rem;
      font-size: 35px;
      width: 100%;
      margin: 2rem 0 3rem;
      font-size: 30px; }
      section#main .advantage_cycles.d-md-none:before {
        border-top: 5px solid #92cd75;
        width: 53px;
        padding-top: 19px;
        border-top: 4px solid #92cd75;
        width: 40px;
        padding-top: 15px; }
    section#main .advantage_cycles div {
      font-size: 14px;
      padding-top: 2px;
      padding-left: 6px; }
  section#sec3 {
    background-position: 0 -44px;
    background-position: 0 -117px; }
  section#sec8 {
    background-position: 0 -30px; }
  section#sec8 {
    background-position: 0 -110px; } }

@media screen and (max-width: 420px) {
  .block_thanks_main .error_page img {
    width: 270px; }
  .block_thanks_main .error_page h1 {
    font-size: 26px;
    margin: 25px 15px 18px;
    line-height: 1.2; }
  .block_thanks_main .error_page p {
    font-size: 18px;
    margin-bottom: 20px; }
  section#pageThanks .block_thanks_main h1 {
    font-size: 30px;
    line-height: 1; }
  section#pageThanks .block_thanks_main p {
    font-size: 17px;
    line-height: 1.3;
    padding: 0 35px; } }

@media screen and (max-width: 377px) {
  .quiz_left .test__kviz06--item {
    padding: 0; }
  section#sec4 .quiz_block .quiz_left form {
    margin: auto;
    width: 100%; }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_1 {
    padding: 18px 13px;
    padding-right: 0;
    width: 160px; }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span {
    font-size: 42px;
    width: 135px;
    margin-right: 6px;
    padding: 0 26px 0 36px;
    width: 100%; }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span:after {
    width: 17px; }
  section#sec4 .quiz_block .quiz__question .card.card__mini2 {
    margin-bottom: 15px; }
  section#sec3 {
    background-position: 0 -28px; }
  section#sec8 {
    background-position: 0 -77px;
    background-position: 0 -60px; }
    section#sec8 .quiz_questions form input[type=number] {
      width: 143px;
      width: 126px; }
    section#sec8 .quiz_questions form .quantity-arrow-plus {
      left: 235px;
      left: 218px; } }

@media screen and (max-width: 362px) {
  section#sec8 .quiz_questions form input[type=number] {
    width: 128px; }
  section#sec8 .quiz_questions form .quantity-arrow-plus {
    left: 219px; }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_1 {
    font-size: 14px;
    width: auto;
    line-height: 35px; }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span {
    line-height: 70px; } }

@media screen and (max-width: 362px) {
  section#page404 {
    height: 105vh;
    padding-top: 22vh; }
  section#sec4 .quiz_block .quiz__question .card img {
    height: 80px;
    width: 80px;
    width: auto;
    padding: 6px 0; }
  section#sec4 .quiz_block .quiz__question .card img {
    padding: 6px; }
  section#sec4 .quiz_block .quiz__question .card img + .card-body {
    height: 80px;
    padding-left: 0; }
    section#sec4 .quiz_block .quiz__question .card img + .card-body .card-text {
      padding: 8px 5px; }
  section#sec4 .quiz_block .quiz__question .card.card__mini2 img {
    width: auto;
    height: 99px;
    padding: 25px 20px; }
  section#sec4 .quiz_block .quiz__question .card.card__mini2 .card-text2 {
    height: 99px;
    font-size: 22px;
    width: auto; }
  section#sec4 .quiz_block .quiz__question {
    padding: 0; }
  section#sec4 .quiz_block .quiz_left form {
    margin: auto;
    width: auto;
    max-width: 100%;
    padding: 0 15px; }
  section#sec4 .quiz_block .quiz_left form h3 {
    font-size: 20px;
    line-height: 1.4; }
  section#sec8 .quiz_questions form .align-items-end .form-group label {
    height: 10px;
    line-height: 1;
    width: 100%; }
  section#sec8 .quiz_questions form .quantity-arrow-minus,
  section#sec8 .quiz_questions form .quantity-arrow-plus {
    top: 17px; }
  section#sec8 .quiz_questions form .quantity-arrow-minus {
    left: 7px; }
  section#sec8 .quiz_questions form .quantity-arrow-plus {
    left: 93px; }
  section#sec8 .quiz_questions form input[type=number] {
    width: 128px; }
  section#sec8 {
    background-position: 0 -45px; } }

@media screen and (max-width: 325px) {
  .block_thanks_main .error_page img {
    width: 240px; }
  .block_thanks_main .error_page h1 {
    font-size: 23px;
    margin: 10px 15px 15px; }
  .block_thanks_main .error_page p {
    font-size: 16px;
    padding: 0 15px; }
  section#sec8 {
    background-position: 0 -5px; }
  .quiz_left .test__kviz06--item {
    padding: 0 15px; } }

@media screen and (min-width: 470px) and (max-width: 480px) {
  header .header_contacts {
    padding: 25px 5px 10px; }
  header .header_mailblock a {
    font-size: 16px; }
  header .header_phoneblock a {
    font-size: 16px; }
  section#main h1 {
    font-size: 2.3rem; }
  section#main .slogan {
    font-size: 1.4rem; }
  section#main .test-drive_div span {
    width: 71px;
    font-size: 74px; }
  section#main .test-drive_div p {
    font-size: 1.3rem;
    padding-left: 71px; }
  section#main .advantage_cycles.d-md-none {
    font-size: 35px; } }

@media screen and (min-width: 480px) and (max-width: 640px) {
  #orderModal .modal-content {
    background-position: 3px 0;
    padding-left: 44px; }
  #orderModal .modal-content h3 {
    font-size: 2rem;
    margin: 70px 0 4px; }
  section#sec2 {
    padding: 50px 0 0;
    background-position: top center; }
    section#sec2 .advantage_right h3 {
      font-size: 1.5rem;
      line-height: 1.5;
      padding: 0;
      text-align: center;
      padding: 0 10px; }
    section#sec2 .adv_block:before {
      display: none; }
    section#sec2 .adv_item {
      padding-left: 20px; }
    section#sec2 .production-video.d-md-none .production-video__img p {
      padding-left: 20px;
      width: 120px;
      font-size: 12px; }
    section#sec2 .production-video.d-md-none .production-video__img .final-top-l__btn {
      width: 150px;
      height: 150px;
      margin: 48px auto 0px;
      width: 70px;
      height: 70px;
      margin: 90px auto; }
      section#sec2 .production-video.d-md-none .production-video__img .final-top-l__btn:before, section#sec2 .production-video.d-md-none .production-video__img .final-top-l__btn:after {
        width: 70px;
        height: 70px; }
    section#sec2 .production-start {
      margin: 55px 0 0; }
  section#sec3 {
    background-position: 0 -200px; }
    section#sec3 h2 {
      font-size: 1.5rem;
      text-align: center; }
      section#sec3 h2 br {
        display: none; }
      section#sec3 h2 + p {
        font-size: 1.2rem;
        line-height: 1.35;
        text-align: center; }
        section#sec3 h2 + p br {
          display: none; }
    section#sec3 .description {
      margin-left: 15px; }
    section#sec3 .description .desc {
      background-position: -18px -9px;
      background-size: 60px; }
      section#sec3 .description .desc span {
        line-height: 20px;
        width: 25px;
        font-size: 12px;
        margin-right: 15px; }
        section#sec3 .description .desc span:after {
          top: -5px;
          left: -2px; }
      section#sec3 .description .desc .desc-text {
        font-size: .85rem;
        font-family: "Uni Neue Regular"; }
    section#sec3 .description_block form {
      width: 300px;
      margin: 183px auto 40px; }
  section#sec4 {
    padding: 36px 0 54px; }
    section#sec4 h2 {
      line-height: 1.4;
      font-size: 1.5rem;
      text-align: center; }
      section#sec4 h2 br {
        display: none; }
  section#sec5 {
    padding: 65px 0 60px; }
    section#sec5 h2 {
      line-height: 1.4;
      font-size: 1.5rem;
      text-align: center;
      margin: 0; }
      section#sec5 h2 + p {
        margin: 35px 0 50px;
        font-size: 1.2rem; }
        section#sec5 h2 + p:before {
          padding-top: 20px; }
  section#sec6 {
    padding: 65px 0 183px; }
    section#sec6 .laserEngraving_block h2 {
      line-height: 1.4;
      font-size: 1.5rem;
      text-align: center; }
    section#sec6 .economy_block {
      width: 310px;
      margin: 60px auto 10px; }
      section#sec6 .economy_block span {
        font-size: 15.5px; }
      section#sec6 .economy_block .economy_value {
        font-size: 14px; }
      section#sec6 .economy_block .economy_text {
        font-size: 14px; }
        section#sec6 .economy_block .economy_text:before {
          width: 67px;
          padding-top: 20px; }
      section#sec6 .economy_block .free_engraving {
        font-size: 12px;
        width: 265px;
        margin: 25px 0 20px; }
    section#sec6 .bookingForm form {
      width: 240px;
      margin: auto;
      padding: 15px 0 15px; }
      section#sec6 .bookingForm form .form-group {
        margin-bottom: 16px; }
  section#sec7 h2 {
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.4; }
  section#sec7 .comments_block {
    height: 320px;
    height: 390px;
    float: none; }
  section#sec7 .slide-item {
    height: 320px;
    height: 340px; }
  section#sec7 .slick-dots {
    bottom: -65px; }
  section#sec7 .customers_count {
    padding: 25px 0 0;
    width: 315px;
    margin: auto;
    float: none; }
    section#sec7 .customers_count p {
      font-size: 10px;
      margin: 20px 0 0 168px; }
  section#sec7 .comments_logos {
    margin: 0px auto -44px;
    height: 200px; }
  section#sec7 .comments_logos {
    margin: 35px auto -15px; }
  section#sec7 .card_img_blk {
    height: 162px; }
    section#sec7 .card_img_blk img {
      width: 60%;
      margin: 20px auto 0;
      width: 70%;
      margin: 0 auto;
      text-align: center; }
  section#sec7 .card-img-overlay {
    top: 97px; }
  section#sec7 .card-body {
    top: 162px; }
  section#sec8 {
    padding: 50px 0 20px;
    padding: 50px 0 35px; }
    section#sec8 h2 {
      margin-bottom: 20px;
      font-size: 1.5rem;
      text-align: center;
      line-height: 1.4; }
    section#sec8 p {
      font-size: 16px; }
    section#sec8 .quiz_questions {
      margin: 2px auto 0;
      margin: -13px auto 0;
      padding: 15px 14px 12px;
      width: 350px;
      width: 365px; }
    section#sec8 .delivery_advantages {
      margin: 29px 0 20px;
      height: auto; }
      section#sec8 .delivery_advantages .delivery_advantages-item {
        margin: 0 5% 15px;
        width: 40%; }
        section#sec8 .delivery_advantages .delivery_advantages-item span {
          font-size: 39px; }
        section#sec8 .delivery_advantages .delivery_advantages-item p {
          font-size: 14px; }
        section#sec8 .delivery_advantages .delivery_advantages-item:nth-child(1), section#sec8 .delivery_advantages .delivery_advantages-item:nth-child(3) {
          width: 40%; }
    section#sec8 .result .table thead th {
      font-size: 12px; }
    section#sec8 .result .table td {
      font-size: 12px; }
      section#sec8 .result .table td a {
        font-size: 12px; }
    section#sec8 .quiz_questions form .align-items-end .form-group {
      text-align: center !important; }
      section#sec8 .quiz_questions form .align-items-end .form-group button[type=submit] {
        max-width: 230px; }
  section#sec9 {
    height: 561px; }
    section#sec9 .address_block {
      width: 300px;
      height: 330px;
      left: 50px;
      top: 160px;
      left: 0;
      top: 170px;
      margin: auto; } }

@media screen and (min-width: 640px) and (max-width: 768px) {
  .fullpage {
    height: auto;
    padding-bottom: 120px; }
  header .header_phoneblock span.menu2_open,
  header .header_phoneblock span.menu_open {
    margin: 0 0 0 99px; }
  section#sec2 {
    padding: 65px 0;
    background-position: top center; }
    section#sec2 .advantage_right h3 {
      font-size: 2rem;
      line-height: 1.2; }
    section#sec2 .adv_block {
      margin: 35px 0;
      margin: 35px 25px; }
    section#sec2 .production-video.d-md-none .production-video__img .final-top-l__btn {
      margin: 8rem auto 8rem;
      width: 70px;
      height: 70px; }
      section#sec2 .production-video.d-md-none .production-video__img .final-top-l__btn:before, section#sec2 .production-video.d-md-none .production-video__img .final-top-l__btn:after {
        width: 70px;
        height: 70px; }
    section#sec2 .production-start .prodstart__right {
      font-size: .85rem;
      line-height: 1.5; }
  section#sec3 {
    background-position: 0 -70px;
    padding: 60px 0;
    background-position: 0 -35px;
    padding: 65px 0 147px; }
    section#sec3 h2 {
      font-size: 2rem;
      line-height: 1.2; }
      section#sec3 h2 + p {
        font-size: 1.3rem;
        line-height: 1.35; }
    section#sec3 .send-catalog_form form {
      margin: 45px 0 60px; }
    section#sec3 .description {
      display: none; }
      section#sec3 .description.description_vision640 {
        display: block !important;
        margin-top: 35px; }
  section#sec4 {
    padding: 65px 0 65px; }
    section#sec4 h2 {
      font-size: 2rem;
      line-height: 1.2; }
    section#sec4 .quiz_block {
      margin-top: 60px;
      margin-bottom: 17px; }
      section#sec4 .quiz_block .progress__label {
        font-size: .95rem;
        padding-top: 20px;
        padding-bottom: 20px; }
      section#sec4 .quiz_block .question__title {
        font-size: 1.5rem; }
      section#sec4 .quiz_block .quiz__question {
        padding-left: 10px;
        padding-right: 10px; }
        section#sec4 .quiz_block .quiz__question .card {
          margin-bottom: 10px; }
        section#sec4 .quiz_block .quiz__question .card-body .card-text {
          font-size: 14px;
          font-size: 10px; }
  section#sec5 {
    padding: 88px 0 241px;
    padding: 88px 0 60px; }
    section#sec5 h2 {
      font-size: 2rem;
      line-height: 1.2; }
      section#sec5 h2 + p {
        margin: 35px 0 50px;
        margin: 40px 0 65px;
        font-size: 1.5rem; }
        section#sec5 h2 + p:before {
          width: 50px;
          padding-top: 25px; }
  section#sec6 {
    padding: 110px 0 216px; }
    section#sec6 .laserEngraving_block h2 {
      font-size: 2rem;
      line-height: 1.2; }
    section#sec6 .economy_block {
      margin: 80px auto 40px; }
      section#sec6 .economy_block .free_engraving {
        font-size: 14px;
        margin: 35px 0 20px; }
  section#sec7 {
    padding: 60px 0 63px; }
    section#sec7 h2 {
      font-size: 2rem;
      line-height: 1.2; }
    section#sec7 .comments_block {
      margin: 35px auto; }
    section#sec7 .comments_logos {
      margin: 10px auto -37px;
      height: 250px; }
  section#sec7 .comments_block {
    width: 100%; }
  section#sec8 {
    padding: 75px 0 86px; }
    section#sec8 h2 {
      font-size: 2rem;
      line-height: 1.2; }
    section#sec8 p {
      font-size: 18px; }
    section#sec8 .delivery_advantages {
      margin: 40px 0 40px; }
      section#sec8 .delivery_advantages .delivery_advantages-item {
        width: 45%;
        margin: 0 2.5% 15px;
        padding-left: 48px;
        background-size: 80px;
        background-position: -18px 2px; }
        section#sec8 .delivery_advantages .delivery_advantages-item span {
          font-size: 55px;
          margin-top: 0; }
        section#sec8 .delivery_advantages .delivery_advantages-item p {
          font-size: 18px;
          padding: 0;
          display: flex;
          align-items: center;
          line-height: 1;
          height: 82px; }
        section#sec8 .delivery_advantages .delivery_advantages-item:nth-child(1), section#sec8 .delivery_advantages .delivery_advantages-item:nth-child(3) {
          width: 45%; }
    section#sec8 .quiz_questions {
      width: 380px;
      width: 450px;
      margin: 0 auto 0; }
  section#sec9 {
    height: 720px; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  section#main {
    padding-top: 10vh; }
    section#main .patent_block {
      font-size: 1.0rem;
      height: 44px;
      background-size: 32px;
      display: none; }
    section#main .test-drive_div {
      display: block !important;
      margin-right: 0;
      float: left !important; }
      section#main .test-drive_div.d-md-none {
        display: none !important; }
    section#main .test-drive_form {
      margin-top: -150px; }
    section#main .slogan br {
      display: none; }
    section#main .main-left {
      margin: 50px 0; }
  .advantage_right {
    background-position: 250px 260px;
    background-size: 500px; }
  section#sec2 .production_process {
    display: none; }
  section#sec3 {
    padding: 85px 0 100px;
    background-position: -505px 0;
    background-position: -660px 0; }
    section#sec3 h2 {
      line-height: 1.2;
      font-size: 2.25rem; }
      section#sec3 h2 br {
        display: none; }
      section#sec3 h2 + p {
        line-height: 1.35;
        font-size: 1.45rem; }
        section#sec3 h2 + p br {
          display: none; }
    section#sec3 .send-catalog_form form {
      margin: 45px 0 140px;
      margin: 55px 0 250px;
      margin: 55px 30px 250px; }
  .description .desc {
    height: auto;
    background-position: 1px 0px; }
    .description .desc.desc1, .description .desc.desc2 {
      margin-top: 0; }
  section#sec4 {
    padding: 5px 0 65px;
    margin-top: -100px; }
    section#sec4 h2 {
      line-height: 1.2;
      font-size: 2.25rem; }
      section#sec4 h2 br {
        display: none; }
    section#sec4 .quiz_block {
      height: auto; }
      section#sec4 .quiz_block .quiz_left {
        width: 100%;
        float: none; }
      section#sec4 .quiz_block .question__title {
        font-size: 2.0rem;
        padding: 55px 0 30px; }
      section#sec4 .quiz_block .progress__label {
        font-size: 1.65rem; }
      section#sec4 .quiz_block .quiz__question .card {
        margin-bottom: 30px; }
      section#sec4 .quiz_block .quiz__question .card img {
        width: 100%;
        padding: 25px; }
  section#sec5 {
    padding: 88px 0 30px; }
    section#sec5 h2 {
      line-height: 1.2;
      font-size: 2.25rem; }
      section#sec5 h2 br {
        display: none; }
    section#sec5 .col-1-5, section#sec5 .col-lg-1-5, section#sec5 .col-md-1-5, section#sec5 .col-sm-1-5, section#sec5 .col-xs-1-5 {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      width: 50%; }
    section#sec5 .card {
      margin-bottom: 30px; }
      section#sec5 .card .card-img-top {
        padding: 1.25rem;
        padding: 2rem 5rem 0; }
      section#sec5 .card span {
        width: 50px;
        height: 50px;
        top: -20px;
        right: -20px;
        line-height: 50px;
        font-size: .95rem; }
  section#sec6 {
    padding: 110px 0 285px;
    padding: 110px 0 345px; }
    section#sec6 .laserEngraving_block h2 {
      line-height: 1.2;
      font-size: 2.25rem;
      text-align: center; }
      section#sec6 .laserEngraving_block h2 br {
        display: none; }
      section#sec6 .laserEngraving_block h2 span {
        font-size: 2.25rem; }
    section#sec6 .economy_block {
      margin: 120px 100px 40px;
      margin-bottom: 0; }
      section#sec6 .economy_block .economy_text {
        font-size: 1.0rem; }
      section#sec6 .economy_block .free_engraving {
        font-size: 1.0rem;
        margin: 35px 0 20px;
        background-position: 13px 110px;
        background-position: 13px 90px;
        margin: 0px 0 20px;
        line-height: 1.3; }
    section#sec6 .bookingForm form {
      margin: auto;
      padding: 0;
      margin-left: 80px; }
  section#sec7 {
    padding: 85px 0 70px; }
    section#sec7 h2 {
      line-height: 1.2;
      font-size: 2.25rem;
      text-align: center; }
      section#sec7 h2 br {
        display: none; }
    section#sec7 .comments_block {
      margin: 56px auto;
      width: 100%;
      /* height: 570px; */ }
    section#sec7 .slide-item {
      min-width: 690px;
      max-width: 690px;
      height: 370px; }
    section#sec7 .customers_count {
      float: none;
      clear: both;
      margin: 56px auto 0;
      width: 500px;
      height: 220px; }
    section#sec7 .comments_logos {
      margin: 0 auto;
      height: 280px;
      background: url(../img/comments_logos_320.png) no-repeat;
      background-size: contain;
      background-position: 50%; }
    section#sec7 .customers_count {
      height: 320px; }
      section#sec7 .customers_count span {
        float: left; }
      section#sec7 .customers_count p {
        float: left;
        margin: 7px 0 0 25px; }
        section#sec7 .customers_count p:before {
          display: none; }
    section#sec7 .card-body img {
      display: none; }
    section#sec7 .card-body p {
      padding-left: 50px; }
  section#sec8 {
    padding: 85px 0 85px;
    background: #ccc url(../img/section_08_320.jpg) no-repeat;
    background-size: cover; }
    section#sec8 h2 {
      line-height: 1.2;
      font-size: 2.25rem;
      text-align: center; }
    section#sec8 p {
      font-size: 20px;
      text-align: center; }
    section#sec8 .delivery_advantages {
      height: 210px; }
    section#sec8 .quiz_questions form .form-group.text-right {
      margin: 15px auto;
      text-align: center !important; }
    section#sec8 .quiz_questions form input[type=number] {
      width: 200px; }
    section#sec8 .quiz_questions form .quantity-arrow-plus {
      right: 0;
      left: 159px; }
    section#sec8 .quiz_questions .result .table td {
      padding: 4px 10px 2px; }
  section#sec9 .address_block {
    top: 160px; }
  footer .navbar-dark .navbar-brand {
    display: flex; }
  footer .navbar-dark .navbar-nav .nav-link {
    padding: .5rem 1rem; }
  footer .socials-list {
    padding: 0; }
  footer .socials {
    margin: 2px; } }

@media screen and (min-width: 962px) and (max-width: 992px) {
  .menu2 {
    width: auto;
    padding-left: 85px; }
  .menu2 .nav-link {
    font-size: 1.2rem !important;
    padding-bottom: 0.65rem; }
  section#main {
    padding-top: 125px;
    padding-top: 155px;
    padding-bottom: 40px; }
    section#main h1 {
      font-size: 2.0rem;
      text-align: center; }
      section#main h1 br {
        display: none; }
    section#main .slogan {
      font-size: 1.4rem;
      text-align: center; }
      section#main .slogan br {
        display: none; }
    section#main .main-left {
      margin-top: 25px; }
    section#main .advantage_cycles {
      font-size: 2rem;
      margin: 3rem 8px 3rem; }
    section#main .patent_block {
      display: none; }
    section#main .test-drive_div {
      float: left !important; }
    section#main .test-drive_form {
      margin-right: 0;
      margin-top: -150px; } }

@media screen and (min-width: 992px) and (max-width: 1279px) {
  .advantage_right {
    background-position: 160px 300px;
    background-size: 400px;
    background-position: 210px 380px; }
  header .navbar-nav .nav-link {
    padding-right: 0.95rem !important;
    padding-left: 0.95rem !important; }
  section#main {
    padding-top: 14vh; }
    section#main h1 {
      font-size: 2.6rem;
      text-align: center; }
      section#main h1 br {
        display: none; }
    section#main .slogan {
      font-size: 1.5rem;
      text-align: center; }
    section#main .advantage_cycles {
      font-size: 2.6rem; }
      section#main .advantage_cycles:before {
        width: 55px; }
    section#main .test-drive_div {
      float: left !important; }
    section#main .test-drive_div span {
      font-size: 115px;
      line-height: 85px; }
    section#main .main-left {
      min-width: 100%; }
    section#main .main-right {
      min-width: 100%; }
    section#main .patent_block {
      display: none; }
    section#main .test-drive_form {
      margin-right: 10px;
      margin-top: -150px; }
  section#sec2 .advantage_right h3 {
    font-size: 1.5rem;
    line-height: 1.3; }
  section#sec3 {
    background-position: 62% bottom; }
  section#sec4 .quiz_block .quiz_right {
    display: none !important; }
  section#sec4 .quiz_block .quiz_left {
    width: 100%; }
  section#sec7 h2 {
    text-align: center; }
  section#sec7 .comments_block {
    margin: 56px auto;
    float: none; }
  section#sec7 .customers_count {
    margin: 56px auto 0;
    float: none;
    padding-bottom: 0;
    height: 140px;
    width: 455px; }
    section#sec7 .customers_count span {
      font-size: 3.6rem;
      float: left; }
    section#sec7 .customers_count p {
      margin: 3px 0 3px 20px;
      display: block;
      float: left; }
    section#sec7 .customers_count p:before {
      display: none; }
  section#sec7 .comments_block {
    margin: 56px auto 115px; }
  section#sec7 .slick-dots {
    bottom: -95px; }
  section#sec8 {
    background-position: 65% bottom;
    background-position: 52% bottom; }
    section#sec8 .quiz_questions {
      margin: 35px 0;
      width: 400px; }
    section#sec8 .delivery_advantages {
      margin: 25px 5% 20px;
      width: 90%;
      height: 490px; }
      section#sec8 .delivery_advantages .delivery_advantages-item {
        background-position: -13px 14px;
        padding-left: 45px; }
        section#sec8 .delivery_advantages .delivery_advantages-item span {
          padding-right: 15px; }
  section#sec9 .address_block {
    width: 405px; }
    section#sec9 .address_block h2 {
      font-size: 1.8rem;
      text-align: center; }
  footer .navbar-dark .navbar-nav .nav-link {
    padding: .5rem 1rem; } }

@media screen and (min-width: 1280px) and (max-width: 1365px) {
  section#main {
    padding-top: 17vh; }
  .fullpage {
    background-position: 55% bottom; }
  section#sec3 {
    background-position: 0 -65px;
    background-position: 43% bottom;
    padding: 85px 0 200px; }
  section#sec8 {
    background-position: 60% bottom; } }

@media screen and (min-width: 1366px) {
  section#sec3 {
    background-position: 54% bottom;
    padding: 80px 0 187px;
    padding: 80px 0 186px; }
  section#sec8 {
    background-position: 60% bottom; }
  section#sec9 .address_block {
    width: 400px; } }

@media screen and (max-width: 1028px) and (min-width: 1018px) {
  section#main {
    padding-top: 11vh; }
  section#sec2 .advantage_right {
    background-position: 240px 360px; }
  section#sec9 .address_block {
    top: 213px;
    left: calc(50% - 215px); }
  .fullpage {
    height: auto;
    padding-bottom: 20px; } }

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  section#main {
    padding-top: 21vh; } }

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  section#sec3 {
    background-position: 0 -116px; }
  section#sec8 {
    background-position: 0 -115px; }
  section#sec7 .slide-item {
    height: 360px; }
  section#sec7 .customers_count {
    height: 155px;
    margin: auto;
    width: 300px;
    float: none;
    clear: both; } }

/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  section#main {
    padding-top: 19vh; }
    section#main h1 {
      font-size: 1.75rem; }
    section#main .slogan {
      font-size: 1.4rem; }
    section#main .test-drive_div span {
      width: 71px;
      font-size: 74px; }
    section#main .test-drive_div p {
      font-size: 1.3rem;
      padding-left: 71px; }
    section#main .advantage_cycles.d-md-none {
      margin: 4rem 0 3rem;
      font-size: 35px; }
  section#sec7 .customers_count {
    height: 200px;
    height: 155px; } }

@media screen and (min-width: 641px) and (max-width: 769px) and (max-height: 1060px) {
  .fullpage {
    height: auto; }
  section#main {
    padding-top: 125px;
    padding-bottom: 40px; }
  section#sec9 .address_block {
    top: 212px;
    left: calc(50% - 215px); } }

@media screen and (orientation: portrait) and (min-width: 641px) and (max-width: 767px) {
  section#sec3 {
    background-position: -272px 0px; }
  section#sec3 h2 {
    text-align: center; }
    section#sec3 h2 br {
      display: none; }
    section#sec3 h2 + p br {
      display: none; }
  section#sec3 .description.description_vision640 {
    display: block !important; }
  .description .desc.desc1, .description .desc.desc2 {
    margin-top: 0; }
  .description .desc {
    height: auto;
    background-position: 1px 0; } }

@media screen and (orientation: landscape) and (max-width: 960px) {
  section#pageThanks {
    padding-top: 129px;
    height: 106vh; }
  section#page404 {
    padding-top: 15vh;
    height: 105vh; }
  .catalog_slider .card {
    margin: 0;
    margin-bottom: 22px; }
  .fullpage {
    height: auto; }
  header .header_contacts {
    padding: 0px 12px 35px; }
  section#main {
    padding-top: 125px;
    padding-bottom: 40px;
    padding-top: 50px !important;
    padding-top: 105px !important; }
    section#main h1 {
      font-size: 2.0rem; }
      section#main h1 br {
        display: none; }
    section#main .slogan {
      font-size: 1.5rem; }
      section#main .slogan br {
        display: none; }
    section#main .advantage_cycles {
      font-size: 2.0rem; }
    section#main .test-drive_div {
      float: left !important; }
    section#main .patent_block {
      display: none !important; }
    section#main .test-drive_form {
      margin-right: 0;
      margin-top: -150px;
      margin-top: -215px;
      margin-top: -335px; }
    section#main .main-left {
      margin-top: 25px; }
    section#main .test-drive_div {
      display: none !important; }
      section#main .test-drive_div.d-md-none {
        display: block !important; }
        section#main .test-drive_div.d-md-none p {
          font-size: 1.1rem; }
  .menu2 {
    width: 400px; }
    .menu2 .nav-link {
      font-size: 1rem !important;
      padding-bottom: 1.2rem; }
  section#sec3 .description {
    display: none !important; }
    section#sec3 .description.description_vision640 {
      display: block !important; }
  section#sec7 .customers_count {
    height: auto; }
  .quiz_questions form .quantity-arrow-minus,
  .quiz_questions form .quantity-arrow-plus {
    top: 30px; } }

@media screen and (orientation: landscape) and (max-width: 820px) {
  .block_thanks_main .error_page img {
    width: 250px; }
  .block_thanks_main .error_page h1 {
    font-size: 26px;
    margin: 15px 0 5px;
    line-height: 1.45; }
  .block_thanks_main .error_page p {
    font-size: 21px;
    line-height: 1.55;
    margin-bottom: 30px; }
  section#pageThanks {
    padding-top: 78px;
    height: 106vh; } }

@media screen and (orientation: landscape) and (min-width: 810px) and (max-width: 815px) {
  section#pageThanks {
    padding-top: 25px;
    height: 106vh; }
  section#page404 {
    padding-top: 19vh;
    height: 106vh; }
    section#page404 .error_page .btn.btn-yellow {
      display: none; }
  section#sec3 {
    background-position: -450px -60px; } }

@media screen and (orientation: landscape) and (min-width: 665px) and (max-width: 686px) {
  .fullpage.fullpage--404 {
    padding-bottom: 60px; }
  section#pageThanks {
    padding-top: 10px;
    padding-top: 17px;
    height: 106vh; }
  section#page404 {
    padding-top: 9vh;
    height: 107vh;
    padding-top: 15vh;
    height: 110vh; }
  .block_thanks_main .error_page h1 {
    font-size: 23px;
    margin: 15px 0 5px;
    line-height: 1.45; }
  .block_thanks_main .error_page p {
    font-size: 19px;
    line-height: 1.55;
    margin-bottom: 30px; }
  .fullpage {
    background-position-x: -189px; }
  .advantage_right {
    background: none; }
  .adv_item:nth-child(3) {
    padding-left: 0; }
  .adv_item:nth-child(5) {
    display: none; }
  section#sec2 .advantage_right h3 {
    padding-left: 5px;
    text-align: center; }
  .adv_block:before {
    display: none; }
  .description .desc {
    height: auto;
    background-position: 1px 1px; }
  .desc1,
  .desc2 {
    margin-top: 0; }
  section#sec3 {
    padding: 65px 0 160px;
    background-position: 0 0;
    background-position: 14% 0;
    background-position: 13% 0; }
    section#sec3 h2 {
      text-align: center; }
      section#sec3 h2 br {
        display: none; }
      section#sec3 h2 + p {
        text-align: center; }
        section#sec3 h2 + p br {
          display: none; }
    section#sec3 .description.description_vision640 {
      margin: 25px auto; }
    section#sec3 .send-catalog_form form {
      margin: 45px auto 60px; }
  .production-start {
    margin: 55px auto 0; }
  section#sec4 {
    margin-top: -160px; }
    section#sec4 h2 br {
      display: none; }
    section#sec4 .quiz_block {
      height: auto;
      min-height: unset; }
      section#sec4 .quiz_block .quiz_left {
        width: 100%;
        float: none;
        clear: both; }
      section#sec4 .quiz_block .progress {
        width: 100%; }
      section#sec4 .quiz_block .question__title {
        padding: 30px 0 10px; }
  .quiz_questions form .form-group {
    text-align: center !important; }
    .quiz_questions form .form-group button[type=submit] {
      margin-top: 15px; }
  section#sec5 {
    padding: 88px 0 88px; }
  section#sec6 {
    background-position-x: 54.5%; }
    section#sec6 .laserEngraving_block h2 {
      text-align: center; }
      section#sec6 .laserEngraving_block h2 br:first-child {
        display: none; }
    section#sec6 .bookingForm {
      margin-bottom: 50px; }
    section#sec6 .economy_block {
      margin: 80px auto 0px; }
  section#sec7 h2 {
    text-align: center; }
    section#sec7 h2 br {
      display: none; }
  section#sec7 .slide-item {
    min-width: 500px;
    max-width: 500px;
    max-width: 100%; }
  section#sec7 .card-body {
    padding-left: 255px;
    padding-right: 25px; }
    section#sec7 .card-body img {
      display: none; }
    section#sec7 .card-body h5 {
      font-size: 1.45rem;
      padding: 0 0 10px;
      padding-left: 0; }
    section#sec7 .card-body button[type=button] {
      display: none; }
  section#sec7 .customers_count {
    width: 230px;
    float: none;
    clear: both;
    height: 330px;
    padding: 80px 0 0;
    margin: 95px auto 0; }
  section#sec7 .comments_logos {
    margin: 20px auto 0px;
    height: 220px;
    background: url(../img/comments_logos_320.png) no-repeat;
    background-size: contain;
    background-position: center; }
  section#sec8 {
    background-position: 70% 0;
    background: #333539 url(../img/section_08_320.jpg) no-repeat;
    background-size: cover;
    background-position: 100% 21%; }
    section#sec8 h2 {
      text-align: center; }
      section#sec8 h2 br {
        display: none; }
      section#sec8 h2 + p {
        text-align: center; }
    section#sec8 .quiz_questions form .form-row.align-items-end .form-group:first-child {
      flex: 0 0 50%;
      flex: 0 0 100%; }
      section#sec8 .quiz_questions form .form-row.align-items-end .form-group:first-child + .form-group {
        text-align: center !important;
        margin: 20px auto; }
  section#sec9 .address_block {
    top: 212px;
    left: calc(50% - 215px); }
  .navbar-dark .navbar-brand {
    width: 100%;
    margin: auto;
    padding: 25px 0 20px;
    text-align: center; }
  footer .footer_menu {
    padding: 0; }
  footer .navbar-dark .navbar-nav .nav-link {
    text-align: center; }
  .socials-list {
    padding: 25px 0;
    text-align: center; }
  #test__kviz01 .row .col-12,
  #test__kviz03 .row .col-12 {
    flex: 0 0 50%; }
  section#sec4 .quiz_block .quiz__question .card {
    margin-bottom: 25px; }
  section#sec4 .quiz_block .quiz_left form {
    margin-left: 15px;
    margin-right: 15px; }
    section#sec4 .quiz_block .quiz_left form h3 br {
      display: none; }
  section#sec4 h2 {
    font-size: 26px; }
    section#sec4 h2 br {
      display: none; }
  .quiz_questions form input[type=number] {
    width: 175px;
    margin: auto; }
  .quiz_questions form .quantity-arrow-minus {
    left: 120px; }
  .quiz_questions form .quantity-arrow-plus {
    right: 118px; }
  section#sec7 .card-body p {
    padding-left: 0; } }

@media screen and (orientation: landscape) and (min-width: 733px) and (max-width: 738px) {
  section#pageThanks {
    padding-top: 18px;
    height: 106vh;
    padding-top: 0px;
    height: 108vh; }
  section#page404 {
    padding-top: 15vh;
    height: 122vh;
    padding-top: 8vh;
    height: 127vh;
    padding-top: 11vh;
    height: 138vh; }
  section#sec4 .quiz_block .quiz__question .card.card__mini2 .card-text2 {
    line-height: 90px; }
  section#sec7 .customers_count {
    margin-bottom: 0; }
  .fullpage {
    background-position-x: -189px; }
  .advantage_right {
    background: none; }
    .advantage_right h3 {
      padding-left: 5px;
      text-align: center; }
  .adv_item:nth-child(3) {
    padding-left: 0; }
  .adv_item:nth-child(5) {
    display: none; }
  .adv_block:before {
    display: none; }
  .production-start {
    margin: 80px auto 0; }
  section#sec3 {
    background-position: -110px 48px;
    padding: 120px 0 147px;
    background-position-x: -140px; }
    section#sec3 h2 {
      text-align: center; }
      section#sec3 h2 br {
        display: none; }
      section#sec3 h2 + p {
        text-align: center; }
    section#sec3 .description.description_vision640 {
      margin-top: 15px; }
    section#sec3 .send-catalog_form form {
      margin: 45px auto 60px; }
  .desc1,
  .desc2 {
    margin-top: 0; }
  .description .desc {
    background-position: 1px 1px;
    height: auto; }
  section#sec4 {
    margin-top: -135px; }
    section#sec4 .quiz_block {
      height: auto;
      min-height: auto; }
      section#sec4 .quiz_block .quiz_left {
        width: 100%;
        float: none;
        clear: both; }
      section#sec4 .quiz_block .progress {
        width: 100%; }
      section#sec4 .quiz_block .question__title {
        padding: 25px 0 10px; }
      section#sec4 .quiz_block .quiz__question {
        padding-left: 20px;
        padding-right: 20px; }
        section#sec4 .quiz_block .quiz__question h3 br {
          display: none; }
        section#sec4 .quiz_block .quiz__question .card img {
          width: 50%;
          height: auto;
          margin: auto;
          width: 100%; }
    section#sec4 h2 {
      font-size: 26px; }
      section#sec4 h2 br {
        display: none; }
  .quiz_questions form input[type=number] {
    width: 175px;
    margin: auto; }
  .quiz_questions form .quantity-arrow-minus {
    left: 120px; }
  .quiz_questions form .quantity-arrow-plus {
    right: 118px; }
  section#sec6 {
    background-position-x: 55%; }
    section#sec6 .laserEngraving_block h2 {
      text-align: center; }
    section#sec6 .bookingForm form {
      margin: -80px 0 60px; }
  section#sec7 .slide-item {
    min-width: 500px;
    max-width: 100%;
    height: 358px; }
  section#sec7 h2 {
    text-align: center; }
    section#sec7 h2 br {
      display: none; }
  section#sec7 .card-body {
    padding-left: 255px;
    padding-right: 25px; }
    section#sec7 .card-body img {
      display: none; }
    section#sec7 .card-body button[type=button] {
      display: none; }
    section#sec7 .card-body p {
      padding-left: 0; }
    section#sec7 .card-body h5 {
      padding: 0;
      text-align: center;
      font-size: 1.35rem; }
  section#sec7 .card_img_blk {
    height: 358px;
    height: 356px; }
  section#sec7 .customers_count {
    height: 330px;
    width: 500px;
    text-align: center;
    padding: 53px 61px; }
    section#sec7 .customers_count p:before {
      margin: auto; }
  section#sec7 .comments_logos {
    height: 40px; }
  section#sec8 {
    background: #ccc url(../img/section_08_320.jpg) no-repeat;
    background-size: cover;
    background-position: 0 -165px; }
    section#sec8 h2 br {
      display: none; }
  section#sec9 .address_block {
    top: 210px;
    left: calc(50% - 215px); }
  .quiz_questions form .form-group {
    text-align: center !important; }
    .quiz_questions form .form-group button[type=submit] {
      margin-top: 15px; }
  .quiz_questions form .form-row .form-group:nth-child(2) {
    text-align: center !important;
    margin-top: 15px; }
  .navbar-dark .navbar-brand {
    width: 100%;
    text-align: center;
    padding: 20px 0; }
  footer .footer_menu {
    padding: 0px 0 25px; }
  footer .navbar-dark .navbar-nav .nav-link {
    text-align: center; }
  .socials-list {
    padding: 30px 0 0;
    text-align: center; } }

@media screen and (orientation: portrait) and (min-width: 411px) and (max-width: 417px) {
  .quiz_left .test__kviz06--item {
    padding: 0; }
  #orderModal .modal-content {
    background-position: -38px 0; }
  section#sec4 .quiz_block .quiz__question .card.card__mini2 {
    margin-bottom: 15px; }
  section#sec4 .quiz_block .quiz__question .card.card__mini3 .card-body3 .card-text3_2 span {
    font-size: 40px; }
  section#sec4 .quiz_block .quiz_left form {
    width: 100%;
    margin: auto; } }

@media screen and (orientation: portrait) and (width: 768px) {
  section#main {
    padding-top: 125px !important;
    padding-top: 80px !important; }
    section#main .test-drive_form {
      float: right !important;
      margin: -150px auto 0 !important; }
  section#sec7 .comments_block {
    margin: 56px auto 115px; }
  section#sec7 .slick-dots {
    bottom: -95px; }
  section#sec7 .customers_count {
    height: 210px; } }

@media screen and (orientation: landscape) and (min-width: 635px) and (max-width: 645px) {
  .fullpage.fullpage--404 {
    padding-bottom: 60px; }
  section#page404 {
    padding-top: 21vh;
    height: 120vh; }
  .block_thanks_main .error_page h1 {
    font-size: 22px;
    margin: 15px 0 5px;
    line-height: 1.25; }
  .block_thanks_main .error_page p {
    font-size: 16px;
    line-height: 1.55;
    margin-bottom: 30px; } }

@media screen and (orientation: landscape) and (max-width: 570px) {
  section#sec4 .quiz_block .quiz__question .card.card__mini2 img {
    width: auto; }
  section#pageThanks {
    padding-top: 0px;
    height: 106vh; }
    section#pageThanks .block_thanks_main h1 {
      font-size: 35px; }
    section#pageThanks .block_thanks_main p {
      font-size: 18px; } }

@media screen and (orientation: landscape) and (max-width: 486px) {
  section#pageThanks {
    padding-top: 13px;
    height: 117vh; } }
