// Переопределение дефолтных значений переменных Bootstrap 4 и определение своих
@import "variables";

// Подключение нужных SCSS исходников Bootstrap 4
////@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../../node_modules/bootstrap/scss/bootstrap";
////@import "../../../node_modules/bootstrap/scss/bootstrap-reboot";
////@import "../../../node_modules/bootstrap/scss/bootstrap-grid";
////@import "../../../node_modules/bootstrap/scss/_root";

// @import "../../../node_modules/bootstrap/scss/_functions";
// @import "../../../node_modules/bootstrap/scss/_variables";
// @import "../../../node_modules/bootstrap/scss/_mixins";
// @import "../../../node_modules/bootstrap/scss/_print";
// @import "../../../node_modules/bootstrap/scss/_reboot";
// @import "../../../node_modules/bootstrap/scss/_type";
// @import "../../../node_modules/bootstrap/scss/_images";
// @import "../../../node_modules/bootstrap/scss/_code";
// @import "../../../node_modules/bootstrap/scss/_grid";
// @import "../../../node_modules/bootstrap/scss/_tables";
// @import "../../../node_modules/bootstrap/scss/_forms";
// @import "../../../node_modules/bootstrap/scss/_buttons";
// @import "../../../node_modules/bootstrap/scss/_transitions";
// @import "../../../node_modules/bootstrap/scss/_dropdown";
// @import "../../../node_modules/bootstrap/scss/_button-group";
// @import "../../../node_modules/bootstrap/scss/_input-group";
// @import "../../../node_modules/bootstrap/scss/_custom-forms";
// @import "../../../node_modules/bootstrap/scss/_nav";
// @import "../../../node_modules/bootstrap/scss/_navbar";
// @import "../../../node_modules/bootstrap/scss/_card";
// @import "../../../node_modules/bootstrap/scss/_breadcrumb";
// @import "../../../node_modules/bootstrap/scss/_pagination";
// @import "../../../node_modules/bootstrap/scss/_badge";
// @import "../../../node_modules/bootstrap/scss/_jumbotron";
// @import "../../../node_modules/bootstrap/scss/_alert";
// @import "../../../node_modules/bootstrap/scss/_progress";
// @import "../../../node_modules/bootstrap/scss/_media";
// @import "../../../node_modules/bootstrap/scss/_list-group";
// @import "../../../node_modules/bootstrap/scss/_close";
// @import "../../../node_modules/bootstrap/scss/_modal";
// @import "../../../node_modules/bootstrap/scss/_tooltip";
// @import "../../../node_modules/bootstrap/scss/_popover";
// @import "../../../node_modules/bootstrap/scss/_carousel";
// @import "../../../node_modules/bootstrap/scss/_utilities";


// Подключение сторонних SCSS файлов
@import "../../../node_modules/jquery-ui-dist/jquery-ui.min.css";
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../../node_modules/fancybox/dist/scss/jquery.fancybox.scss";
@import "../../../node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";


// Подключение своих SCSS файлов
@import "base";
@import "screen";

@import "override.css";
