.card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 5;
  }
  @include media-breakpoint-only(xl) {
    column-count: 5;
  }
}
.modal-dialog {
  //max-width: 500px;
  // max-width: 497px;
}
.col-1-5,
.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5 {
  position: relative;
  min-height: 1px;
  padding-right: 11px;
  padding-left: 11px;
}
.col-xs-1-5 {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-1-5 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-1-5 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-1-5 {
    width: 20%;
    float: left;
  }
}

@import "fonts";

$font1: "Uni Neue Regular";
$color_fon: #faf9f7;
// $color_base: #343434;
$color_base: #333;

body {
  min-width: 100%;
  min-width: 320px;
  width: 100%;
  display: inline-block;
  display: block;
  color: $color_base;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-family: $font1;
  font-weight: normal;
  font-size: 16px;
}

.form-control {
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.18);
}
/* keyframes ------------------------------------------*/
// @-webkit-keyframes circle {
//   0% {
//     -webkit-transform: scale(0.5);
//     transform: scale(0.5);
//   }
//   100% {
//     -webkit-transform: scale(1.75);
//     transform: scale(1.75);
//     opacity: 0;
//   }
// }
// @keyframes circle {
//   0% {
//     -webkit-transform: scale(0.5);
//     transform: scale(0.5);
//   }
//   100% {
//     -webkit-transform: scale(1.75);
//     transform: scale(1.75);
//     opacity: 0;
//   }
// }

@-webkit-keyframes circle {
  0% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
  100% {
    -webkit-transform: scale(3.275);
    transform: scale(3.275);
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
  100% {
    -webkit-transform: scale(3.275);
    transform: scale(3.275);
    opacity: 0;
  }
}

@-webkit-keyframes circle2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scaleX(1.25) scaleY(1.85);
    transform: scaleX(1.25) scaleY(1.85);
    opacity: 0;
  }
}
@keyframes circle2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scaleX(1.25) scaleY(1.85);
    transform: scaleX(1.25) scaleY(1.85);
    opacity: 0;
  }
}

@-webkit-keyframes circle3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scaleX(1.2) scaleY(1.5);
    transform: scaleX(1.2) scaleY(1.5);
    opacity: 0;
  }
}
@keyframes circle3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scaleX(1.2) scaleY(1.5);
    transform: scaleX(1.2) scaleY(1.5);
    opacity: 0;
  }
}
.modal-backdrop.show {
  opacity: .85;
}
// .ymaps-2-1-69-map-copyrights-promo,
// .ymaps-2-1-69-copyright{
//   display: none!important;
// }
ymaps [class$="-map-copyrights-promo"],
ymaps [class$="-copyright_logo_no"]{
  display: none!important;
}
.catalog.catalog_slider{
  .col-1-5:focus{
    outline: none;
  }
}

.modal-content {
  background-color: #000;
  border: 0px solid #000;
}
.navbar-light .navbar-toggler {
  outline: none;
}
// ==============================================
.btn-yellow {
  color: #000;
  background-color: #fbd255;
  border-color: #fbd255;
  border-radius: 10px;
  // padding: .75rem 1.3rem .95rem;
  // padding: .75rem .75rem .95rem;
  // padding: .75rem 1.3rem .95rem;
  padding: .75rem 1.3rem .75rem;
  transition: all 0.25s linear;
  background: radial-gradient(ellipse at center, #fcda61 1%, #fabc2f 100%);
  background: linear-gradient(-76deg, #ffae00 0%,#fbd761 100%);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.29);
  box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
  box-shadow: 0 2px 0 0 #c3880a,1px 1px 0px #ffaf06!important;
  border-color: #fec847;
  // border-color: transparent;
  //border: 0;
  background: linear-gradient(-76deg,#ffae00 30%,#fed527 100%);
}
.custom-checkbox {
  min-height: 0;
}
.custom-control-label {
  margin: 0;
  margin-bottom: .5rem;
  cursor: pointer;
  margin-top: 0px;
  margin-top: -5px;
  color: #b6b6b6;
  font-size: 9px;
}

// ==============================================
.navbar-brand img {
  // width: auto;
  height: 25px;
}
.fullpage{
  background: url(../img/main.jpg) no-repeat bottom center #fff;
  background-size: cover;
  width: 100%;
  height: 100vh;
  //max-height: 1080px;
  margin: 0;
  overflow: hidden;
  position: relative;
  &--404,
  &--thanks{
    background: url(../img/section_404.jpg) no-repeat bottom center #fff;
    background-size: cover;
  }
}
header{
  max-width: 1400px;
  margin: auto;
  position: relative;
  z-index: 1;
  .navbar{
    padding: 35px 35px;
  }
  .navbar-nav .nav-link {
    color: #333333;
    font-size: 12px;
    font-size: .8rem;
    padding-right: 1.25rem!important;
    padding-left: 1.25rem!important;
    &:focus, &:hover {
      text-decoration: underline;
    }
  }
  .header_mailblock {
    margin-top: .2rem;
    float: left;
    a {
      color: #333;
      font-size: 0.9rem;
      display: block;
    }
  }
  .header_phoneblock{
    padding-left: 70px;
    padding-left: 72px;
    float: right;
    background-image: url(../img/whatsapp_icon.png);
    background-image: url(../img/whatsapp2_icon.png);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 47px 6px;
    background-position: 47px 13px;
    padding: 8px 0 0 72px;
    a{
      color: #333;
      font-size: 18px;
      font-size: 1.15rem;
      font-family: "Uni Neue Bold";
      font-weight: normal;
    }
    span.menu_open,
    span.menu2_open{
      display: block;
      line-height: 1;
      line-height: 1.4;
      text-align: right;
      color: #333;
      font-size: 12px;
      font-size: .75rem;
      text-decoration: dashed;
      text-decoration: underline;
      cursor: pointer;
      margin: 0 0 0 66px;
      text-decoration: none;
      border-bottom: 1px dashed #333;
    }
  }
}


section{
  padding: 70px 0;
  position: relative;
  overflow: hidden;
}

section#page404,
section#pageThanks {
  padding-top: 210px;
  margin-top: -180px;
  padding-bottom: 82px;
  text-align: center;
}
.block_thanks_main{
  //margin: -15px auto 0;
  margin: 75px auto 0;
  .error_page{
    img{}
    h1{
      font-size: 32px;
      margin: 27px 0 21px;
      line-height: 1.45;
    }
    p {
      font-size: 21px;
      line-height: 1.55;
      margin-bottom: 39px;
      color: #465356;
      font-family: "Uni Neue Light";
    }
    a{
      font-family: "Uni Neue Bold";
      font-size: 16px;
      color: #000000;
      padding: .8rem 3.8rem .8rem;
    }
  }
}
section#pageThanks{
  .block_thanks_main {
    margin: 125px auto 0;
  }
}

section#main{
  padding-top: 210px;
  margin-top: -180px;
  padding-bottom: 82px;
  // background: url(../img/main.jpg) no-repeat bottom center #fff;
  // background-size: cover;
  // width: 100%;
  // height: 100vh;
  // max-height: 1080px;
  // margin: 0;
  .main-left {
    margin-top: 50px;
  }
  h1{
    font-size: 48px;
    font-size: 3.0rem;
    color: #333;
    line-height: 1.1;
  }
}
.slogan {
  font-weight: 300;
  font-size: 30px;
  font-size: 1.9rem;
  font-family: "Uni Neue Light";
  color: #465356;
  margin-top: 1rem;
  line-height: 1.3;
}
.advantage_cycles {
  color: #000;
  font-size: 2.875rem;
  line-height: 1.3;
  margin: 3.75rem 0;
  margin: 5rem 8px 3.0rem;
  span {
    font-family: "Uni Neue Light";
  }
  &:before {
    content: "";
    display: block;
    border-top: 5px solid #92cd75;
    width: 68px;
    width: 70px;
    padding-top: 20px;
  }
  div {
    font-size: 1rem;
    color: #465356;
  }
}
.patent_block{
  display: inline-block;
  height: 38px;
  background-image: url(../img/patent_icon.png);
  background-repeat: no-repeat;
  font-size: 0.875rem;
  font-family: "Uni Neue Light";
  color: #465356;
  margin-top: .65rem;
  margin-left: .45rem;
  padding-left: 50px;
  line-height: 1.3;
  line-height: 40px;
  a {
    color: #333;
    text-decoration: underline!important;
    border-bottom: 1px dashed #333;
    text-decoration: none!important;
    font-family: "Uni Neue Regular";
    cursor: pointer;
    &:hover {
      text-decoration: none!important;
    }
  }
}
.test-drive_div{
  margin-right: 70px;
  margin-right: 60px;
  margin-bottom: 30px;
  margin-bottom: 15px;
  span{
    font-size: 145px;
    line-height: 100px;
    line-height: 115px;
    color: #92cd75;
    display: block;
    width: 100px;
    float: left;
  }
  p{
    padding-left: 100px;
    font-size: 1.125rem;
    color: #000;
    &+p{
      //font-family: "Uni Neue Light";
      font-size: 0.875rem;
      color: #465356;
    }
  }
}
.test-drive_form{
  width: 300px;
  width: 320px;
  //height: 430px;
  background: #fff;
  padding: 30px;
  padding: 28px 32px;
  margin-right: 70px;
  margin-right: 63px;
  margin-right: 45px;
  border-radius: 10px;
  // border: 1px solid #e0e6e6;
  box-shadow: 1px 1px 3px rgba(199, 199, 199, 0.19), 2px 2px 18px rgba(206, 206, 206, 0.48);
  box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
  border: none;
  h4{
    font-family: "Uni Neue Bold";
    font-weight: 400;
    font-size: 21px;
    font-size: 1.3125rem;
    font-size: 1.2rem;
  }
  label {
    color: #6d7c7f;
    color: #b6b6b6;
    font-size: 10px;
    font-size: .625rem;
    margin: 0.75rem 0.15rem;
    margin: .79rem 0.15rem 0.1rem;
    margin-top: 0;
    line-height: 1;
  }
  p {
    margin: -5px 5px 0;
    margin: -5px -5px 0;
    margin-right: -15px;
    span {
      color: #465356;
      font-size: 9px;
      display: inline-block;
      text-align: center;
    }
  }
  #testDrive2_range1+p span:first-child,
  #testDrive_range1+p span:first-child {
    text-align: center;
    width: 30px;
  }
  #testDrive2_range1+p span:nth-child(2),
  #testDrive_range1+p span:nth-child(2) {
    width: 118px;
  }
  #testDrive2_range1+p span:nth-child(3),
  #testDrive_range1+p span:nth-child(3) {
    width: 35px;
  }
  #testDrive2_range1+p span:last-child,
  #testDrive_range1+p span:last-child {
    text-align: right;
    width: 65px;
  }
  #testDrive2_range2+p span:first-child,
  #testDrive_range2+p span:first-child {
    text-align: left;
    width: 35px;
  }
  #testDrive2_range2+p span:nth-child(2),
  #testDrive_range2+p span:nth-child(2) {
    width: 69px;
  }
  #testDrive2_range2+p span:nth-child(3),
  #testDrive_range2+p span:nth-child(3) {
    width: 42px;
  }
  #testDrive2_range2+p span:nth-child(4),
  #testDrive_range2+p span:nth-child(4) {
    width: 74px;
    text-align: center;
  }
  #testDrive2_range2+p span:last-child,
  #testDrive_range2+p span:last-child {
    text-align: right;
    width: 38px;
  }
  .form-group {
    margin-bottom: 1.5rem;
    &:nth-child(1) {
      margin-bottom: 0rem;
      margin-bottom: .5rem;
      margin-bottom: .75rem;
    }
    &:nth-child(4) {
      margin-bottom: .5rem;
      margin-bottom: .75rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  input[type=text] {
    border-radius: 19px;
    border-radius: 26px;
    height: calc(2.75rem + 2px);
    font-size: 16px;
    color: #6d7c7f;
    padding-left: 45px;
    background-image: url(../img/phone_icon.png);
    background-repeat: no-repeat;
    background-position: 19px;
  }
  button[type=submit] {
    width: 100%;
    font-family: "Uni Neue Bold";
    font-weight: 400;
  }
}

section#sec2{
  background: url(../img/section_02.jpg) no-repeat bottom center #fff;
  //background: url(../img/section_02mod0.jpg) no-repeat bottom center #fff;
  padding: 70px 0 0;
  background-position: top center;
  background-size: cover;
}
.production-video{
  // width: 630px;
  // height: 445px;
}
.advantage_right{
  // background-image: url(../img/adv_button.png);
  // background-repeat: no-repeat;
  // background-position: 190px 275px;
  // background-position: 330px 282px;
  // background-position: 215px 282px;
  background-image: url(../img/adv_button2.png);
  background-repeat: no-repeat;
  background-position: 310px 377px;
  background-size: 315px;
  h3{
    font-size: 2rem;
    color: #eef3f3;
    padding-left: 55px;
    padding-top: 30px;
    line-height: 1.4;
    padding-right: 5px;
  }
}
.production-video__img{
  width: 100%;
  height: auto;
  p {
    margin-top: 45px;
    text-align: center;
    font-family: "Uni Neue Bold";
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
        margin-left: 10px;
  }
}
.production-start{
  margin: 83px -10px 0;
  margin: 80px 0px 0;
  width: 360px;
  height: 135px;
  background: #2f3237;
  color: #fff;
  font-size: 0.875rem;
  font-family: "Uni Neue Light";
}

.prodstart__left{
  float: left;
  padding: 10px 20px;
  font-size: 1rem;
  span{
    font-size: 5rem;
  }
}
.prodstart__right{
  font-size: .875rem;
  padding: 35px 0 0;
  padding-left: 200px;
}
.adv_block{
  margin: 45px 55px;
  margin-right: 0;
  &:before{
    content: "";
    display: block;
    border-top: 5px solid #92cd75;
    width: 68px;
    width: 70px;
    padding-top: 20px;
  }
}
.adv_item{
  color: #dce3e3;
  font-size: 0.875rem;
  font-family: "Uni Neue Light";
  width: 50%;
  padding: 20px 0;
  padding: 43px 0 0px;
  float: left;
  height: 85px;
  // &:nth-child(2){
  //   padding-left: 65px;
  // }
  &:nth-child(3){
    padding-left: 65px;
  }
  span{
    color: #fff;
    font-size: 2.625rem;
    display: block;
    width: 100px;
    float: left;
    line-height: 1;
  }
}

.final-top-l__btn {
  position: relative;
  width: 232px;
  height: 232px;
  border-radius: 100%;
  background: url(../img/play_icon.png) no-repeat center/cover;
  cursor: pointer;
  margin: 8rem auto 8rem;
  width: 70px;
  height: 70px;
  background: #fff url(../img/media-play-symbol3.svg) no-repeat center/cover;
  background-position: center center;
  background-size: 15px;
  background-image: url(../img/media-play-symbol3.svg);
  background: linear-gradient(-35deg,#ffe25b 0%,#c79216 0%,#fed527 100%);
  background: linear-gradient(-35deg,#ffe25b 0%,#c79216 50%,#fed527 100%);
  background: linear-gradient(-35deg,#ffe25b 0%,#c79216 100%);
  background: linear-gradient(-35deg,#c79216 0%,#ffe25b 100%);
  border: #684e0e 1px solid;
  border-color: #946900;
  border-top-width: 0;
  border-bottom-width: 2px;
  span{
    display: block;
    background-image: url(../img/media-play-symbol3.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px;
    width: 70px;
    height: 70px;
  }
  &:before, &:after {
    content: "";
    // width: 150px;
    // height: 150px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid #fbb921;
    border-radius: 50%;
    -webkit-animation: circle 1.7s linear infinite;
    animation: circle 1.7s linear infinite;
  }
  &:before {
    -webkit-animation: circle 2s linear infinite;
    animation: circle 2s linear infinite;
  }
  &:after {
    -webkit-animation: circle 2s 1s linear infinite;
    animation: circle 2s 1s linear infinite;
  }
}


section#sec3{
  background: #24262b url(../img/section_03.png) no-repeat;
  padding: 80px 0 70px;
  padding: 80px 0 185px;
  background-position: top center;
  background-position: bottom center;
  background-size: cover;
  //height: 100vh;
  h2 {
    font-size: 2.75rem;
    line-height: 1.3;
  }
  h2+p {
    font-size: 1.875rem;
    color: #465356;
    font-family: "Uni Neue Light";
  }
}
.send-catalog_form {
  form {
    width: 310px;
    padding: 27px;
    width: 320px;
    padding: 28px 32px;
    margin-top: 60px;
    margin-bottom: 90px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #e0e6e6;
    box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
    border: none;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 1.45;
    margin-bottom: 30px;
  }
  input[type=text] {
      border-radius: 26px;
      height: calc(2.75rem + 2px);
      font-size: 16px;
      color: #6d7c7f;
      padding-left: 45px;
      background-image: url(../img/phone_icon.png);
      background-repeat: no-repeat;
      background-position: 19px;
  }
  input[name=test-drive_mail] {
      background-image: url(../img/mail_icon.png);
      background-repeat: no-repeat;
      background-position: 19px;
  }
  button[type=submit] {
    //width: 100%;
    // background: #fbd255 url(../img/download_icon.png) no-repeat;
    // background-position: 180px 16px;
    // padding: 0.75rem 3rem 0.75rem;
    // padding-left: 2rem;
    // padding-right: 4rem;
  }
  button[type=submit] {
    width: 100%;
    padding: .75rem 3rem .75rem;
    box-shadow: 0 2px 0 0 #c3880a,1px 1px 0 #ffaf06!important;
    border-color: #fec847;
    padding-left: 2rem;
    padding-right: 4rem;
    position: relative;
    &:before {
      content: "";
      display: block;
      background: url(../img/download_icon.png) no-repeat;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 16px;
      right: 56px;
    }
  }
  .form-group:last-child {
      margin-bottom: 0;
  }
  label {
    color: #6d7c7f;
    font-size: .625rem;
    margin: .79rem .15rem .1rem;
    margin-top: 0;
    line-height: 1;
    text-align: left;
  }
}
.description {
  margin-top: 82px;
  .desc {
    font-size: 0.875rem;
    font-family: "Uni Neue Bold";
    color: #fff;
    display: block;
    // background-image: url(../img/point_icon.png);
    // background-repeat: no-repeat;
    // background-position: 0px;
    height: 83px;
    width: 33.33%;
    float: left;
    span {
      display: block;
      padding: 38px;
      padding: 0;
      padding-top: 30px;
      padding-bottom: 30px;
      width: 81px;
      position: relative;
      z-index: 1;
      text-align: center;
      &:after{
        position: absolute;
        top: 26px;
        left: 0;
        right: 0;
        margin: auto;
        width: 30px;
        height: 30px;
        content: '';
        border-radius: 50%;
        background: linear-gradient(3deg,#7cc340 0,#93dc54 100%);
        box-shadow: 0 0 25px 3px #6db94d73;
        z-index: -1;
      }
    }
  }
  .desc-text {
    font-size: 0.75rem;
    color: #000;
    text-transform: uppercase;
    padding: 0 25px;
    padding-right: 0;
  }
}
.desc1 {
  margin-top: 60px;
}
.desc2 {
  margin-top: 30px;
}
.desc3 {
  margin-top: 0px;
}


section#sec4{
  background: #24262b;
  padding: 70px 0 70px;
  padding-top: 0px;
  //height: 100vh;
  background: #24262b url(../img/section_04.png) no-repeat;
  background-position: top center;
  //background-position: bottom center;
  h2{
    text-align: center;
    color: #eef3f3;
    line-height: 1.35;
  }
  .quiz_block{
    box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.19), 2px 2px 18px rgba(239, 239, 239, 0.48);
    box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
    border: none;
    height:600px;
    height:auto;
    min-height:600px;
    background: #fff;
    border-radius: 10px;
    padding: 25px;
    padding: 30px;
    padding: 30px 13px;
    padding: 13px;
    margin-top: 95px;
    margin-bottom: 95px;
    .slick-dots li button:before {
      content: "";
      display: block;
      background: #e7e6f1;
      opacity: 1;
      border-radius: 50%;
      width: 14px;
      height: 14px;
    }
    .slick-dots li.slick-active button:before {
      background: #77d575;
      opacity: 1;
    }
    .slick-next, .slick-prev {
      height: 40px;
      width: 40px;
      background: #77d575;
      border-radius: 50%;
    }
    .slick-next:before, .slick-prev:before {
      font-size: 40px;
      line-height: 1.15;
      opacity: 1;
      display: block;
      content: "";
      width: 40px;
      height: 40px;
      background-image: url(../img/next-arrow_white.svg);
      background-repeat: no-repeat;
      background-position: 50%;
    }
    .slick-next:before, .slick-prev:before {
      color: white !important;
      opacity: 1;
    }
    .slick-prev:before {
      background-image: url(../img/prev-arrow_white.svg);
    }
    .quiz_left{
      width: 855px;
      width: 842px;
      width: 845px;
      float: left;
      form{
        padding: 25px 0px;
        max-width: 666px;
        margin: auto;
        padding: 0;
        margin-top: -5px;
        h3 {
          font-size: 21px;
          color: #000000;
          line-height: 1.6;
          margin-bottom: 36px;
        }
        input[type=text] {
          border-radius: 26px;
          height: calc(2.75rem + 2px);
          font-size: 16px;
          color: #6d7c7f;
          padding-left: 45px;
        }
        input[name=quiz_callback_phone] {
          background-image: url(../img/phone_icon.png);
          background-repeat: no-repeat;
          background-position: 19px;
        }
        input[name=quiz_callback_name] {
          background-image: url(../img/user_icon.png);
          background-repeat: no-repeat;
          background-position: 19px;
        }
        button[type=submit] {
          // width: 100%;
          // padding: .75rem 3rem .75rem;
          box-shadow: 0 2px 0 0 #c3880a,1px 1px 0 #ffaf06!important;
          border-color: #fec847;
          position: relative;
          margin-top: 30px;
          width: auto;
          padding: .9rem 4rem;
        }
        .custom-control-label{
          text-align: left;
        }
        .custom-checkbox{
          width: 238px;
          margin: 25px auto;
        }
      }
    }
    .progress{
      height: .75rem;
      width: 525px;
      margin: auto;
      border-radius: 10px;
      // background: linear-gradient(-45deg,#cccccdb5 25%,#ededed 25%,#ededed 50%,#cccccdb5 50%,#cccccdb5 75%,#ededed 75%,#ededed);
      background: linear-gradient(-45deg,rgba(204,204,205,.71) 25%,rgba(237,237,237,1) 25%,rgba(237,237,237,1) 50%,rgba(204,204,205,.71) 50%,rgba(204,204,205,.71) 75%,rgba(237,237,237,1) 75%,rgba(237,237,237,1));
      background-size: 6px 6px;
      box-shadow: inset 4px 4px 4px -3px rgba(0,0,0,.5);
      span{
        background: #78b731;
        background: linear-gradient(90deg,#94dd55 0,#6fb433 100%);
        background: linear-gradient(90deg,rgba(148, 221, 85, 0.75) 0,rgba(111, 180, 51, 0.75) 100%);
        box-shadow: inset 4px 4px 4px -3px rgba(0,0,0,.5);
      }
    }
    .progress__label{
      font-family: "Uni Neue Bold";
      color: #24262b;
      font-size: 0.875rem;
      padding-bottom: 7px;
      padding-top: 17px;
    }
    .question__title{
      font-family: "Uni Neue Light";
      color: #24262b;
      font-size: 1.875rem;
      padding: 75px 0 30px;
      padding: 60px 0 30px;
    }
    .quiz__question{
      padding-left: 20px;
      padding-right: 20px;
      #test__kviz04{
        margin: 0 50px;
        .col-12.col-sm-6.col-lg-3{
          &:focus{
            outline: none;
          }
        }
      }
      .slick-next {
          right: -45px;
      }
      .slick-prev {
          left: -45px;
      }
      .card{
        cursor: pointer;
        box-shadow: 1px 1px 2px rgba(245, 245, 245, 0.19), 1px 1px 15px rgba(202, 202, 202, 0.48);
        border: none;
        .card-text{
          color: #24262b;
          font-size: 12px;
          font-family: "Uni Neue Bold";
          text-transform: uppercase;
          height: 36px;
        }
        img {
          padding: 8px;
          border-radius: 20px;
          border-radius: 15px;
        }
        &:hover{
          background: #dfe6e7;
          box-shadow: 1px 1px 3px rgba(165, 165, 165, 0.19), 2px 2px 18px rgba(189, 189, 189, 0.48);
        }
        &.card__mini{
          margin-bottom: 30px;
          img {
            border-radius: 15px;
            width: 100%;
          }
          .card-body {
            padding: 10px 1.25rem;
            margin-top: -8px;
            .card-text {
              height: 18px;
            }
          }
        }
        &.card__mini2{
          margin-bottom: 30px;
          img {
            width: 50%;
            margin: 0;
            float: left;
            padding: 25px;
          }
          .card-text2 {
            width: 50%;
            float: right;
            padding: 25px;
            margin: 0;
            line-height: 110px;
            padding: 0;
            font-size: 30px;
            font-size: 26px;
            font-family: "Uni Neue Light";
          }
        }
        &.card__mini3{
          // margin-bottom: 30px;
          margin-bottom: 22px;
          width: 330px;
          .card-body3{
            height: 60px;
            .card-text3_1{
              text-align: left;
              float: left;
              padding: 18px 20px;
              padding-right: 0;
              width: 175px;
            }
            .card-text3_2{
              float: right;
              height: 60px;
              sup{
                font-size: 10px;
                text-transform: uppercase;
                text-align: left;
                /* height: 60px; */
                top: 20px;
              }
              span {
                content: none;
                position: relative;
                width: 0;
                height: 0;
                color: black;
                display: block;
                font-size: 48px;
                font-family: "Uni Neue Light";
                height: 60px;
                line-height: 60px;
                /* line-height: 1; */
                width: 135px;
                background: 0 0;
                top: 0;
                padding: 0 30px 0 45px;
                margin-right: 10px;
                text-align: right;
                padding: 0 35px 0 45px;
                display: inline-block;
                width: 100%;
                &:before{
                  display: block;
                  content: 'скидка';
                  position: absolute;
                  font-size: 10px;
                  text-transform: uppercase;
                  left: 3px;
                  top: -9px;
                  font-family: "Uni Neue Regular";
                }
                &:after{
                  display: block;
                  position: absolute;
                  content: '%';
                  font-size: 24px;
                  width: 25px;
                  height: 60px;
                  right: 10px;
                  top: 7px;
                  font-family: "Uni Neue Regular";
                  text-align: left;
                }
              }
            }
          }
          .card-text2 {
            width: 50%;
            float: right;
            padding: 25px;
            margin: 0;
            line-height: 110px;
            padding: 0;
            font-size: 30px;
            font-size: 26px;
            font-family: "Uni Neue Light";
          }
        }
      }
      .card-body {
        padding: 1.0rem 1.25rem;
      }
    }
    .quiz_right{
      margin-left: 24px;
      padding-left: 845px;
      padding-left: 830px;
      padding-left: 835px;
      .quiz_right__inner{
        padding-left: 15px;
        box-shadow: -10px 0 17px rgba(204, 204, 204, 0.14);
        box-shadow: -10px 0 40px rgba(204, 204, 204, 0.23);
      }
      p{
        color:#465356;
        font-size: 0.75rem;
      }
      .right_background {
        height: 275px;
        //margin: 0 12px;
        background: url(../img/quiz_rightbackground.jpg) no-repeat;
        background-size: cover;
        background-position: bottom center;
        border-radius: 0 10px 0 0;
        h4{
          font-size: 1.5rem;
          color: #000000;
          padding: 22px 0 0;
        }
      }
      form{
        padding: 25px 0px;
        width: 260px;
        margin: auto;
        h5 {
          font-size: 1.3125rem;
          color: #000000;
        }
        input[name=quiz_right_phone] {
          border-radius: 26px;
          height: calc(2.75rem + 2px);
          font-size: 16px;
          color: #6d7c7f;
          padding-left: 45px;
          background-image: url(../img/phone_icon.png);
          background-repeat: no-repeat;
          background-position: 19px;
        }
        // button[type=submit] {
        //   width: 100%;
        //   background: #fbd255 url(../img/call_icon.png) no-repeat;
        //   background-position: 186px 16px;
        //   padding: .75rem 3rem .75rem;
        //   padding-left: 2rem;
        //   padding-right: 4rem;
        // }
        button[type=submit] {
          width: 100%;
          padding: .75rem 3rem .75rem;
          box-shadow: 0 2px 0 0 #c3880a,1px 1px 0 #ffaf06!important;
          border-color: #fec847;
          padding-left: 2rem;
          padding-right: 4rem;
          position: relative;
          &:before {
            content: "";
            display: block;
            background: url(../img/call_icon.png) no-repeat;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 16px;
            right: 56px;
          }
        }
        .custom-control-label{
          text-align: left;
        }
      }
    }
  }
  .test__kviz06--item{
    .card__mini3{
      float: right;
    }
    &:nth-child(2n){
      .card__mini3{
        float: left;
      }
    }
  }
}

section#sec5{
  background: #faf9fe;
  background-position: top center;
  //background-position: bottom center;
  padding: 95px 0 40px;
  padding: 95px 0 33px;
  background: #faf9fe url(../img/section_05.jpg) no-repeat;
  h2{
    text-align: center;
    line-height: 1.35;
  }
  h2+p{
    text-align: center;
    font-size: 1.3125rem;
    color: #465356;
    margin: 35px 0 60px;
    font-family: "Uni Neue Light";
    &:before {
      content: "";
      display: block;
      border-top: 5px solid #92cd75;
      width: 68px;
      width: 70px;
      padding-top: 20px;
      margin: auto;
    }
  }
  .card button{
    //width: 100%;
  }
}
.product_item{
  .product_item-inner{
    background: #fff;
    height: 287px;
    border-radius: 10px;
    padding: 25px;
    margin: 0 8px;
    margin-bottom: 30px;
    border: 1px solid #ccc;
  }
}
.card-columns .card {
  //margin-bottom: 2.15rem;
  margin-bottom: 1.3rem;
  border-radius: 15px;
  border-radius: 10px;
}
.card {
  box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.19), 2px 2px 18px rgba(239, 239, 239, 0.48);
  text-align: center;
  position: relative;
  margin-bottom: 22px;
  border-radius: 8px;
  span{
    content: '';
    display: block;
    width: 46px;
    height: 46px;
    top: -10px;
    right: -5px;
    position: absolute;
    background: #88d05f;
    border-radius: 50%;
    color: #fff;
    line-height: 46px;
    text-align: center;
    font-family: "Uni Neue Bold";
    font-size: 0.875rem;
  }
  .card-img-top{
    padding: 1.25rem;
    padding: 2rem 3rem 0;
  }
  .card-text {
    font-size: 14px;
    font-family: "Uni Neue Regular";
    color: #000;
    strike{
      float: left;
      padding: 4px;
      padding-right: 0px;
      //margin-right: -20px;
      margin-right: -25px;
    }
    strong{
      font-size: 24px;
      font-family: "Uni Neue Bold";
    }
  }
  a.btn,
  button{
    padding: .5rem 2.0rem .5rem;
  }
}

section#sec6 {
  background-position: top center;
  //padding: 95px 0 40px;
  padding: 95px 0 35px;
  background: #ccc url(../img/section_06.jpg) no-repeat;
  background-size: cover;
  color: #fff;
  .laserEngraving_block{
    h2{
      font-size: 2rem;
      line-height: 1.3;
      span{
        font-family: "Uni Neue Light";
        font-size: 1.6875rem;
      }
    }
  }
  .economy_block{
    margin: 65px 0 30px;
    span{
      font-family: "Uni Neue Bold";
      font-size: 1.09375rem;
    }
    .economy_value{
      font-family: "Uni Neue Light";
      font-size: 0.9375rem;
      margin: -10px 0 5px;
      span{
        font-family: "Uni Neue Light";
        font-size: 4rem;
      }
    }
    .economy_text{
      font-family: "Uni Neue Light";
      font-size: 0.9375rem;
      float: left;
      padding-right: 130px;
      &:before {
        content: "";
        display: block;
        border-top: 5px solid #92cd75;
        width: 70px;
        padding-top: 25px;
      }
    }
    .free_engraving{
      font-family: "Uni Neue Light";
      font-size: 0.75rem;
      padding-top: 1rem;
      line-height: 1.8;
      color: #dce3e3;
      float: left;
      background-image: url(../img/array_icon.png);
      background-repeat: no-repeat;
      background-position: bottom left;
      padding-bottom: 90px;
      margin-bottom: -90px;
      background-position: 13px 93px;
    }
  }
  .bookingForm{
    //width: 260px;
    form{
      width: 295px;
      padding: 28px 32px;
      padding-left: 0;
    }
    input[name=laser-engraving_phone] {
      box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.19), 2px 2px 18px rgba(239, 239, 239, 0.48);
      background-color: #4e4543;
      border: 1px solid #4e4543;
      border-radius: 26px;
      height: calc(2.75rem + 2px);
      font-size: 16px;
      color: #6d7c7f;
      padding-left: 45px;
      background-image: url(../img/phone_icon.png);
      background-repeat: no-repeat;
      background-position: 19px;
    }
    button[type=submit]{
      width: 100%;
    }
    // .form-group:last-child {
    //   margin-bottom: 1rem;
    //   margin-bottom: 1.4rem;
    // }
    .form-group:nth-last-child(2) {
      margin-bottom: 1.4rem;
    }
  }
}


section#sec7 {
  //height: 913px;
  background-position: top center;
  padding: 95px 0 35px;
  padding: 63px 0 35px;
  background: #ccc url(../img/section_07.jpg) no-repeat;
  background-size: cover;
  h2{
    font-size: 2.75rem;
    line-height: 1.3;
  }
  .comments_block{
    //background: #cccccc52;
    margin: 40px 0;
    height: 520px;
    height: 370px;
    width: 815px;
    margin: 40px auto;
    float: left;
    margin: 56px auto;
    margin-left: 24px;
  }
  .customers_count {
    width: 300px;
    height: 370px;
    //background: #ccc;
    float: right;
    // margin: 40px 0;
    // padding: 80px 55px;
    margin: 56px 0;
    padding: 53px 61px;
    padding-right: 0;
    font-family: "Uni Neue Light";
    span{
      font-size: 5.25rem;
    }
    p {
      color: #465356;
      font-size: 1.125rem;
      margin: 38px 8px;
      margin: 5px 8px;
      &:before {
        content: "";
        display: block;
        border-top: 5px solid #92cd75;
        width: 68px;
        width: 70px;
        padding-top: 28px;
      }
    }
  }
  .comments_logos{
    margin: 0;
    margin: 110px auto;
    margin: 110px auto 40px;
    height: 63px;
    background: url(../img/comments_logos.png) no-repeat;
    background-size: contain;
    background-position: 0px 2px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 40px;
    content: '';
  }
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    width: 50px;
    height: 50px;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 3px rgba(132, 132, 132, 0.28), 2px 2px 18px rgba(226, 226, 226, 0.66);
    box-shadow: 2px 2px 18px rgba(142, 142, 142, 0.26), 2px 2px 0px rgba(226, 226, 226, 0.1);
    border: none;
  }
  .slick-prev {
    left: -18px;
    left: -23px;
    background-image: url(../img/prev-arrow.svg);
  }
  .slick-next {
    right: -20px;
    right: -25px;
    background-image: url(../img/next-arrow.svg);
  }
  .slick-dots{
    bottom: -45px;
    bottom: -90px;
    bottom: -112px;
    bottom: -70px;
    li {
      width: 50px;
      height: 50px;
      button {
        width: 50px;
        height: 50px;
        &:before {
          font-size: 25px;
          line-height: 50px;
          width: 50px;
          height: 50px;
          color: #e7e7f2;
        }
      }
      &.slick-active button:before {
        color: #92cd75;
        color: #88d05f;
      }
    }
  }
  .slide-item {
    min-width: 815px;
    max-width: 815px;
    height: 370px;
    border-radius: 10px;
    margin-bottom: 0;
    margin-bottom: 35px;
    margin-bottom: 45px;
    &:focus{
      outline: none;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      height: 30px;
      width: 200px;
      top: auto;
      bottom: -15px;
      left: 0;
      right: 0;
      border-radius: 15px;
      border-radius: 0 0 11px 11px;
      background-color: #fff;
      z-index: -1;
      margin: auto;
      box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
      bottom: -20px;
      width: 190px;
      width: 75%;
      bottom: -35px;
      width: 83%;
    }
    &:after {
      bottom: -10px;
      width: 88%;
      bottom: -17.5px;
      width: 87%;
    }
  }
  .card_img {
    position: absolute;
    width: 230px;
  }
  .card_img_blk {
    position: absolute;
    width: 230px;
    height: 369px;
    background: url(../img/avatar.png) no-repeat;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    background-size: cover;
    img{
      width: 493px;
      margin-left: -97px;
      height: auto;
    }
  }
  .card_img_blk--2 {
    background: url(../img/avatar2.png) no-repeat;
    background-size: cover;
  }
  .card-img-overlay {
    width: 230px;
    top: auto;
    .card-title{
      color: #fff;
      margin-bottom: 0rem;
    }
    p{
      color: #bfc5c7;
    }
  }
  .card-body {
    padding: 2rem 2.75rem 2rem;
    padding: 2rem 4.0rem 2rem;
    padding-left: 230px;
    // max-width: 815px;
    // border-radius: 10px;
    // border: 1px solid #f3f2f6;
    h5{
      font-size: 1.5rem;
      padding: 0 0 27px;
      padding-left: 50px;
      text-align: left;
    }
    img {
      margin-left: 50px;
      margin-right: 37px;
      border: 1px solid #ebebee;
      border-radius: 10px;
    }
    p {
      color: #465356;
      font-size: 1rem;
      font-family: "Uni Neue Light";
      //color: #ff007f;
      display: block;
      text-align: left;
      margin-bottom: 1.5rem;
      padding-left: 50px;
      // padding-bottom: 10px;
    }
    button[type=button] {
      // padding: 1rem 3rem 1rem;
      // padding-left: 2.0rem;
      // padding-right: 4.0rem;
      // background: #fbd255 url(../img/speaker_icon.png) no-repeat;
      // background-position: 205px 22px;
      //box-shadow: 0 4px 0 0 #987311, 1px 1px 6px rgba(0, 0, 0, 0.24);
    }
    button[type=button] {
      // width: 100%;
      padding: 1rem 3rem 1rem;
      // box-shadow: 0 2px 0 0 #c3880a,1px 1px 0 #ffaf06!important;
      // box-shadow: 0 3px 0 0 #c3880a,1px 1px 0 #ffaf06!important;
      border-color: #fec847;
      padding-left: 2rem;
      padding-right: 4rem;
      position: relative;
      &:hover{
        // box-shadow: 0 2px 0 0 #c3880a,1px 1px 0 #ffaf06!important;
      }
      .speaker_icon{
      //&:before {
        content: "";
        display: block;
        background: url(../img/speaker_icon.png) no-repeat;
        width: 20px;
        height: 15px;
        position: absolute;
        top: 22px;
        right: 30px;
      }
    }
  }
}

section#sec8 {
  //height: 842px;
  background-position: top center;
  padding: 80px 0 35px;
    padding: 78px 0 35px;
  background: #ccc url(../img/section_08mod0.jpg) no-repeat;
  //background: #ccc url(../img/section_08mod00.jpg) no-repeat;
  //background: #ccc url(../img/section_08.jpg) no-repeat;
  color: #fff;
  h2{
    font-size: 2rem;
    line-height: 1.4;
    margin-bottom: 20px;
    margin-bottom: 17px;
  }
  p{
    font-family: "Uni Neue Light";
    font-size: 1.3125rem;
  }
}
.quiz_questions {
  //width: 452px;
  margin: 47px 0;
  margin: 47px 0;
  background: #fff;
  border-radius: 10px;
  padding: 30px 15px 25px;
  padding: 20px 18px 25px;
  padding: 20px 25px 25px;
  box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
  border: none;
  form{
    .form-group {
      margin-bottom: 9px;
      position: relative;
    }
    label{
      color: #878787;
      font-size: 9px;
      text-transform: uppercase;
      padding: 0;
      padding-left: 22px;
      margin-bottom: 6px;
    }
    input[type=text]{
      position: relative;
      background-image: url(../img/location_icon.png);
      background-repeat: no-repeat;
      border-radius: 26px;
      height: calc(2.75rem + 2px);
      font-size: 16px;
      color: #6d7c7f;
      padding-left: 45px;
      background-position: 20px;
      background-size: 12px;
      // box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.28), 2px 2px 18px rgba(239, 239, 239, 0.66);
      &+span{
        position: absolute;
        top: 39px;
        right: 12px;
        content: '';
        transform: rotate(90deg);
        background: url(../img/right-arrow.svg);
        background-color: #ffffff;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        //padding: 2px;
        //background-size: 10px;
        background-size: 8px;
        background-repeat: no-repeat;
        background-position: 50%;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
      }
    }
    input[type=number]{
      box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.28), 2px 2px 18px rgba(239, 239, 239, 0.66);
      box-shadow: inset 1px 1px 2px rgba(0,0,0,.18);
      box-shadow: inset 1px 1px 2px rgba(0,0,0,.18), 2px 2px 18px rgba(239,239,239,.66);
      box-shadow: inset 1px 1px 2px rgba(0,0,0,.18), 2px 2px 9px rgba(0, 0, 0, 0.08);
      border-radius: 26px;
      height: calc(2.75rem + 2px);
      font-size: 16px;
      color: #6d7c7f;
      padding-left: 45px;
      padding-right: 45px;
      background-size: 12px;
      text-align: center;
      //width: 164px;
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
    .quantity-arrow-minus,
    .quantity-arrow-plus {
      cursor: pointer;
      color: #333;
      font-family: "Uni Neue Light";
      font-size: 24px;
      line-height: 45px;
      padding: 0;
      width: 45px;
      height: 44px;
      box-sizing: border-box;
      outline: 0;
      box-shadow: none;
      border: none;
      background: #fff;
      position: absolute;
      font-size: 40px;
      line-height: 1;
      line-height: 43px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
      color: #7c7e7c;
    }
    .quantity-arrow-minus {
      top: 29px;
      left: 7px;
      border-radius: 50% 0 0 50%;
      border-right: 1px solid #ccc;
      box-shadow: 3px 0px 2px 0px rgba(0, 0, 0, 0.07);
      box-shadow: 3px 0px 4px rgba(204, 204, 204, 0.3);
      box-shadow: 10px 0 17px rgba(204, 204, 204, 0.21);
      height: 43px;
      top: 30px;
    }
    .quantity-arrow-plus {
      top: 29px;
      right: 7px;
      left: auto;
      border-radius: 0 50% 50% 0;
      border-left: 1px solid #ccc;
      box-shadow: -3px 0 2px 0 rgba(0,0,0,.07);
      box-shadow: -3px 0px 4px rgba(204, 204, 204, 0.3);
      box-shadow: -10px 0 17px rgba(204, 204, 204, 0.21);
      height: 43px;
      top: 30px;
    }
  }
  .result{
    margin: 20px 0 0;
    margin: 17px 0 0;
    .table-bordered {
      border: 1px solid #dee2e6;
      border: none;
    }
    .table {
      margin: 0;
      thead th {
        // vertical-align: bottom;
        // border-bottom: 2px solid #dee2e6;
        color: #333;
        font-size: 14px;
        font-weight: normal;
        font-family: "Uni Neue Bold";
        border-bottom-width: 1px;
        &:focus{
          outline: none;
        }
      }
      td,th {
        border: 1px solid #dee2e6;
        border: none;
        border-right: 1px solid #dee2e6;
        padding: .75rem 0;
        padding: 2px 0 0;
        // padding-left: 25px;
        // &:first-child {
        //   padding-left: 0;
        // }
        &:last-child {
          border-right: none;
        }
      }
      th {
        padding: 4px 0 13px;
        padding: 5px 0 5px;
        border-bottom: 1px solid #ccc;
        padding-left: 25px;
        padding-left: 20px;
        &:first-child {
          padding-left: 0;
          padding-left: 2px;
        }
      }
      td {
        padding: 2px 0 0;
        padding: 5px 0 0;
        padding: 4px 0 2px;
        font-size: 14px;
        font-weight: normal;
        font-family: "Uni Neue Light";
        font-family: "Uni Neue Regular";
        padding-left: 25px;
        color: #333;
        &:first-child {
          padding-left: 0;
          padding-left: 2px;
        }
        a{
          font-size: 14px;
          font-weight: normal;
          // font-family: "Uni Neue Light";
          // font-family: "Uni Neue Regular";
          color: #333;
          text-decoration: none;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }
}
.delivery_advantages{
  width: 100%;
  height: 510px;
  margin: 47px 0 20px;
  margin: 39px 0 20px;
  // background-image: url(../img/car.png);
  // background-position: 15px 178px;
  // background-repeat: no-repeat;
  .delivery_advantages-item{
    font-family: "Uni Neue Light";
    padding-left: 122px;
    float: left;
    width: 50%;
    height: 96px;
    margin: 0 0 36px;
    // background-image: url(../img/checked_icon.png);
    // background-position: 55px 15px;
    // background-repeat: no-repeat;
    position: relative;
    &:before{
      position: absolute;
      top: 41px;
      //right: 232px;
      left: 24%;
      width: 22px;
      height: 22px;
      content: '';
      -webkit-border-radius: 50%;
      background: url(../img/checked7.svg) no-repeat center center,-webkit-linear-gradient(136deg,#85ce49 0,#72b937 100%);
      background-size: 15px,100%;
      box-shadow: 0 0 25px 2px #7cc440;
    }
    span{
      font-size: 64px;
      color: #fff;
      display: block;
      float: left;
      padding-right: 28px;
    }
    p{
      font-size: 18px;
      color: #dce3e3;
      padding: 22px 0 0;
      float: left;
      margin: 0;
      line-height: 1.3;
    }
  }
}

section#sec9 {
  height: 720px;
  position: relative;
  background-position: top center;
  //padding: 125px 0 118px;
  margin: 0;
  padding: 0;
  background: #ccc url(../img/section_09.jpg) no-repeat;
  .address_block{
    background: #faf9fe;
    width: 430px;
    height: 497px;
    padding: 55px 50px;
    position: absolute;
    z-index: 1;
    top: 125px;
    box-shadow: 1px 1px 3px rgba(132,132,132,.28), 2px 2px 18px rgba(226,226,226,.66);
    h2{
      font-family: "Uni Neue Light";
      font-size: 2rem;
      line-height: 1.35;
    }
    address{
      font-size: 1.3125rem;
      padding: 18px;
      padding-left: 0;
      .address_item{
        margin-bottom: 1.125rem;
        padding-left: 43px;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: left center;
        &.address_item--phone{
          background-image: url(../img/whatsapp_icon.png);
          background-image: url(../img/whatsapp2_icon.png);
          background-position: left 41px;
          background-position: left 38px;
          background-size: 22px;
        }
        &.address_item--location{
          background-image: url(../img/location_icon.png);
          background-position: left 39px;
          background-position: left 35px;
        }
        &.address_item--email{
          background-image: url(../img/mail_icon.png);
          background-position: left 37px;
          background-position: left 38px;
        }
      }
      span{
        font-family: "Uni Neue Bold";
        font-size: 1.6875rem;
      }
      strong{
        font-weight: normal;
        font-size: 0.75rem;
        color: #465356;
      }
    }
  }
}



.btn-yellow.focus,
.btn-yellow:focus {
  outline: 0;
  box-shadow: none;
}

.slick-prev:before,
.slick-next:before {
  color: #464547!important;
}


//===================================

footer {
  position: relative;
  overflow: hidden;
  .footer_menu {
    padding: 85px 0;
    padding: 73px 0;
    padding: 70px 0;
  }
  .navbar-dark .navbar-nav .nav-link {
    //font-size: 14px;
    font-size: .9rem;
    font-family: "Uni Neue Light";
    text-decoration: underline;
    color: #fff;
    padding-right: 6rem;
    padding-left: 6rem;
  }
}
.socials-list {
  padding: 0;
  padding-right: 70px;
  font-size: 0;
}
.socials {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-left: 5px;
  margin-left: 15px;
  background-image: url(../img/social_icons.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.socials.social_vk {
  background-position: 0px 0px;
}
.socials.social_fb {
  background-position: -31px 0px;
}
.socials.social_in {
  background-position: -62px 0px;
}

//===================================

#orderModal{
  .modal-content{
    padding: 23px 50px 20px;
    padding: 23px 50px 0;
    padding: 0px 0 0;
    padding-left: 46px;
    padding-left: 44px;
    margin: auto;
    border: none;
    background: url(../img/orderModal.png) no-repeat;
    background-size: cover;
    //background-size: contain;
    border-radius: 8px;
    max-width: 497px;
    h3{
      font-size: 2.0rem;
      text-align: center;
      margin: 70px 0 4px;
    }
    .orderDiv {
      width: 300px;
      padding: 22px 0px 48px;
      padding: 22px 0 29px;
      margin: auto;
      p{
        text-align: center;
        margin-bottom: 2rem;
      }
      input[name=order_phone] {
        border-radius: 26px;
        height: calc(2.75rem + 2px);
        font-size: 16px;
        color: #6d7c7f;
        padding-left: 45px;
        background-image: url(../img/phone_icon.png);
        background-repeat: no-repeat;
        background-position: 19px;
      }
      input[name=order_name] {
        border-radius: 26px;
        height: calc(2.75rem + 2px);
        font-size: 16px;
        color: #6d7c7f;
        padding-left: 45px;
        background-image: url(../img/user_icon.png);
        background-repeat: no-repeat;
        background-position: 19px;
      }
      button[type=submit]{
        width: 100%;
      }
      .form-group {
        margin: 0 1rem 1.3rem;
      }
    }
  }
}
#youtubeModal .modal-body {
  padding: 0;
}
#patentModal .modal-content {
  width: 396px;
  margin: auto;
  .modal-body{
    padding: 0;
  }
}
#testDriveModal2{
  .modal-dialog{
    width: 322px;
    margin: auto;
  }
  .modal-body{
    padding: 0;
  }
  .test-drive_form{
    border: 0;
    border-radius: 10px;
  }
}
#goodSendModal{
  .modal-content {
    background-color: #fff;
    width: 350px;
    padding: 20px;
    h4 {
      text-align: center;
      margin-bottom: 1.2rem;
      &+p{
        text-align: center;
      }
    }
  }
}
#badSendModal{
  .modal-content {
    background-color: #fff;
    width: 350px;
    padding: 20px;
    h4 {
      text-align: center;
      margin-bottom: 1.2rem;
      color: red;
      font-size: 32px;
      &+p{
        text-align: center;
        font-size: 19px;
      }
    }
  }
}
#dontLeaveModal {
  .modal-content{
    //width: 726px;
    padding: 23px 50px 20px;
    padding-left: 110px;
    margin: auto;
    border-radius: 10px;
    border: none;
    background: url(../img/dontLeaveModal.png) no-repeat;
  }
  .modal-header {
    justify-content: center;
    border-bottom: none;
    h5{
      font-size: 1rem;
      //font-family: "Uni Neue Light";
      color: #465356;
    }
  }
  .modal-body{
    h3{
      font-size: 2rem;
      color: #333;
      margin: 2px 0 20px;
      line-height: 1.4;
    }
  }
  .promoCodeDiv{
    width: 260px;
    padding: 22px 0 0;
    input[name=promo-code_phone] {
      // background-color: #4e4543;
      // border: 1px solid #4e4543;
      border-radius: 26px;
      height: calc(2.75rem + 2px);
      font-size: 16px;
      color: #6d7c7f;
      padding-left: 45px;
      background-image: url(../img/phone_icon.png);
      background-repeat: no-repeat;
      background-position: 19px;
    }
    button[type=submit] {
      width: 100%;
    }
  }
  .modal-footer{
    justify-content: flex-start;
    border: none;
    padding-bottom: 0;
    p {
      color: #b6b6b6;
      font-family: "Uni Neue Regular";
      font-size: 0.5625rem;
      margin: 15px 0 0;
      margin: 10px 0;
      border: none;
      text-align: left;
    }
  }
}

.menu,
.menu_open,
.menu2,
.menu2_open{
  font-size: 19px;
  font-weight: normal;
}


.menu,
.menu2{
  z-index: 2000;
  bottom: 0;
  width: 657px;
  background-color: #23262c;
  padding: 50px 246px 20px 60px;
  //padding: 50px 60px 20px;
  overflow: auto;
  transition: all .3s ease-in-out;
  transform: translate(100%);
  position: fixed;
  right: 0;
  top: 0;
  p{
    line-height: 1.85;
    font-size: 1.6875rem;
    font-family: "Uni Neue Light";
    padding: 0px 11px;
  }
  &.active{
    transform: translate(0%);
  }
  color: #fbfbfc;
  a{
    color: #fbfbfc;
  }
  .menu_close,
  .menu2_close{
    display: table;
    margin-left: auto;
    margin-bottom: 100px;
    margin-bottom: 93px;
    cursor: pointer;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-size: 36px;
    font-weight: 700;
    line-height: 20px;
    transition: opacity .5s, top .5s;
    color: #fff;
    opacity: 0.8;
    font-weight: normal;
    font-family: "Uni Neue Light";
    &:hover{
      opacity: 1;
    }
  }
  form{
    width: 260px;
    padding: 36px 0 32px;
    padding-left: 0;
    //text-align: center;
    input[type=text]{
      border-radius: 26px;
      height: calc(2.75rem + 2px);
      font-size: 16px;
      color: #6d7c7f;
      padding-left: 45px;
      background-image: url(../img/phone_icon.png);
      background-repeat: no-repeat;
      background-position: 19px;
      border: 1px solid #22252c;
    }
    input[name=callback_name] {
      background-image: url(../img/user_icon.png);
    }
    button[type=submit]{
      width: 100%;
    }
  }
}

.menu2{
  width: 534px;
  padding: 36px 37px 20px 60px;
  .menu2_close{
    margin-bottom: 33px;
  }
  .navbar-nav{
    text-align: right;
    padding-right: 62px;
  }
  .nav-link{
    color: #fff!important;
    font-size: 2rem!important;
    padding-bottom: 1.5rem;
    font-family: "Uni Neue Light";
  }
}


#audioModal audio {
  display: none;
}
// audio {
//   width: 300px;
//   height: 54px;
//   background: #fff;
// }
.audio-comment_block {
  line-height: 54px;
  background: #f1f2f4;
  width: 100%;
  height: 54px;
  padding: 0;
  margin: 0;
}
.audio-player{
  //width: 300px;
  height: 100%;
  margin: auto;
}
.control_play_pause {
  //width: 54px;
  float: left;
  padding: 0 5px;
  padding-left: 10px;
}
.control_stop {
  //width: 54px;
  float: left;
  padding: 0 5px;
}
.control_volume {
  float: right;
  padding: 0 10px 0 5px;
}
.control_progress {
  float: left;
  //padding: 0 15px;
  padding: 0 10px;
  progress {
    display: block;
    // height: 1em;
    // width: 20em;
    height: 14px;
    width: 16em;
    margin: 20px 0;
    color: hsl(44,100%,44%);
    //background-color: #888;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

#playback progress {
  // margin-left: 1rem;
  // width: 20rem; height: 12px;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  // background-color: #888;
  // border: none;
  // color: hsl(44,100%,44%);
}
progress::-webkit-progress-bar,
progress::-moz-progress-bar,
progress::progress-bar {
  background: hsl(44,100%,44%);
}
progress::-webkit-progress-value {
  background: hsl(44,100%,44%);
}
progress::-moz-progress-bar {
  background-color: hsl(44,100%,44%);
}

.playbacktime {
  font-size: 0.9rem;
  float: left;
  padding: 0 10px;
  //width: 54px;
  width: 64px;
  height: 54px;
  color: #333;
}


.btn.button_play_pause {
  position: relative;
  box-shadow: 0 4px 0 0 #987311, 1px 1px 6px rgba(0, 0, 0, 0.24)!important;
  &:hover {
    -webkit-transform: translateY(3px);
    -ms-transform: translateY(3px);
    transform: translateY(3px);
    box-shadow: 0 1px 0 0 #987311, 1px 1px 6px rgba(0, 0, 0, 0.24)!important;
    transition: all 0.25s linear;
  }
  &.button_play_pause--animation{
    -webkit-transform: translateY(3px);
    -ms-transform: translateY(3px);
    transform: translateY(3px);
    box-shadow: 0 1px 0 0 #987311, 1px 1px 6px rgba(0, 0, 0, 0.24)!important;
    transition: all 0.25s linear;
    &:before, &:after {
      content: "";
      width: 268px;
      height: 68px;
      //width: 258px;
      //height: 58px;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -6px;
      //left: 0;
      right: 0;
      //border: 1px solid #fbb921;
      border: 1px solid #fbd256;
      border-radius: 13px;
      // -webkit-animation: circle2 2.5s linear infinite;
      // animation: circle2 2.5s linear infinite;
    }
    &:before {
      -webkit-animation: circle3 2s linear infinite;
      animation: circle3 2s linear infinite;
    }
    &:after {
      -webkit-animation: circle3 2s 1s linear infinite;
      animation: circle3 2s 1s linear infinite;
    }
  }
}
.ui-widget.ui-widget-content {
  //display: block !important;
  //width: 420px;
  //margin-top: 5px;
  max-height: 410px;
  border-radius: 25px;
  border: 1px solid #dcdcdd;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 420px !important;
  margin-top: 10px !important;
}

// .ui-menu .ui-menu-item-wrapper {
//   padding: 2px 20px;
//   &:first-child{
//     padding-top: 15px;
//   }
//   &:last-child{
//     padding-bottom: 15px;
//   }
//   &.ui-state-active{
//     padding: 2px 20px;
//     border: none;
//     background: none;
//     color: inherit;
//   }
// }
.ui-menu-item{
  &:first-child{
    .ui-menu-item-wrapper {
      //padding-top: 15px;
    }
  }
  &:last-child{
    .ui-menu-item-wrapper {
      //padding-bottom: 15px;
    }
  }
  .ui-menu-item-wrapper {
    padding: 2px 20px;
    padding: 3px 10px 0px;
    font-size: 14px;
    font-family: "Uni Neue Regular";
    &.ui-state-active{
      //padding: 2px 20px;
      padding: 3px 10px 0px;
      border: none;
      background: none;
      color: inherit;
      text-decoration: underline;
    }
  }
}

section#sec5{
  .slick-next, .slick-prev {
    z-index: 1;
    height: 40px;
    width: 40px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 2px 2px 18px rgba(142,142,142,.26), 2px 2px 0 rgba(226,226,226,.1);
    &:before {
      font-size: 40px;
      line-height: 1.15;
      opacity: 1;
      display: block;
      content: "";
      width: 40px;
      height: 40px;
      background-image: url(../img/next-arrow.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      color: black !important;
      opacity: 1;
    }
  }
  .slick-prev:before {
    background-image: url(../img/prev-arrow.svg);
  }
}
.slick-active:focus{
  outline: none;
}


section#pageThanks {
  padding-top: 25vh;
  .block_thanks_main{
    h1{
      font-size: 46px;
    }
    p {
      font-size: 26px;
    }
  }
}

// .parallax-window {
//   //min-height: 400px;
//   background: transparent;
// }

// section#sec2.parallax-window,
// // section#sec3.parallax-window,
// // section#sec4.parallax-window,
// // section#sec5.parallax-window,
// section#sec6.parallax-window,
// // section#sec7.parallax-window,
// section#sec8.parallax-window,
// // section#sec9.parallax-window
// {
//   background: transparent;
// }
